import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    ListGroup,
    ListGroupItem,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Button,
    Spinner
} from "reactstrap";
import classnames from "classnames";
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import Slider from 'react-rangeslider';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import { addMainFood, getAllGrapes, updateMainFood } from '../../helpers/api/product/product';
import { getAdminDetails } from '../../helpers/api/auth/auth';
import { addProducer, getProducerDetails, updateProducer } from '../../helpers/api/producer/producer';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { controlFileSize } from '../../helpers/api/common/toolbox';
// register lottie and define custom element
defineLordIconElement(loadAnimation);

const ProducerDetails = () => {
    const [activeArrowTab, setactiveArrowTab] = useState(4);
    const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
    const [enable, setEnable] = useState({
        all: false,
        doButton: true,
        cancelButton: false,
        refreshButton: true,
        form: true,
        profile: true
    });
    const [dropdownItems, setDropdownItems] = useState({
        grapes: []
    });
    const [formValues, setFormValues] = useState({
        name: "",
        producerExplain: "",
        producerExplainEn: "",
        vineyard: "",
        vineyardEn: "",
        vineyardNo: "",
        vineyardNoEn: "",
        tours: "",
        toursEn: "",
        accomodation: "",
        accomodationEn: "",
        restaurant: "",
        restaurantEn: "",
        image: { data: "", isNew: true },
        grapes: []
    });
    const [profileMode, setProfileMode] = useState(false);
    const [print, setPrint] = useState({
        enable: false
    });
    const [texts, setTexts] = useState({
        title: "",
        doButton: "Ekle"
    });
    const [pageType, setPageType] = useState("");
    const [loading, setLoading] = useState(false);
    const [visibility, setVisibility] = useState({
        loading: true,
        cancelButton: true
    });

    const history = useHistory();
    const location = useLocation();
    const { mode, id, type } = location.state.params;

    function toggleArrowTab(tab) {
        if (activeArrowTab !== tab) {
            var modifiedSteps = [...passedarrowSteps, tab];

            if (tab >= 4 && tab <= 8) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    }

    const handleProducerDetails = async () => {
        try {
            let result = await getProducerDetails({ id });
            if (!result.success) return console.log("Bir hata oluştu");

            let producer = result.data;

            setFormValues({
                ...formValues,
                name: producer.ProducerName || "",
                producerExplain: producer.ProducerExplain || "",
                producerExplainEn: producer.ProducerExplain_ing || "",
                vineyard: producer.Vineyard || "",
                vineyardEn: producer.Vineyard_ing || "",
                vineyardNo: producer.VineyardNo || "",
                vineyardNoEn: producer.VineyardNo_ing || "",
                image: (producer.ProducerLogo) ? { data: producer.ProducerLogo, isNew: false } : { data: "", isNew: true },
                grapes: producer.Grapes || [],
                tours: producer.Tours || "",
                toursEn: producer.Tours_ing || "",
                accomodation: producer.Accomodation || "",
                accomodationEn: producer.Accomodation_ing || "",
                restaurant: producer.Restaurant || "",
                restaurantEn: producer.Restaurant_ing || "",
            });

            if (type != "ADD") {
                setPrint({
                    ...print,
                    enable: true
                });
            }
        } catch (error) {
            return console.log(error);
        }
    }

    const doFirstProcess = async () => {
        try {
            setPageType("LOADING");

            await fillDropdowns();

            if (id) await handleProducerDetails();

            setPageType(type);
        } catch (error) {
            return console.log(error);
        }
    };

    const fillDropdowns = async () => {
        try {
            let fields = {};
            { // Grapes
                let responseGrapes = await getAllGrapes();
                if (!responseGrapes.success) return console.log(responseGrapes.data);

                fields = {
                    ...fields,
                    grapes: responseGrapes.data
                };
            }

            setDropdownItems({
                ...dropdownItems,
                ...fields
            });
        } catch (error) {
            return console.log(error);
        }
    }

    const handleGrapeDetail = ({
        id,
        name
    }) => {
        let grapes = [...formValues.grapes];
        let index = grapes.findIndex(x => x.Id == id);

        grapes[index] = {
            ...grapes[index],
            GrapeName: name
        };


        setFormValues({
            ...formValues,
            grapes
        });
    }

    const addGrapeToState = ({
        name,
        isNew
    }) => {
        let grapes = [...formValues.grapes];

        let id;
        if (!isNew) id = dropdownItems.grapes.find(x => x.GrapeName == name).id;
        else {
            for (let i = 0; i < 1; i++) {
                id = Math.random().toString(36).slice(-6);
                if (grapes.find(x => x.Id == id)) i--;
            }
        }

        grapes.push({
            GrapeName: name,
            isNew: (isNew) ? true : false,
            Id: id
        });

        setFormValues({
            ...formValues,
            grapes
        });
    };

    const handleGrapeAdd = ({
        e,
        isNew
    }) => {
        e.preventDefault();

        let formList = e.target.parentElement.parentElement.children;

        let nameValue;

        if (isNew) nameValue = formList[0].children[0].value;
        else nameValue = formList[0].children[1].value;

        if (nameValue.trim() == "") return alert("Üzüm adı boş bırakılamaz");

        if (isNew) addGrapeToState({ name: nameValue, isNew });
        else addGrapeToState({ name: nameValue });


        if (isNew) formList[0].children[0].value = "";
        else formList[0].children[1].selectedIndex = 0;
    };

    const handleGrapeRemove = ({
        e,
        id
    }) => {
        e.preventDefault();
        let grapes = [...formValues.grapes];
        let index = grapes.findIndex(x => x.Id == id);

        grapes.splice(index, 1);

        setFormValues({
            ...formValues,
            grapes
        });
    };

    const handleDo = () => {
        if (pageType == "INFORM") {
            setPageType("EDIT");
        } else if (pageType == "EDIT") {
            handleUpdateProducer();
        }
        else if (pageType == "ADD") {
            handleAddProducer();
        }
    }

    const handleCancel = () => {
        setPageType("INFORM");
    }

    const handleRefresh = () => {
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure to refresh?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        if (pageType == "INFORM" || pageType == "EDIT") {
                            reloadContent();
                        } else if (pageType == "ADD") {
                            setFormValues({
                                ...formValues,
                                name: "",
                                producerExplain: "",
                                producerExplainEn: "",
                                vineyard: "",
                                vineyardEn: "",
                                tours: "",
                                toursEn: "",
                                accomodation: "",
                                accomodationEn: "",
                                restaurant: "",
                                restaurantEn: "",
                                grapes: [],
                            });
                        }
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const reloadContent = async () => {
        try {
            setLoading(true);

            await doFirstProcess();

            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const alertValidationError = (element) => {
        alert("Lütfen " + element + " alanını doldurunuz.");
    }

    const controlNecessaryValues = () => {
        if (formValues.name.trim() == "") return alertValidationError("Name");
        if(!controlFileSize(formValues.image)) return alert("Dosya boyutu 1MB'dan fazla olamaz.");
        return true;
    }

    const handleUpdateProducer = async () => {
        try {
            setLoading(true);
            if (controlNecessaryValues() === true) {
                let data = {
                    ...formValues,
                    image: (formValues.image.isNew && formValues.image.data != "") ? formValues.image.data : null,
                    grapes: formValues.grapes.map(x => {
                        return {
                            Name: x.GrapeName,
                            isNew: x.isNew,
                            id: x.Id || x.id
                        }
                    })
                };

                let response = await updateProducer({
                    id,
                    ...data
                });

                if (!response.success) return console.log(response.data);

                if (mode == "PROFILE") history.push("/wine-table");
                else history.push("/producer-table");
            }
            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const handleAddProducer = async () => {
        try {
            setLoading(true);
            if (controlNecessaryValues() === true) {
                let data = {
                    ...formValues,
                    image: (formValues.image.isNew && formValues.image.data != "") ? formValues.image.data : null,
                    grapes: formValues.grapes.map(x => {
                        return {
                            Name: x.GrapeName,
                            isNew: x.isNew,
                            id: x.Id
                        }
                    })
                };

                let response = await addProducer({
                    ...data,
                    now: new Date()
                });

                if (!response.success) return console.log(response.data);
                history.push("/producer-table");
            }
            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader();
            let file = e.target.files[0];
            reader.onloadend = () => {
                setFormValues({
                    ...formValues,
                    image: { url: reader.result, isNew: true, data: file }
                });
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        switch (type) {
            case "EDIT":
                setTexts({
                    ...texts,
                    title: "Producer Düzenle",
                    button: "Kaydet"
                });
                break;
            case "ADD":
                setTexts({
                    ...texts,
                    title: "Producer Ekle",
                    button: "Ekle"
                });
                break;
            case "INFORM":
                setTexts({
                    ...texts,
                    title: "Producer Bilgileri",
                    button: "Düzenle"
                });
                break;
            default:
                break;
        }

        if (mode && mode == "PROFILE") setProfileMode(true);

        doFirstProcess();
    }, []);

    useEffect(() => {
        document.title = texts.title;
    }, [texts.title]);

    useEffect(() => {
        if (!pageType) return

        switch (pageType) {
            case "INFORM":
                setTexts({
                    ...texts,
                    title: "Producer Bilgileri",
                    doButton: "Düzenle"
                });
                setEnable({
                    ...enable,
                    form: false,
                    cancelButton: false,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: true,
                    loading: false
                });
                break;
            case "EDIT":
                setTexts({
                    ...texts,
                    title: "Producer Düzenleme",
                    doButton: "Kaydet"
                });
                setEnable({
                    ...enable,
                    form: true,
                    cancelButton: true,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: true,
                    loading: false
                });
                break;
            case "ADD":
                setTexts({
                    ...texts,
                    title: "Producer Ekleme"
                });
                setEnable({
                    ...enable,
                    form: true,
                    cancelButton: false,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: false,
                    loading: false
                });
                break;
            case "LOADING":
                setEnable({
                    ...enable,
                    all: false
                });
                setVisibility({
                    ...visibility,
                    loading: true
                });
                break;
            default:
                break;
        }
    }, [pageType]);

    useEffect(() => {
        if (loading) {
            setEnable({
                ...enable,
                all: false
            });
            setVisibility({
                ...visibility,
                loading: true
            });
        } else {
            setEnable({
                ...enable,
                all: true
            });
            setVisibility({
                ...visibility,
                loading: false
            });
        }
    }, [loading]);

    return (
        <>
            <div className="page-content">
                <fieldset disabled={!enable.all}>
                    <Container fluid={true}>
                        <Card>
                            <CardHeader>
                                <Row className='align-items-end'>
                                    <Col sm={1}><Spinner color='danger' hidden={!visibility.loading}>loading ...</Spinner></Col>
                                    <Col sm={4}>
                                        <h2 className="mb-0">{texts.title}</h2>
                                        <p>Kaydetmek için '<span style={{ color: "red" }}>*</span>'la işaretlenmiş alanları doldurunuz.</p>
                                    </Col>
                                    <Col sm={4}></Col>
                                    <Col sm={1}><Button hidden={!visibility.cancelButton} disabled={!enable.cancelButton} onClick={handleCancel} color='warning'>İptal Et</Button></Col>
                                    <Col sm={1}><Button disabled={!enable.refreshButton} onClick={handleRefresh} color='danger'>Yenile</Button></Col>
                                    <Col sm={1}><Button disabled={!enable.doButton} onClick={handleDo} color='success'>{texts.doButton}</Button></Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="form-steps">
                                <Form>
                                    <div className="step-arrow-nav mb-4">
                                        <Nav
                                            className="nav-pills custom-nav nav-justified"
                                            role="tablist"
                                        >
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    id="steparrow-gen-info-tab"
                                                    className={classnames({
                                                        active: activeArrowTab === 4,
                                                        done: activeArrowTab <= 6 && activeArrowTab > 3,
                                                    })}
                                                    onClick={() => {
                                                        toggleArrowTab(4);
                                                    }}
                                                >
                                                    Genel Bilgiler
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    id="steparrow-gen-info-tab"
                                                    className={classnames({
                                                        active: activeArrowTab === 5,
                                                        done: activeArrowTab <= 6 && activeArrowTab > 4,
                                                    })}
                                                    onClick={() => {
                                                        toggleArrowTab(5);
                                                    }}
                                                >
                                                    Üzümler
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    id="steparrow-gen-info-tab"
                                                    className={classnames({
                                                        active: activeArrowTab === 6,
                                                        done: activeArrowTab <= 6 && activeArrowTab > 5,
                                                    })}
                                                    onClick={() => {
                                                        toggleArrowTab(6);
                                                    }}
                                                >
                                                    Hizmetler
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                    <fieldset disabled={!enable.form}>
                                        <TabContent activeTab={activeArrowTab}>
                                            <TabPane id="common" tabId={4}>
                                                <Form >
                                                    <Row>
                                                        <Col lg={12}>
                                                            <Card>
                                                                <CardHeader className="card-header">
                                                                    <h4 className="card-title mb-0">Producer Logosu</h4>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col lg={6}>
                                                                            {
                                                                                (!formValues.image.isNew && formValues.image.data)
                                                                                    ? <img src={formValues.image.data} style={{ height: "100px" }} />
                                                                                    : (formValues.image.isNew && formValues.image.data && formValues.image.data != "" && formValues.image.url)
                                                                                        ? <img src={formValues.image.url} style={{ height: "100px" }} />
                                                                                        : <></>
                                                                            }
                                                                        </Col>
                                                                        <Col lg={6}>
                                                                            <div>
                                                                                <p className="text-muted">Fotoğraf kareye dönüştürlecektir. Bu yüzden kare fotoğraf yüklemeniz önerilir.</p>
                                                                                <input className="form-control" type="file" id="formFile" onChange={handleImageChange} />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <Col md={12}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="firstNameinpu" className="form-label">Name<span style={{ color: "red" }}>*</span></Label>
                                                                <Input value={formValues.name} onChange={(e) => setFormValues({ ...formValues, name: e.target.value })} type="text" className="form-control" id="firstNameinpu" disabled={profileMode} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="firstNameinpu" className="form-label">Producer Explain Tr</Label>
                                                                <Input value={formValues.producerExplain} onChange={(e) => setFormValues({ ...formValues, producerExplain: e.target.value })} type="textarea" className="form-control" id="firstNameinpu" />
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="firstNameinpu" className="form-label">Producer Explain En</Label>
                                                                <Input value={formValues.producerExplainEn} onChange={(e) => setFormValues({ ...formValues, producerExplainEn: e.target.value })} type="textarea" className="form-control" id="firstNameinpu" />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="firstNameinpu" className="form-label">Vineyard Tr</Label>
                                                                <Input value={formValues.vineyard} onChange={(e) => setFormValues({ ...formValues, vineyard: e.target.value })} type="textarea" className="form-control" id="firstNameinpu" />
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="firstNameinpu" className="form-label">Vineyard En</Label>
                                                                <Input value={formValues.vineyardEn} onChange={(e) => setFormValues({ ...formValues, vineyardEn: e.target.value })} type="textarea" className="form-control" id="firstNameinpu" />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="firstNameinpu" className="form-label">Vineyard No Tr</Label>
                                                                <Input value={formValues.vineyardNo} onChange={(e) => setFormValues({ ...formValues, vineyardNo: e.target.value })} type="textarea" className="form-control" id="firstNameinpu" />
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="firstNameinpu" className="form-label">Vineyard No En</Label>
                                                                <Input value={formValues.vineyardNoEn} onChange={(e) => setFormValues({ ...formValues, vineyardNoEn: e.target.value })} type="textarea" className="form-control" id="firstNameinpu" />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </TabPane>
                                            <TabPane id='grape' tabId={5}>
                                                <Row>
                                                    <Col xl={6}>
                                                        <Card>
                                                            <CardHeader>
                                                                <h4 className="card-title mb-0">Kayıtlı Olan Üzüm Çeşitleri</h4>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <Card>
                                                                            <CardBody >
                                                                                <Row className="row-cols-lg-auto g-3 align-items-center">
                                                                                    <Col xs={12}>
                                                                                        <Label className="visually-hidden" htmlFor="inlineFormSelectPref">Üzüm Çeşidi</Label>
                                                                                        <select className="form-select" data-choices data-choices-sorting="true" id="inlineFormSelectPref">
                                                                                            {
                                                                                                dropdownItems.grapes.map((item, index) => (
                                                                                                    <option key={index} value={item.GrapeName}>{item.GrapeName}</option>
                                                                                                ))
                                                                                            }
                                                                                        </select>
                                                                                    </Col>
                                                                                    <Col xs={12}>
                                                                                        <button type="button" className="btn btn-success" onClick={(e) => handleGrapeAdd({ e })}>+</button>
                                                                                    </Col>
                                                                                </Row>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                                <hr />
                                                                {
                                                                    formValues.grapes.map((item, index) => (!item.isNew) ? (
                                                                        <Row key={index}>
                                                                            <Col lg={12}>
                                                                                <Card>
                                                                                    <CardBody >
                                                                                        <Row className="row-cols-lg-auto g-3 align-items-center">
                                                                                            <Col xs={12}>
                                                                                                <Label className="visually-hidden" htmlFor="inlineFormSelectPref">Preference</Label>
                                                                                                <select value={item.GrapeName} onChange={(e) => handleGrapeDetail({ id: item.Id, name: e.target.value })} className="form-select" data-choices data-choices-sorting="true" id="inlineFormSelectPref">
                                                                                                    {
                                                                                                        dropdownItems.grapes.map((item, index) => (
                                                                                                            <option key={index}>{item.GrapeName}</option>
                                                                                                        ))
                                                                                                    }
                                                                                                </select>
                                                                                            </Col>
                                                                                            <Col xs={12}>
                                                                                                <button type="button" className="btn btn-danger" onClick={(e) => handleGrapeRemove({ e, id: item.Id })}>-</button>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </CardBody>
                                                                                </Card>
                                                                            </Col>
                                                                        </Row>
                                                                    ) : (""))
                                                                }
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col xl={6}>
                                                        <Card>
                                                            <CardHeader>
                                                                <h4 className="card-title mb-0">Kayıtlı Olmayan Üzüm Çeşitleri</h4>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <Card>
                                                                            <CardBody >
                                                                                <Row className="row-cols-lg-auto g-3 align-items-center">
                                                                                    <Col xs={12}>
                                                                                        <Input type="text" className="form-control" placeholder="Üzüm Adı" />
                                                                                    </Col>
                                                                                    <Col xs={12}>
                                                                                        <button type="button" className="btn btn-success" onClick={(e) => handleGrapeAdd({ e, isNew: true })}>+</button>
                                                                                    </Col>
                                                                                </Row>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                                <hr />
                                                                {
                                                                    formValues.grapes.map((item, index) => (item.isNew) ? (
                                                                        <Row key={index}>
                                                                            <Col lg={12}>
                                                                                <Card>
                                                                                    <CardBody >
                                                                                        <Row className="row-cols-lg-auto g-3 align-items-center">
                                                                                            <Col xs={12}>
                                                                                                <Label className="visually-hidden" htmlFor="inlineFormSelectPref">Preference</Label>
                                                                                                <Input value={item.GrapeName} onChange={(e) => handleGrapeDetail({ id: item.Id, name: e.target.value })} type="text" className="form-control" placeholder="Üzüm Adı" />
                                                                                            </Col>
                                                                                            <Col xs={12}>
                                                                                                <button type="button" className="btn btn-danger" onClick={(e) => handleGrapeRemove({ e, id: item.Id })}>-</button>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </CardBody>
                                                                                </Card>
                                                                            </Col>
                                                                        </Row>
                                                                    ) : (""))
                                                                }
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane id='extras' tabId={6}>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="firstNameinpu" className="form-label">Turlar Tr</Label>
                                                            <Input value={formValues.tours} onChange={(e) => setFormValues({ ...formValues, tours: e.target.value })} type="textarea" className="form-control" id="firstNameinpu" />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="firstNameinpu" className="form-label">Turlar En</Label>
                                                            <Input value={formValues.toursEn} onChange={(e) => setFormValues({ ...formValues, toursEn: e.target.value })} type="textarea" className="form-control" id="firstNameinpu" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="firstNameinpu" className="form-label">Konaklama Tr</Label>
                                                            <Input value={formValues.accomodation} onChange={(e) => setFormValues({ ...formValues, accomodation: e.target.value })} type="textarea" className="form-control" id="firstNameinpu" />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="firstNameinpu" className="form-label">Konaklama En</Label>
                                                            <Input value={formValues.accomodationEn} onChange={(e) => setFormValues({ ...formValues, accomodationEn: e.target.value })} type="textarea" className="form-control" id="firstNameinpu" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="firstNameinpu" className="form-label">Restorant Tr</Label>
                                                            <Input value={formValues.restaurant} onChange={(e) => setFormValues({ ...formValues, restaurant: e.target.value })} type="textarea" className="form-control" id="firstNameinpu" />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="firstNameinpu" className="form-label">Restorant En</Label>
                                                            <Input value={formValues.restaurantEn} onChange={(e) => setFormValues({ ...formValues, restaurantEn: e.target.value })} type="textarea" className="form-control" id="firstNameinpu" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </fieldset>
                                </Form>
                            </CardBody>
                        </Card>

                    </Container>
                </fieldset>
            </div>
        </>
    );
}

export default ProducerDetails;