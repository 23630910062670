import { md5Control, tokenControl } from "../common/middlewareHeaders";
import { addAromaURL, addColorTypeURL, addFoodURL, addGlassTypeURL, addGrapeURL, addMainAromaURL, addMainFoodURL, addWineURL, adminLoginURL, changeWineActivityURL, deleteAromaURL, deleteColorTypeURL, deleteFoodURL, deleteGlassTypeURL, deleteGrapeURL, deleteMainAromaURL, deleteMainFoodURL, deletePlaceMapPictureURL, deleteWineURL, getAllAromasWithMainAromasURL, getAllBarrelsURL, getAllColorTypesURL, getAllDishesWithMainDishesURL, getAllGlassTypesURL, getAllGrapesDetailedByCompanyURL, getAllGrapesDetailedURL, getAllGrapesURL, getAllManufacturePlacesURL, getAllMotherlandNamesURL, getAllPlaceMapPicturesURL, getAllWineCategoriesURL, getAllWinesURL, getAllWineTypesURL, getAromaDetailsURL, getColorTypeDetailsURL, getFoodDetailsURL, getGlassTypeDetailsURL, getGrapeDetailedURL, getMainAromaDetailsURL, getMainAromasURL, getMainDishesURL, getMainFoodDetailsURL, getPriceCategoriesURL, getWineDetailsURL, getWinesByCompanyURL, updateAromaURL, updateColorTypeURL, updateFoodURL, updateGlassTypeURL, updateGrapeURL, updateMainAromaURL, updateMainFoodURL, updatePlaceMapPictureURL, updatePriceCategoriesURL, updateWineURL } from "../common/endpointURLs";
import fetchToAPI from "../common/fetchToAPI";
import CustomError from "../../error/CustomError";

const getAllWines = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getAllWinesURL,
            headers: md5Control(),
            method: "GET"
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getWinesByCompany = async ({
    companyId
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: getWinesByCompanyURL,
            headers: md5Control(),
            method: "POST",
            body: {
                companyId
            }
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getAllGrapesDetailedByCompany = async ({
    companyId
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: getAllGrapesDetailedByCompanyURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id: companyId
            }
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getWineDetails = async ({
    wineId
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: getWineDetailsURL,
            headers: md5Control(),
            method: "POST",
            body: {
                productId: wineId,
                lang: "tr"
            }
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getAllWineCategories = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getAllWineCategoriesURL,
            headers: md5Control(),
            method: "GET"
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getAllWineTypes = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getAllWineTypesURL,
            headers: md5Control(),
            method: "GET"
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getAllColorTypes = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getAllColorTypesURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getAllBarrels = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getAllBarrelsURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getAllGlassTypes = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getAllGlassTypesURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getAllGrapes = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getAllGrapesURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getAllManufacturePlaces = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getAllManufacturePlacesURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getAllMotherlandNames = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getAllMotherlandNamesURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getAllAromasWithMainAromas = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getAllAromasWithMainAromasURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getAllDishesWithMainDishes = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getAllDishesWithMainDishesURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const updateWine = async ({
    id,
    name,
    harvest,
    category,
    wineStyle,
    woodType,
    woodTime,
    idealOlderTime,
    idealVentilateTime,
    idealServiceTemp,
    alcohol,
    color,
    region,
    asidite,
    tanen,
    bole,
    idealGlassType,
    grapes,
    aromas,
    foods,
    now,
    image,
    companyId,
    price,
    awards,
    extraNotes,
    extraNotesEn,
    active
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: updateWineURL,
            headers: md5Control(),
            method: "POST",
            body: {
                price,
                id,
                name,
                harvest,
                category,
                wineStyle,
                woodType,
                woodTime,
                idealOlderTime,
                idealVentilateTime,
                idealServiceTemp,
                alcohol,
                color,
                region,
                asidite,
                tanen,
                bole,
                idealGlassType,
                grapes,
                aromas,
                foods,
                now,
                image,
                companyId,
                awards,
                extraNotes,
                extraNotesEn,
                active
            },
            contentType: "multipart/form-data"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const addWine = async ({
    name,
    harvest,
    category,
    wineStyle,
    woodType,
    woodTime,
    idealOlderTime,
    idealVentilateTime,
    idealServiceTemp,
    alcohol,
    color,
    region,
    asidite,
    tanen,
    bole,
    idealGlassType,
    grapes,
    aromas,
    foods,
    now,
    image,
    companyId,
    price,
    awards,
    extraNotes,
    extraNotesEn,
    active
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: addWineURL,
            headers: md5Control(),
            method: "POST",
            body: {
                price,
                name,
                harvest,
                category,
                wineStyle,
                woodType,
                woodTime,
                idealOlderTime,
                idealVentilateTime,
                idealServiceTemp,
                alcohol,
                color,
                region,
                asidite,
                tanen,
                bole,
                idealGlassType,
                grapes,
                aromas,
                foods,
                now,
                image,
                companyId,
                awards,
                extraNotes,
                extraNotesEn,
                active
            },
            contentType: "multipart/form-data"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getAllGrapesDetailed = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getAllGrapesDetailedURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getGrapeDetailed = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: getGrapeDetailedURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id,
                lang: "tr"
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const updateGrape = async ({
    id,
    name,
    category,
    idealOlderTime,
    idealVentilateTime,
    idealServiceTemp,
    color,
    region,
    asidite,
    tanen,
    bole,
    idealGlassType,
    aromas,
    foods,
    type,
    motherland
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: updateGrapeURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id,
                name,
                category,
                idealOlderTime,
                idealVentilateTime,
                idealServiceTemp,
                color,
                region,
                asidite,
                tanen,
                bole,
                idealGlassType,
                aromas,
                foods,
                type,
                motherland
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const addGrape = async ({
    name,
    category,
    idealOlderTime,
    idealVentilateTime,
    idealServiceTemp,
    color,
    region,
    asidite,
    tanen,
    bole,
    idealGlassType,
    aromas,
    foods,
    now,
    type,
    motherland
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: addGrapeURL,
            headers: md5Control(),
            method: "POST",
            body: {
                name,
                category,
                idealOlderTime,
                idealVentilateTime,
                idealServiceTemp,
                color,
                region,
                asidite,
                tanen,
                bole,
                idealGlassType,
                aromas,
                foods,
                now,
                type,
                motherland
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const deleteGrape = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: deleteGrapeURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const deleteWine = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: deleteWineURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const changeWineActivity = async ({
    id,
    activity
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: changeWineActivityURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id,
                activity
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getMainAromas = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getMainAromasURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getAromaDetails = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: getAromaDetailsURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const addAroma = async ({
    name,
    nameEng,
    mainAroma,
    now
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: addAromaURL,
            headers: md5Control(),
            method: "POST",
            body: {
                name,
                nameEng,
                mainAromaId: mainAroma,
                now
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const updateAroma = async ({
    id,
    name,
    nameEng,
    mainAroma
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: updateAromaURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id,
                name,
                nameEng,
                mainAromaId: mainAroma
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getMainAromaDetails = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: getMainAromaDetailsURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const addMainAroma = async ({
    name,
    nameEng,
    now,
    image
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: addMainAromaURL,
            headers: md5Control(),
            method: "POST",
            body: {
                name,
                nameEng,
                now,
                image
            },
            contentType: "multipart/form-data"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const updateMainAroma = async ({
    id,
    name,
    nameEng,
    image
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: updateMainAromaURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id,
                name,
                nameEng,
                image
            },
            contentType: "multipart/form-data"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getFoodDetails = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: getFoodDetailsURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const addFood = async ({
    name,
    nameEng,
    mainFood,
    now
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: addFoodURL,
            headers: md5Control(),
            method: "POST",
            body: {
                name,
                nameEng,
                mainFoodId: mainFood,
                now
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const updateFood = async ({
    id,
    name,
    nameEng,
    mainFood
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: updateFoodURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id,
                name,
                nameEng,
                mainFoodId: mainFood
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getMainFoodDetails = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: getMainFoodDetailsURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const addMainFood = async ({
    name,
    nameEng,
    now,
    image
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: addMainFoodURL,
            headers: md5Control(),
            method: "POST",
            body: {
                name,
                nameEng,
                now,
                image
            },
            contentType: "multipart/form-data"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const updateMainFood = async ({
    id,
    name,
    nameEng,
    image
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: updateMainFoodURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id,
                name,
                nameEng,
                image
            },
            contentType: "multipart/form-data"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getMainDishes = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getMainDishesURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const deleteMainFood = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: deleteMainFoodURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const deleteFood = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: deleteFoodURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const deleteMainAroma = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: deleteMainAromaURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const deleteAroma = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: deleteAromaURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getPriceCategories = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getPriceCategoriesURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const updatePriceCategories = async ({
    one,
    two,
    three,
    four,
    five
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: updatePriceCategoriesURL,
            headers: md5Control(),
            method: "POST",
            body: {
                priceCategories: [
                    one,
                    two,
                    three,
                    four,
                    five
                ]
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const deleteColorType = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: deleteColorTypeURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getColorTypeDetails = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: getColorTypeDetailsURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const addColorType = async ({
    name,
    nameEng,
    code,
    now
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: addColorTypeURL,
            headers: md5Control(),
            method: "POST",
            body: {
                name,
                nameEng,
                code,
                now
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const updateColorType = async ({
    name,
    nameEng,
    code,
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: updateColorTypeURL,
            headers: md5Control(),
            method: "POST",
            body: {
                name,
                nameEng,
                code,
                id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getAllPlaceMapPictures = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getAllPlaceMapPicturesURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const updatePlaceMapPicture = async ({
    name,
    category,
    oldImage,
    image,
    oldName
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: updatePlaceMapPictureURL,
            headers: md5Control(),
            method: "POST",
            body: {
                name,
                category,
                oldImage,
                image,
                oldName
            },
            contentType: "multipart/form-data"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getGlassTypeDetails = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: getGlassTypeDetailsURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const addGlassType = async ({
    name,
    nameEng,
    description,
    descriptionEng,
    now,
    image
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: addGlassTypeURL,
            headers: md5Control(),
            method: "POST",
            body: {
                name,
                nameEng,
                now,
                description,
                descriptionEng,
                image
            },
            contentType: "multipart/form-data"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const updateGlassType = async ({
    id,
    name,
    nameEng,
    description,
    descriptionEng,
    image
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: updateGlassTypeURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id,
                name,
                nameEng,
                description,
                descriptionEng,
                image
            },
            contentType: "multipart/form-data"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const deleteGlassType = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: deleteGlassTypeURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const deletePlaceMapPicture = async ({
    name,
    category,
    photo
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: deletePlaceMapPictureURL,
            headers: md5Control(),
            method: "POST",
            body: {
                name,
                category,
                photo
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

export {
    getAllWines,
    getWinesByCompany,
    getWineDetails,
    getAllWineCategories,
    getAllWineTypes,
    getAllColorTypes,
    getAllBarrels,
    getAllGlassTypes,
    getAllGrapes,
    getAllManufacturePlaces,
    getAllAromasWithMainAromas,
    getAllDishesWithMainDishes,
    updateWine,
    addWine,
    getAllGrapesDetailed,
    getGrapeDetailed,
    updateGrape,
    addGrape,
    deleteGrape,
    deleteWine,
    changeWineActivity,
    getMainAromas,
    getAromaDetails,
    addAroma,
    updateAroma,
    getMainAromaDetails,
    addMainAroma,
    updateMainAroma,
    getFoodDetails,
    addFood,
    updateFood,
    getMainFoodDetails,
    addMainFood,
    updateMainFood,
    getMainDishes,
    deleteMainFood,
    deleteFood,
    deleteMainAroma,
    deleteAroma,
    getAllGrapesDetailedByCompany,
    getPriceCategories,
    updatePriceCategories,
    getAllMotherlandNames,
    deleteColorType,
    getColorTypeDetails,
    addColorType,
    updateColorType,
    getAllPlaceMapPictures,
    updatePlaceMapPicture,
    addGlassType,
    updateGlassType,
    deleteGlassType,
    getGlassTypeDetails,
    deletePlaceMapPicture
};