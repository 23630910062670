import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    ListGroup,
    ListGroupItem,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Button,
    Spinner
} from "reactstrap";
import classnames from "classnames";
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import { addWine, getAllAromasWithMainAromas, getAllBarrels, getAllColorTypes, getAllDishesWithMainDishes, getAllGlassTypes, getAllGrapes, getAllManufacturePlaces, getAllWineCategories, getAllWineTypes, getPriceCategories, getWineDetails, updateWine } from '../../helpers/api/product/product';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import Slider from "react-rangeslider";
import useAuth from '../../helpers/auth/useAuth';
import { getAllCompanies } from '../../helpers/api/auth/auth';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { controlFileSize } from '../../helpers/api/common/toolbox';
// register lottie and define custom element
defineLordIconElement(loadAnimation);

const WineDetails = () => {
    const [activeArrowTab, setactiveArrowTab] = useState(4);
    const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
    const [enable, setEnable] = useState({
        all: false,
        region: false,
        doButton: true,
        cancelButton: false,
        refreshButton: true,
        form: true
    });
    const [formValues, setFormValues] = useState({
        name: "",
        price: "",
        harvest: "",
        category: "",
        wineStyle: "",
        color: "",
        woodType: "",
        woodTime: "",
        idealOlderTime: ["", ""],
        idealGlassType: "",
        idealVentilateTime: "",
        idealServiceTemp: ["", ""],
        asidite: "",
        tanen: "",
        bole: "",
        alcohol: "",
        grapes: [],
        region: {
            name: ""
        },
        aromas: [],
        foods: [],
        image: { data: "", isNew: true },
        companyId: "",
        awards: [],
        extraNotes: "",
        extraNotesEn: ""
    });
    const [dropdownItems, setDropdownItems] = useState({
        category: [],
        wineStyle: [],
        color: [],
        barrel: [],
        glassType: [],
        grapes: [],
        regions: [],
        aromas: [],
        foods: [],
        companies: [],
        prices: []
    });
    const [print, setPrint] = useState({
        enable: false,
        aromas: [],
        foods: []
    });
    const [texts, setTexts] = useState({
        title: "",
        doButton: "Ekle"
    });
    const [pageType, setPageType] = useState("");
    const [loading, setLoading] = useState(false);
    const [visibility, setVisibility] = useState({
        loading: true,
        cancelButton: true
    });

    const { auth } = useAuth();

    const history = useHistory();
    const location = useLocation();
    const { id, type } = location.state.params;

    function toggleArrowTab(tab) {
        if (activeArrowTab !== tab) {
            var modifiedSteps = [...passedarrowSteps, tab];

            if (tab >= 4 && tab <= 8) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    }

    const isAdmin = () => {
        if (auth.roles.includes("ADMIN")) {
            return true;
        }
        return false;
    }

    const handleWineDetails = async () => {
        try {
            let result = await getWineDetails({ wineId: id });
            if (!result.success) return console.log("Bir hata oluştu");

            let wine = result.data;

            let tanen = wine.WineCharacteristic.find(x => x.CharacteristicName === "Tanen")?.CharacteristicRate;
            if (!tanen && tanen != 0) tanen = "";
            let bole = wine.WineCharacteristic.find(x => x.CharacteristicName === "Gövde")?.CharacteristicRate;
            if (!bole && bole != 0) bole = "";
            let asidite = wine.WineCharacteristic.find(x => x.CharacteristicName === "Asidite")?.CharacteristicRate;
            if (!asidite && asidite != 0) asidite = "";

            setFormValues({
                ...formValues,
                name: wine.Name || "",
                harvest: wine.WineYear || "",
                category: wine.Category || "",
                wineStyle: wine.WineType || "",
                color: wine.WineColorType[0]?.Id || "",
                woodType: wine.Barrel || "",
                woodTime: (wine.BarrelTime) || "",
                idealOlderTime: [wine.StartingAging, wine.FinishingAging] || ["", ""],
                idealGlassType: wine.GlassType[0]?.GlassName || "",
                idealVentilateTime: wine.Ventilation || "",
                idealServiceTemp: [wine.StartingTemperature, wine.FinishingTemperature] || ["", ""],
                asidite,
                tanen,
                bole,
                alcohol: wine.AlcoholRate || "",
                grapes: wine.WineMaterial || [],
                region: { name: wine.ManufacturePlaceName || "", isNew: false },
                aromas: wine.AromaType || [],
                foods: wine.FoodMatching || [],
                image: (wine.Picture) ? { data: wine.Picture, isNew: false } : { data: "", isNew: true },
                companyId: wine.Company || "",
                price: wine.PriceCategory || "",
                awards: wine.WineAward?.map(x => ({ name: x.AwardName, year: x.AwardYear, place: x.AwardRating })) || [],
                extraNotes: wine.ExtraNotes || "",
                extraNotesEn: wine.ExtraNotes_ing || ""
            });

            if (type != "ADD") {
                setPrint({
                    ...print,
                    enable: true
                });
            }
        } catch (error) {
            return console.log(error);
        }
    }

    const fillDropdowns = async () => {
        try {
            let fields = {};
            { // Wine Categories
                let responseCategories = await getAllWineCategories();
                if (!responseCategories.success) return console.log(responseCategories.data);

                fields = {
                    ...fields,
                    category: responseCategories.data
                };
            }

            { // Wine Style
                let responseWineTypes = await getAllWineTypes();
                if (!responseWineTypes.success) return console.log(responseWineTypes.data);

                fields = {
                    ...fields,
                    wineStyle: responseWineTypes.data
                };
            }

            { // Color
                let responseColorTypes = await getAllColorTypes();
                if (!responseColorTypes.success) return console.log(responseColorTypes.data);

                fields = {
                    ...fields,
                    color: responseColorTypes.data
                };
            }

            { // Barrel
                let responseBarrel = await getAllBarrels();
                if (!responseBarrel.success) return console.log(responseBarrel.data);

                fields = {
                    ...fields,
                    barrel: responseBarrel.data
                };
            }

            { // Glass Type
                let responseGlassType = await getAllGlassTypes();
                if (!responseGlassType.success) return console.log(responseGlassType.data);

                fields = {
                    ...fields,
                    glassType: responseGlassType.data
                };
            }

            { // Grapes
                let responseGrapes = await getAllGrapes();
                if (!responseGrapes.success) return console.log(responseGrapes.data);

                fields = {
                    ...fields,
                    grapes: responseGrapes.data
                };
            }

            { // Regions
                let responseRegion = await getAllManufacturePlaces();
                if (!responseRegion.success) return console.log(responseRegion.data);

                fields = {
                    ...fields,
                    regions: responseRegion.data.map(x => ({ name: x.ManufacturePlaceName }))
                };
            }

            { // Aromas
                let responseAroma = await getAllAromasWithMainAromas();
                if (!responseAroma.success) return console.log(responseAroma.data);


                fields = {
                    ...fields,
                    aromas: responseAroma.data.map(x => ({ id: x.id, name: x.AromaName, mainName: x.MainAromaName, id: x.id, selected: false }))
                };
            }

            { // Foods
                let responseFood = await getAllDishesWithMainDishes();
                if (!responseFood.success) return console.log(responseFood.data);


                fields = {
                    ...fields,
                    foods: responseFood.data.map(x => ({ id: x.id, name: x.FoodName, mainName: x.MainDishName, selected: false }))
                };
            }

            { // Companies
                let responseCompanies = await getAllCompanies();
                if (!responseCompanies.success) return console.log(responseCompanies.data);

                fields = {
                    ...fields,
                    companies: responseCompanies.data.map(x => ({ id: x.id, name: x.ProducerName }))
                };
            }

            { // Prices
                let responsePrices = await getPriceCategories();
                if (!responsePrices.success) return console.log(responsePrices.data);

                fields = {
                    ...fields,
                    prices: responsePrices.data.map(x => ({ value: x.Value, text: x.Text }))
                };
            }

            setDropdownItems({
                ...dropdownItems,
                ...fields
            });
        } catch (error) {
            return console.log(error);
        }
    }

    const handleGrapeDetail = ({
        id,
        percent,
        name
    }) => {
        let grapes = [...formValues.grapes];
        let index = grapes.findIndex(x => x.Id == id);

        if (name) {
            grapes[index] = {
                ...grapes[index],
                GrapeName: name
            };
        }
        if (percent) {
            if (percent > 100) return alert("Yüzde 100'den fazla olamaz");
            if (percent < 0) return alert("Yüzde 0'dan az olamaz");
            if (typeof percent != Number) percent = 0;

            grapes[index] = {
                ...grapes[index],
                GrapePercent: percent || 0
            };
        }

        setFormValues({
            ...formValues,
            grapes
        });
    }

    const addGrapeToState = ({
        name,
        percent,
        isNew
    }) => {
        let grapes = [...formValues.grapes];

        let id;
        if (!isNew) id = dropdownItems.grapes.find(x => x.GrapeName == name).id;
        else {
            for (let i = 0; i < 1; i++) {
                id = Math.random().toString(36).slice(-6);
                if (grapes.find(x => x.Id == id)) i--;
            }
        }

        grapes.push({
            GrapeName: name,
            GrapePercent: percent || 0,
            isNew: (isNew) ? true : false,
            Id: id
        });

        setFormValues({
            ...formValues,
            grapes
        });
    };

    const handleGrapeAdd = ({
        e,
        isNew
    }) => {
        e.preventDefault();

        let formList = e.target.parentElement.parentElement.children;

        let percentValue = formList[1].children[0].value;
        let nameValue;

        if (isNew) nameValue = formList[0].children[0].value;
        else nameValue = formList[0].children[1].value;

        percentValue = parseFloat(percentValue);

        if (isNaN(percentValue)) percentValue = 0;

        if (percentValue > 100) return alert("Yüzde 100'den fazla olamaz");
        if (percentValue < 0) return alert("Yüzde 0'dan az olamaz");
        if (nameValue.trim() == "") return alert("Üzüm adı boş bırakılamaz");

        if (isNew) addGrapeToState({ name: nameValue, percent: percentValue, isNew });
        else addGrapeToState({ name: nameValue, percent: percentValue });


        if (isNew) formList[0].children[0].value = "";
        else formList[0].children[1].selectedIndex = 0;

        formList[1].children[0].value = 0;
    };

    const handleGrapeRemove = ({
        e,
        id
    }) => {
        e.preventDefault();
        let grapes = [...formValues.grapes];
        let index = grapes.findIndex(x => x.Id == id);

        grapes.splice(index, 1);

        setFormValues({
            ...formValues,
            grapes
        });
    };

    const handleAwardDetail = ({
        id,
        name,
        year,
        place
    }) => {
        let awards = [...formValues.awards];
        let index = awards.findIndex(x => x.Id == id);

        if (name) {
            awards[index] = {
                ...awards[index],
                name
            };
        }
        if (year) {
            awards[index] = {
                ...awards[index],
                year
            };
        }
        if (place) {
            awards[index] = {
                ...awards[index],
                place
            };
        }

        setFormValues({
            ...formValues,
            awards
        });
    }

    const addAwardToState = ({
        name,
        year,
        place
    }) => {
        let awards = [...formValues.awards];

        let id;
        for (let i = 0; i < 1; i++) {
            id = Math.random().toString(36).slice(-6);
            if (awards.find(x => x.Id == id)) i--;
        }

        awards.push({
            name,
            year,
            place,
            Id: id
        });

        setFormValues({
            ...formValues,
            awards
        });
    }

    const handleAwardAdd = ({
        e
    }) => {
        e.preventDefault();

        let formList = e.target.parentElement.parentElement.children;

        let nameValue = formList[0].children[0].value;
        let yearValue = formList[1].children[0].value;
        let placeValue = formList[2].children[0].value;

        yearValue = parseFloat(yearValue);

        if (isNaN(yearValue)) yearValue = 0;

        if (nameValue.trim() == "") return alert("Ödül adı boş bırakılamaz");

        addAwardToState({ name: nameValue, year: yearValue, place: placeValue });

        formList[0].children[0].value = "";
        formList[1].children[0].value = "";
        formList[2].children[0].value = "";
    };

    const handleAwardRemove = ({
        e,
        id
    }) => {
        e.preventDefault();
        let awards = [...formValues.awards];
        let index = awards.findIndex(x => x.Id == id);

        awards.splice(index, 1);

        setFormValues({
            ...formValues,
            awards
        });
    };

    const handleRegion = ({
        name,
        isNew
    }) => {
        let region;
        if (isNew) region = { name, isNew: true };
        else region = { name, isNew: false };

        setFormValues({
            ...formValues,
            region
        });
    };

    const handleRegionEnable = (checked) => {
        setEnable({
            ...enable,
            region: checked
        })
    }

    const handleAromaChange = ({
        id,
        checked
    }) => {
        let aromas = [...dropdownItems.aromas];

        let index = aromas.findIndex(x => x.id === id);
        if (index === -1) return;

        aromas[index] = {
            ...aromas[index],
            selected: checked
        };

        setDropdownItems({
            ...dropdownItems,
            aromas
        });
    }

    const handleFoodChange = ({
        id,
        checked
    }) => {
        let foods = [...dropdownItems.foods];

        let index = foods.findIndex(x => x.id === id);
        if (index === -1) return;

        foods[index] = {
            ...foods[index],
            selected: checked
        };

        setDropdownItems({
            ...dropdownItems,
            foods
        });
    }

    const doFirstProcess = async () => {
        try {
            setPageType("LOADING");
            await fillDropdowns();

            if (id) await handleWineDetails();
            else {
                if (!isAdmin()) setFormValues({ ...formValues, companyId: auth.companyId });
            }

            setPageType(type);
        } catch (error) {
            return console.log(error);
        }
    };

    const changedFormValuesAroma = () => {
        if (!dropdownItems.aromas.length) return;

        let fAromas = [...dropdownItems.aromas];
        fAromas = fAromas.map(x => ({ ...x, selected: false }));

        for (let aroma of formValues.aromas) {
            let index = fAromas.findIndex(x => x.name == aroma.AromaName);
            if (index == -1) continue;

            fAromas[index].selected = true;
        }

        return fAromas;
    }

    const changedFormValuesFood = () => {
        if (!dropdownItems.foods.length) return;

        let fFoods = [...dropdownItems.foods];

        for (let food of formValues.foods) {
            let index = fFoods.findIndex(x => x.name == food.FoodName);
            if (index == -1) continue;

            fFoods[index].selected = true;
        }

        return fFoods;
    }

    const changedDropdownItemsAroma = () => {
        if (!dropdownItems.aromas.length) return;

        let uniqueMainAromas = [...new Set(dropdownItems.aromas.map(x => x.mainName))];
        let result = [];

        for (let mainAroma of uniqueMainAromas) {
            let selectedAll = dropdownItems.aromas.filter(x => x.mainName == mainAroma).every(x => x.selected);
            result.push({
                name: mainAroma,
                aromas: dropdownItems.aromas.filter(x => x.mainName == mainAroma),
                selectedAll
            });
        }

        return result;
    }

    const changedDropdownItemsFood = () => {
        if (!dropdownItems.foods.length) return;

        let uniqueMainFoods = [...new Set(dropdownItems.foods.map(x => x.mainName))];
        let result = [];

        for (let mainFood of uniqueMainFoods) {
            let selectedAll = dropdownItems.foods.filter(x => x.mainName == mainFood).every(x => x.selected);
            result.push({
                name: mainFood,
                foods: dropdownItems.foods.filter(x => x.mainName == mainFood),
                selectedAll
            });
        }

        return result;
    }

    const handleDo = () => {
        if (pageType == "INFORM") {
            setPageType("EDIT");
        } else if (pageType == "EDIT") {
            handleUpdateWine();
        }
        else if (pageType == "ADD") {
            handleAddWine();
        }
    }

    const handleCancel = () => {
        setPageType("INFORM");
    }

    const handleRefresh = () => {
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure to refresh?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        if (id) {
                            reloadContent();
                        } else {
                            setFormValues({
                                ...formValues,
                                name: "",
                                price: "",
                                harvest: "",
                                category: "",
                                wineStyle: "",
                                color: "",
                                woodType: "",
                                woodTime: "",
                                idealOlderTime: ["", ""],
                                idealGlassType: "",
                                idealVentilateTime: "",
                                idealServiceTemp: ["", ""],
                                asidite: "",
                                tanen: "",
                                bole: "",
                                alcohol: "",
                                grapes: [],
                                region: { name: "", isNew: false },
                                aromas: [],
                                foods: [],
                                image: { data: "", isNew: true },
                                companyId: "",
                                awards: [],
                                extraNotes: "",
                                extraNotesEn: ""
                            });

                            setDropdownItems({
                                ...dropdownItems,
                                aromas: dropdownItems.aromas.map(x => ({ ...x, selected: false })),
                                foods: dropdownItems.foods.map(x => ({ ...x, selected: false }))
                            });
                        }
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const reloadContent = async () => {
        try {
            setLoading(true);

            await doFirstProcess();

            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const alertValidationError = (element) => {
        alert("Lütfen " + element + " alanını doldurunuz.");
    }

    const controlNecessaryValues = () => {
        if (formValues.name.trim() == "") return false;
        if (formValues.harvest == "" && formValues.harvest != 0) return false;
        if (formValues.wineStyle == "" && formValues.wineStyle != 0) return false;
        if (formValues.idealGlassType == "" && formValues.idealGlassType != 0) return false;
        if (formValues.idealServiceTemp[0] == "" && formValues.idealServiceTemp[0] != 0) return false;
        if (formValues.idealServiceTemp[1] == "" && formValues.idealServiceTemp[1] != 0) return false;
        if (formValues.asidite == "" && formValues.asidite != 0) return false;
        if (formValues.tanen == "" && formValues.tanen != 0) return false;
        if (formValues.bole == "" && formValues.bole != 0) return false;
        if (formValues.alcohol == "" && formValues.alcohol != 0) return false;
        if (formValues.companyId == "" && formValues.companyId != 0) return false;
        if (!formValues.region.name || formValues.region.name.trim() == "") return false;
        if (!formValues.grapes.length) return false;
        if (!dropdownItems.aromas.filter(x => x.selected).length) return false;
        if (!dropdownItems.foods.filter(x => x.selected).length) return false;
        return true;
    }

    const handleUpdateWine = async () => {
        try {
            if (!controlFileSize(formValues.image)) return alert("Dosya boyutu 1MB'dan fazla olamaz.");
            setLoading(true);

            let control = controlNecessaryValues();
            if (control === false) alert("Şarap inaktif olarak kaydedilecek.");

            let data = {
                ...formValues,
                active: control,
                aromas: dropdownItems.aromas.filter(x => x.selected).map(x => ({ name: x.name, id: x.id })),
                foods: dropdownItems.foods.filter(x => x.selected).map(x => ({ name: x.name, id: x.id })),
                image: (formValues.image.isNew && formValues.image.data != "") ? formValues.image.data : null,
                grapes: formValues.grapes.map(x => {
                    return {
                        Name: x.GrapeName,
                        Percent: x.GrapePercent,
                        isNew: x.isNew
                    }
                })
            };

            let response = await updateWine({
                id,
                ...data,
                now: (new Date()).toString()
            });

            if (!response.success) return console.log(response.data);
            history.push("/wine-table");

            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const handleAddWine = async () => {
        try {
            if (!controlFileSize(formValues.image)) return alert("Dosya boyutu 1MB'dan fazla olamaz.");
            setLoading(true);

            let control = controlNecessaryValues();
            if (control === false) alert("Şarap inaktif olarak kaydedilecek.");

            let data = {
                ...formValues,
                active: control,
                aromas: dropdownItems.aromas.filter(x => x.selected).map(x => ({ name: x.name, id: x.id })),
                foods: dropdownItems.foods.filter(x => x.selected).map(x => ({ name: x.name, id: x.id })),
                image: (formValues.image.isNew && formValues.image.data != "") ? formValues.image.data : null,
                grapes: formValues.grapes.map(x => {
                    return {
                        Name: x.GrapeName,
                        Percent: x.GrapePercent,
                        isNew: x.isNew
                    }
                })
            };

            let response = await addWine({
                ...data,
                now: (new Date()).toString()
            });

            if (!response.success) return console.log(response.data);
            history.push("/wine-table");


            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const getNumberValueOfBool = (value) => {
        if (value) return 1;
        else return 0;
    }

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader();
            let file = e.target.files[0];
            reader.onloadend = () => {
                setFormValues({
                    ...formValues,
                    image: { url: reader.result, isNew: true, data: file }
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSelectAllAromas = (mainAroma, selected) => {
        let printAromas = print.aromas;

        printAromas = printAromas.map(x => {
            if (x.name == mainAroma) {
                x.selectedAll = selected;
                x.aromas = x.aromas.map(y => {
                    y.selected = selected;
                    return y;
                });
            }
            return x;
        });

        setPrint({
            ...print,
            aromas: printAromas
        });
    }

    const handleSelectAllFoods = (mainFood, selected) => {
        let printFoods = print.foods;

        printFoods = printFoods.map(x => {
            if (x.name == mainFood) {
                x.selectedAll = selected;
                x.foods = x.foods.map(y => {
                    y.selected = selected;
                    return y;
                });
            }
            return x;
        });

        setPrint({
            ...print,
            foods: printFoods
        });
    }

    useEffect(() => {
        switch (type) {
            case "EDIT":
                setTexts({
                    ...texts,
                    title: "Şarap Düzenle",
                    button: "Kaydet"
                });
                break;
            case "ADD":
                setTexts({
                    ...texts,
                    title: "Şarap Ekle",
                    button: "Ekle"
                });
                break;
            case "INFORM":
                setTexts({
                    ...texts,
                    title: "Şarap Bilgileri",
                    button: "Düzenle"
                });
                break;
            default:
                break;
        }

        doFirstProcess();
    }, []);

    useEffect(() => {
        document.title = texts.title;
    }, [texts.title])

    useEffect(() => {
        let aromas = changedFormValuesAroma();
        let foods = changedFormValuesFood();

        if (type == "ADD") {
            setPrint({
                ...print,
                enable: true
            });
        }

        setDropdownItems({
            ...dropdownItems,
            aromas: aromas || [],
            foods: foods || []
        });
    }, [formValues]);

    useEffect(() => {
        let aromas = changedDropdownItemsAroma();
        let foods = changedDropdownItemsFood();

        if (!print.enable) return;

        setPrint({
            ...print,
            aromas: aromas || [],
            foods: foods || []
        })
    }, [dropdownItems]);

    useEffect(() => {
        if (!pageType) return

        switch (pageType) {
            case "INFORM":
                setTexts({
                    ...texts,
                    title: "Şarap Bilgileri",
                    doButton: "Düzenle"
                });
                setEnable({
                    ...enable,
                    form: false,
                    cancelButton: false,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: true,
                    loading: false
                });
                break;
            case "EDIT":
                setTexts({
                    ...texts,
                    title: "Şarap Düzenleme",
                    doButton: "Kaydet"
                });
                setEnable({
                    ...enable,
                    form: true,
                    cancelButton: true,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: true,
                    loading: false
                });
                break;
            case "ADD":
                setTexts({
                    ...texts,
                    title: "Şarap Ekleme"
                });
                setEnable({
                    ...enable,
                    form: true,
                    cancelButton: false,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: false,
                    loading: false
                });
                break;
            case "LOADING":
                setEnable({
                    ...enable,
                    all: false
                });
                setVisibility({
                    ...visibility,
                    loading: true
                });
                break;
            default:
                break;
        }
    }, [pageType]);

    useEffect(() => {
        if (loading) {
            setEnable({
                ...enable,
                all: false
            });
            setVisibility({
                ...visibility,
                loading: true
            });
        } else {
            setEnable({
                ...enable,
                all: true
            });
            setVisibility({
                ...visibility,
                loading: false
            });
        }
    }, [loading]);

    

    return (
        <>
            <div className="page-content">
                <fieldset disabled={!enable.all}>
                    <Container fluid={true}>
                        <Card>
                            <CardHeader>
                                <Row className='align-items-end'>
                                    <Col sm={1}><Spinner color='danger' hidden={!visibility.loading}>loading ...</Spinner></Col>
                                    <Col sm={4}>
                                        <h2 className="mb-0">{texts.title}</h2>
                                        <p>Kaydetmek için '<span style={{ color: "red" }}>*</span>'la işaretlenmiş alanları doldurunuz.</p>
                                    </Col>
                                    <Col sm={4}></Col>
                                    <Col sm={1}><Button hidden={!visibility.cancelButton} disabled={!enable.cancelButton} onClick={handleCancel} color='warning'>İptal Et</Button></Col>
                                    <Col sm={1}><Button disabled={!enable.refreshButton} onClick={handleRefresh} color='danger'>Yenile</Button></Col>
                                    <Col sm={1}><Button disabled={!enable.doButton} onClick={handleDo} color='success'>{texts.doButton}</Button></Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="form-steps">
                                <Form>
                                    <div className="step-arrow-nav mb-4">
                                        <Nav
                                            className="nav-pills custom-nav nav-justified"
                                            role="tablist"
                                        >
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    id="steparrow-gen-info-tab"
                                                    className={classnames({
                                                        active: activeArrowTab === 4,
                                                        done: activeArrowTab <= 7 && activeArrowTab > 3,
                                                    })}
                                                    onClick={() => {
                                                        toggleArrowTab(4);
                                                    }}
                                                >
                                                    Karakter ve Servis
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    id="steparrow-gen-info-tab"
                                                    className={classnames({
                                                        active: activeArrowTab === 5,
                                                        done: activeArrowTab <= 7 && activeArrowTab > 4,
                                                    })}
                                                    onClick={() => {
                                                        toggleArrowTab(5);
                                                    }}
                                                >
                                                    Üzüm ve Bölgeler
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    id="steparrow-gen-info-tab"
                                                    className={classnames({
                                                        active: activeArrowTab === 6,
                                                        done: activeArrowTab <= 7 && activeArrowTab > 5,
                                                    })}
                                                    onClick={() => {
                                                        toggleArrowTab(6);
                                                    }}
                                                >
                                                    Aromalar
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    id="steparrow-gen-info-tab"
                                                    className={classnames({
                                                        active: activeArrowTab === 7,
                                                        done: activeArrowTab <= 7 && activeArrowTab > 6,
                                                    })}
                                                    onClick={() => {
                                                        toggleArrowTab(7);
                                                    }}
                                                >
                                                    Yemekler
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    id="steparrow-gen-info-tab"
                                                    className={classnames({
                                                        active: activeArrowTab === 8,
                                                        done: activeArrowTab <= 8 && activeArrowTab > 7,
                                                    })}
                                                    onClick={() => {
                                                        toggleArrowTab(8);
                                                    }}
                                                >
                                                    Ödüller ve Notlar
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                    <fieldset disabled={!enable.form}>
                                        <TabContent activeTab={activeArrowTab}>
                                            <TabPane id="common" tabId={4}>
                                                <Form >
                                                    <Row>
                                                        {/* 1. SATIR*/}
                                                        <Row>
                                                            <Col lg={12}>
                                                                <Card>
                                                                    <CardHeader className="card-header">
                                                                        <h4 className="card-title mb-0">Şarap Resmi</h4>
                                                                    </CardHeader>
                                                                    <CardBody>
                                                                        <Row>
                                                                            <Col lg={6}>
                                                                                {
                                                                                    (!formValues.image.isNew && formValues.image.data)
                                                                                        ? <img src={formValues.image.data} style={{ height: "100px" }} />
                                                                                        : (formValues.image.isNew && formValues.image.data && formValues.image.data != "" && formValues.image.url)
                                                                                            ? <img src={formValues.image.url} style={{ height: "100px" }} />
                                                                                            : <></>
                                                                                }
                                                                            </Col>
                                                                            <Col lg={6}>
                                                                                <div>
                                                                                    <p className="text-muted">Fotoğraf kareye dönüştürlecektir. Bu yüzden kare fotoğraf yüklemeniz önerilir.</p>
                                                                                    <input className="form-control" type="file" id="formFile" onChange={handleImageChange} />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="firstNameinput" className="form-label">ŞARAP ADI<span style={{ color: "red" }}>*</span></Label>
                                                                <Input value={formValues.name} onChange={(e) => setFormValues({ ...formValues, name: e.target.value })} type="text" className="form-control" id="firstNameinput" />
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="fiyat" className="form-label">FİYAT</Label>
                                                                <select value={formValues.price} onChange={(e) => setFormValues({ ...formValues, price: e.target.value })} id="fiyat" className="form-select" data-choices data-choices-sorting="true" >
                                                                    <option value="">Seçiniz</option>
                                                                    {
                                                                        dropdownItems.prices.map((item, index) => (
                                                                            <option key={index} value={item.value}>{item.text}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </Col>

                                                        {/* 2. SATIR*/}
                                                        <hr />
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="ForminputState" className="form-label">REKOLTESİ<span style={{ color: "red" }}>*</span></Label>
                                                                <Input value={formValues.harvest} onChange={(e) => setFormValues({ ...formValues, harvest: e.target.value })} type="number" className="form-control" id="harvest" placeholder="Yıl" />
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="ForminputState" className="form-label">KATEGORİSİ</Label>
                                                                <select value={formValues.category} onChange={(e) => setFormValues({ ...formValues, category: e.target.value })} id="ForminputState" className="form-select" data-choices data-choices-sorting="true" >
                                                                    <option value="">Seçiniz</option>
                                                                    <option>Yok</option>
                                                                    {
                                                                        dropdownItems.category.map((item, index) => (
                                                                            <option key={index}>{item.Category}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="ForminputState" className="form-label">ŞARAP STİLİ<span style={{ color: "red" }}>*</span></Label>
                                                                <select value={formValues.wineStyle} onChange={(e) => setFormValues({ ...formValues, wineStyle: e.target.value })} id="ForminputState" className="form-select" data-choices data-choices-sorting="true" >
                                                                    <option value="">Seçiniz</option>
                                                                    {
                                                                        dropdownItems.wineStyle.map((item, index) => (
                                                                            <option key={index} value={item.id}>{item.WineName}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="ForminputState" className="form-label">RENK</Label>
                                                                <select value={formValues.color} onChange={(e) => setFormValues({ ...formValues, color: e.target.value })} id="ForminputState" className="form-select" data-choices data-choices-sorting="true" >
                                                                    <option value="">Seçiniz</option>
                                                                    <option>Yok</option>
                                                                    {
                                                                        dropdownItems.color.map((item, index) => (
                                                                            <option key={index} value={item.id}>{item.ColorName}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="ForminputState" className="form-label">MEŞE CİNSİ</Label>
                                                                <select value={formValues.woodType} onChange={(e) => setFormValues({ ...formValues, woodType: e.target.value })} id="ForminputState" className="form-select" data-choices data-choices-sorting="true" >
                                                                    <option value="">Seçiniz</option>
                                                                    <option>Yok</option>
                                                                    {
                                                                        dropdownItems.barrel.map((item, index) => (
                                                                            <option key={index}>{item.Barrel}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="lastNameinput" className="form-label">MEŞE SÜRESİ (AY)</Label>
                                                                <Input value={formValues.woodTime} onChange={(e) => setFormValues({ ...formValues, woodTime: e.target.value })} type="number" className="form-control" placeholder="Süre" id="lastNameinput" />
                                                            </div>
                                                        </Col>


                                                        {/* 3. SATIR*/}
                                                        <hr />
                                                        <Col md={2}>
                                                            <Label htmlFor="compnayNameinput" className="form-label">İDEAL YILLANMA SÜRESİ (YIL) - MIN</Label>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Label htmlFor="phonenumberInput" className="form-label">MAKS</Label>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Label htmlFor="ForminputState" className="form-label">İDEAL KADEH TİPİ<span style={{ color: "red" }}>*</span></Label>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Label htmlFor="emailidInput" className="form-label">İDEAL KARAF/HAVALANDIRMA SÜRESİ</Label>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Label htmlFor="compnayNameinput" className="form-label">İDEAL SERVİS SICAKLIĞI<span style={{ color: "red" }}>*</span> - MIN</Label>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Label htmlFor="phonenumberInput" className="form-label">MAKS</Label>
                                                        </Col>

                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Input value={formValues.idealOlderTime[0]} onChange={(e) => setFormValues({ ...formValues, idealOlderTime: [e.target.value, formValues.idealOlderTime[1]] })} type="number" className="form-control" id="compnayNameinput" placeholder='Min' />
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Input value={formValues.idealOlderTime[1]} onChange={(e) => setFormValues({ ...formValues, idealOlderTime: [formValues.idealOlderTime[0], e.target.value] })} type="number" className="form-control" id="phonenumberInput" placeholder='Max' />
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <select value={formValues.idealGlassType} onChange={(e) => setFormValues({ ...formValues, idealGlassType: e.target.value })} id="ForminputState" className="form-select" data-choices data-choices-sorting="true" >
                                                                    <option value="">Seçiniz</option>
                                                                    {
                                                                        dropdownItems.glassType.map((item, index) => (
                                                                            <option key={index}>{item.GlassName}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Input value={formValues.idealVentilateTime} onChange={(e) => setFormValues({ ...formValues, idealVentilateTime: e.target.value })} type="number" className="form-control" placeholder="Dakika" id="emailidInput" />
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Input value={formValues.idealServiceTemp[0]} onChange={(e) => setFormValues({ ...formValues, idealServiceTemp: [e.target.value, formValues.idealServiceTemp[1]] })} type="number" className="form-control" placeholder="Min °C" id="compnayNameinput" />
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Input value={formValues.idealServiceTemp[1]} onChange={(e) => setFormValues({ ...formValues, idealServiceTemp: [formValues.idealServiceTemp[0], e.target.value] })} type="number" className="form-control" placeholder="Max °C" id="phonenumberInput" />
                                                            </div>
                                                        </Col>

                                                        {/* 4. SATIR*/}
                                                        <hr />
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="ForminputState" className="form-label">ASİDİTE<span style={{ color: "red" }}>*</span></Label>
                                                                <select defaultValue={0} value={formValues.asidite} onChange={(e) => setFormValues({ ...formValues, asidite: e.target.value })} id="ForminputState" className="form-select" data-choices data-choices-sorting="true" >
                                                                    <option value="">Seçiniz</option>
                                                                    <option value={0}>Yok</option>
                                                                    <option value={20}>Düşük</option>
                                                                    <option value={40}>Orta -</option>
                                                                    <option value={60}>Orta</option>
                                                                    <option value={80}>Orta +</option>
                                                                    <option value={100}>Yüksek</option>
                                                                </select>
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="ForminputState" className="form-label">TANEN<span style={{ color: "red" }}>*</span></Label>
                                                                <select defaultValue={0} value={formValues.tanen} onChange={(e) => setFormValues({ ...formValues, tanen: e.target.value })} id="ForminputState" className="form-select" data-choices data-choices-sorting="true" >
                                                                    <option value="">Seçiniz</option>
                                                                    <option value={0}>Yok</option>
                                                                    <option value={20}>Düşük</option>
                                                                    <option value={40}>Orta -</option>
                                                                    <option value={60}>Orta</option>
                                                                    <option value={80}>Orta +</option>
                                                                    <option value={100}>Yüksek</option>
                                                                </select>
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="ForminputState" className="form-label">GÖVDE<span style={{ color: "red" }}>*</span></Label>
                                                                <select defaultValue={0} value={formValues.bole} onChange={(e) => setFormValues({ ...formValues, bole: e.target.value })} id="ForminputState" className="form-select" data-choices data-choices-sorting="true" >
                                                                    <option value="">Seçiniz</option>
                                                                    <option value={0}>Yok</option>
                                                                    <option value={20}>Düşük</option>
                                                                    <option value={40}>Orta -</option>
                                                                    <option value={60}>Orta</option>
                                                                    <option value={80}>Orta +</option>
                                                                    <option value={100}>Yüksek</option>
                                                                </select>
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="address1ControlTextarea" className="form-label">ALKOL ORANI %<span style={{ color: "red" }}>*</span></Label>
                                                                <Input value={formValues.alcohol} onChange={(e) => setFormValues({ ...formValues, alcohol: e.target.value })} type="number" className="form-control" placeholder="Alkol Oranı %" id="address1ControlTextarea" />
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="ForminputStatess" className="form-label">Company<span style={{ color: "red" }}>*</span></Label>
                                                                <select value={formValues.companyId} onChange={(e) => setFormValues({ ...formValues, companyId: e.target.value })} id="ForminputState" className="form-select" data-choices data-choices-sorting="true" disabled={!isAdmin()}>
                                                                    <option value="">Seçiniz</option>
                                                                    <option>Yok</option>
                                                                    {
                                                                        dropdownItems.companies.map((item, index) => (
                                                                            <option key={index} value={item.id}>{item.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </TabPane>

                                            <TabPane id="region-and-grapes" tabId={5}>
                                                <div>
                                                    <Row>
                                                        <Col xl={6}>
                                                            <Card>
                                                                <CardHeader>
                                                                    <h4 className="card-title mb-0">Kayıtlı Olan Bölgeler</h4>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col lg={12}>
                                                                            <Card>
                                                                                <CardBody >
                                                                                    <Row className="row-cols-lg-auto g-3 align-items-center">
                                                                                        <Col xs={12}>
                                                                                            <select value={(!enable.region) ? formValues.region.name : null} onChange={(e) => handleRegion({ name: e.target.value })} className="form-select" data-choices data-choices-sorting="true" id="inlineFormSelectPref" disabled={enable.region}>
                                                                                                <option value="">Seçiniz</option>
                                                                                                <option>Yok</option>
                                                                                                {
                                                                                                    dropdownItems.regions.map((item, index) => (
                                                                                                        <option key={index}>{item.name}</option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                        </Col>
                                                                                        <Col xs={12}>
                                                                                            <div className="form-check form-check-warning">
                                                                                                <Input className="form-check-input" type="checkbox" id="inlineFormCheck" onChange={(e) => handleRegionEnable(e.target.checked)} />
                                                                                                <Label className="form-check-label" htmlFor="inlineFormCheck">
                                                                                                    Yapıldığı bölge seçenekler içinde yok.
                                                                                                </Label>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col xl={6}>
                                                            <Card>
                                                                <CardHeader>
                                                                    <h4 className="card-title mb-0">Bölgeyi kendim belirlemek istiyorum</h4>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col lg={12}>
                                                                            <Col xs={12}>
                                                                                <Input value={(enable.region) ? formValues.region.name : null} onChange={(e) => { handleRegion({ name: e.target.value, isNew: true }) }} type="text" className="form-control" placeholder="Bölge adı" disabled={!enable.region} />
                                                                            </Col>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <Col xl={6}>
                                                            <Card>
                                                                <CardHeader>
                                                                    <h4 className="card-title mb-0">Kayıtlı Olan Üzüm Çeşitleri</h4>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col lg={12}>
                                                                            <Card>
                                                                                <CardBody >
                                                                                    <Row className="row-cols-lg-auto g-3 align-items-center">
                                                                                        <Col xs={12}>
                                                                                            <Label className="visually-hidden" htmlFor="inlineFormSelectPref">Üzüm Çeşidi</Label>
                                                                                            <select className="form-select" data-choices data-choices-sorting="true" id="inlineFormSelectPref">
                                                                                                <option value="">Seçiniz</option>
                                                                                                {
                                                                                                    dropdownItems.grapes.map((item, index) => (
                                                                                                        <option key={index} value={item.GrapeName}>{item.GrapeName}</option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                        </Col>
                                                                                        <Col xs={12}>
                                                                                            <Input type="number" className="form-control" placeholder="Üzüm Yüzdesi %" />
                                                                                        </Col>
                                                                                        <Col xs={12}>
                                                                                            <button type="button" className="btn btn-success" onClick={(e) => handleGrapeAdd({ e })}>+</button>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr />
                                                                    {
                                                                        formValues.grapes.map((item, index) => (!item.isNew) ? (
                                                                            <Row key={index}>
                                                                                <Col lg={12}>
                                                                                    <Card>
                                                                                        <CardBody >
                                                                                            <Row className="row-cols-lg-auto g-3 align-items-center">
                                                                                                <Col xs={12}>
                                                                                                    <Label className="visually-hidden" htmlFor="inlineFormSelectPref">Preference</Label>
                                                                                                    <select value={item.GrapeName} onChange={(e) => handleGrapeDetail({ id: item.Id, name: e.target.value })} className="form-select" data-choices data-choices-sorting="true" id="inlineFormSelectPref">
                                                                                                        {
                                                                                                            dropdownItems.grapes.map((item, index) => (
                                                                                                                <option key={index}>{item.GrapeName}</option>
                                                                                                            ))
                                                                                                        }
                                                                                                    </select>
                                                                                                </Col>
                                                                                                <Col xs={12}>
                                                                                                    <Input value={item.GrapePercent} onChange={(e) => handleGrapeDetail({ id: item.Id, percent: e.target.value })} type="number" className="form-control" placeholder="Üzüm Yüzdesi %" />
                                                                                                </Col>
                                                                                                <Col xs={12}>
                                                                                                    <button type="button" className="btn btn-danger" onClick={(e) => handleGrapeRemove({ e, id: item.Id })}>-</button>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </CardBody>
                                                                                    </Card>
                                                                                </Col>
                                                                            </Row>
                                                                        ) : (""))
                                                                    }
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col xl={6}>
                                                            <Card>
                                                                <CardHeader>
                                                                    <h4 className="card-title mb-0">Kayıtlı Olmayan Üzüm Çeşitleri</h4>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col lg={12}>
                                                                            <Card>
                                                                                <CardBody >
                                                                                    <Row className="row-cols-lg-auto g-3 align-items-center">
                                                                                        <Col xs={12}>
                                                                                            <Input type="text" className="form-control" placeholder="Üzüm Adı" />
                                                                                        </Col>
                                                                                        <Col xs={12}>
                                                                                            <Input type="number" className="form-control" placeholder="Üzüm Yüzdesi %" />
                                                                                        </Col>
                                                                                        <Col xs={12}>
                                                                                            <button type="button" className="btn btn-success" onClick={(e) => handleGrapeAdd({ e, isNew: true })}>+</button>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr />
                                                                    {
                                                                        formValues.grapes.map((item, index) => (item.isNew) ? (
                                                                            <Row key={index}>
                                                                                <Col lg={12}>
                                                                                    <Card>
                                                                                        <CardBody >
                                                                                            <Row className="row-cols-lg-auto g-3 align-items-center">
                                                                                                <Col xs={12}>
                                                                                                    <Label className="visually-hidden" htmlFor="inlineFormSelectPref">Preference</Label>
                                                                                                    <Input value={item.GrapeName} onChange={(e) => handleGrapeDetail({ id: item.Id, name: e.target.value })} type="text" className="form-control" placeholder="Üzüm Adı" />
                                                                                                </Col>
                                                                                                <Col xs={12}>
                                                                                                    <Input value={item.GrapePercent} onChange={(e) => handleGrapeDetail({ id: item.Id, percent: e.target.value })} type="number" className="form-control" placeholder="Üzüm Yüzdesi %" />
                                                                                                </Col>
                                                                                                <Col xs={12}>
                                                                                                    <button type="button" className="btn btn-danger" onClick={(e) => handleGrapeRemove({ e, id: item.Id })}>-</button>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </CardBody>
                                                                                    </Card>
                                                                                </Col>
                                                                            </Row>
                                                                        ) : (""))
                                                                    }
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </TabPane>

                                            <TabPane id="aromas" tabId={6}>
                                                <a className="necessary-warning" style={{color: 'red', fontSize: '18px' }}>Lütfen en az 1 seçim yapınız!</a>
                                                <Row>
                                                    {
                                                        print.aromas.map((item, index) => (
                                                            <Col key={index} xl={3}>
                                                                <Card>
                                                                    <CardHeader>
                                                                        <h4 className="card-title mb-0 text-danger">
                                                                            <div className="d-flex">
                                                                                <div className="flex-grow-1">
                                                                                    <div className="form-check form-check-danger mb-3">
                                                                                        <Input className="form-check-input" type="checkbox" checked={Boolean(getNumberValueOfBool(item.selectedAll))} onChange={(e) => handleSelectAllAromas(item.name, e.target.checked)} />
                                                                                        <Label className="form-check-label">
                                                                                            {item.name}
                                                                                        </Label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </h4>
                                                                    </CardHeader>
                                                                    <CardBody>
                                                                        <div>
                                                                            <SimpleBar style={{ height: "242px" }} className="mx-n3">
                                                                                <ListGroup className="list mb-0" flush>
                                                                                    {
                                                                                        item.aromas.map((subItem, subIndex) => (
                                                                                            <ListGroupItem key={subIndex}>
                                                                                                <div className="d-flex">
                                                                                                    <div className="flex-grow-1">
                                                                                                        <div className="form-check form-check-danger mb-3">
                                                                                                            <Input className="form-check-input" type="checkbox" checked={Boolean(getNumberValueOfBool(subItem.selected))} onChange={(e) => handleAromaChange({ id: subItem.id, checked: e.target.checked })} />
                                                                                                            <Label className="form-check-label">
                                                                                                                {subItem.name}
                                                                                                            </Label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </ListGroupItem>
                                                                                        ))
                                                                                    }
                                                                                </ListGroup >
                                                                            </SimpleBar>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        ))
                                                    }
                                                </Row>
                                            </TabPane>

                                            <TabPane id="foods" tabId={7}>

                                                <a className="necessary-warning" style={{ color:'red', fontSize: '18px' }}>Lütfen en az 1 seçim yapınız!</a>
                                                <Row>
                                                    {
                                                        print.foods.map((item, index) => (
                                                            <Col key={index} xl={3}>
                                                                <Card>
                                                                    <CardHeader>
                                                                        <h4 className="card-title mb-0 text-danger">
                                                                            <div className="d-flex">
                                                                                <div className="flex-grow-1">
                                                                                    <div className="form-check form-check-danger mb-3">
                                                                                        <Input className="form-check-input" type="checkbox" checked={Boolean(getNumberValueOfBool(item.selectedAll))} onChange={(e) => handleSelectAllFoods(item.name, e.target.checked)} />
                                                                                        <Label className="form-check-label">
                                                                                            {item.name}
                                                                                        </Label>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </h4>
                                                                    </CardHeader>
                                                                    <CardBody>
                                                                        <div>
                                                                            <SimpleBar style={{ height: "242px" }} className="mx-n3">
                                                                                <ListGroup className="list mb-0" flush>
                                                                                    {
                                                                                        item.foods.map((subItem, subIndex) => (
                                                                                            <ListGroupItem key={subIndex}>
                                                                                                <div className="d-flex">
                                                                                                    <div className="flex-grow-1">
                                                                                                        <div className="form-check form-check-danger mb-3">
                                                                                                            <Input className="form-check-input" type="checkbox" checked={Boolean(getNumberValueOfBool(subItem.selected))} onChange={(e) => handleFoodChange({ id: subItem.id, checked: e.target.checked })} />
                                                                                                            <Label className="form-check-label">
                                                                                                                {subItem.name}
                                                                                                            </Label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </ListGroupItem>
                                                                                        ))
                                                                                    }
                                                                                </ListGroup >
                                                                            </SimpleBar>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        ))
                                                    }
                                                </Row>
                                            </TabPane>

                                            <TabPane id="awards-and-notes" tabId={8}>
                                                <div>
                                                    <Row>
                                                        <Col xl={12}>
                                                            <Card>
                                                                <CardHeader>
                                                                    <h4 className="card-title mb-0">Aldığı Ödüller</h4>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col lg={12}>
                                                                            <Card>
                                                                                <CardBody >
                                                                                    <Row className="row-cols-lg-auto g-3 align-items-center">
                                                                                        <Col xs={12}>
                                                                                            <Input type="text" className="form-control" placeholder="Ad" />
                                                                                        </Col>
                                                                                        <Col xs={12}>
                                                                                            <Input type="number" className="form-control" placeholder="Yıl" />
                                                                                        </Col>
                                                                                        <Col xs={12}>
                                                                                            <Input type="text" className="form-control" placeholder="Derece" />
                                                                                        </Col>
                                                                                        <Col xs={12}>
                                                                                            <button type="button" className="btn btn-success" onClick={(e) => handleAwardAdd({ e })}>+</button>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr />
                                                                    {
                                                                        formValues.awards.map((item, index) => (
                                                                            <Row key={index}>
                                                                                <Col lg={12}>
                                                                                    <Card>
                                                                                        <CardBody >
                                                                                            <Row className="row-cols-lg-auto g-3 align-items-center">
                                                                                                <Col xs={12}>
                                                                                                    <Label className="visually-hidden" htmlFor="inlineFormSelectPref">Preference</Label>
                                                                                                    <Input value={item.name} onChange={(e) => handleAwardDetail({ id: item.Id, name: e.target.value })} type="text" className="form-control" placeholder="Ad" />
                                                                                                </Col>
                                                                                                <Col xs={12}>
                                                                                                    <Input value={item.year} onChange={(e) => handleAwardDetail({ id: item.Id, year: e.target.value })} type="number" className="form-control" placeholder="Yıl" />
                                                                                                </Col>
                                                                                                <Col xs={12}>
                                                                                                    <Input value={item.place} onChange={(e) => handleAwardDetail({ id: item.Id, place: e.target.value })} type="text" className="form-control" placeholder="Derece" />
                                                                                                </Col>
                                                                                                <Col xs={12}>
                                                                                                    <button type="button" className="btn btn-danger" onClick={(e) => handleAwardRemove({ e, id: item.Id })}>-</button>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </CardBody>
                                                                                    </Card>
                                                                                </Col>
                                                                            </Row>
                                                                        ))
                                                                    }
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <Col xs={12}>
                                                            <Card>
                                                                <CardHeader>
                                                                    <h4 className="card-title mb-0">Ekstra Notlar</h4>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md={6}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="firstNameinpu" className="form-label">Türkçe</Label>
                                                                                <Input value={formValues.extraNotes} onChange={(e) => setFormValues({ ...formValues, extraNotes: e.target.value })} type="textarea" className="form-control" id="firstNameinpu" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="firstNameinpu" className="form-label">English</Label>
                                                                                <Input value={formValues.extraNotesEn} onChange={(e) => setFormValues({ ...formValues, extraNotesEn: e.target.value })} type="textarea" className="form-control" id="firstNameinpu" />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </fieldset>
                                </Form>
                            </CardBody>
                        </Card>

                    </Container>
                </fieldset>
            </div>
        </>
    );
}

export default WineDetails;