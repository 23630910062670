import { md5Control, tokenControl } from "../common/middlewareHeaders";
import fetchToAPI from "../common/fetchToAPI";
import CustomError from "../../error/CustomError";
import { getPremiumCodesURL, getPremiumCodeURL, addPremiumCodeURL, updatePremiumCodeURL, deletePremiumCodeURL, changeValidOfPremiumCodeURL } from "../common/endpointURLs";

const getPremiumCodes = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getPremiumCodesURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getPremiumCode = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: getPremiumCodeURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const deletePremiumCode = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: deletePremiumCodeURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const changeValidOfPremiumCode = async ({
    id,
    isValid
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: changeValidOfPremiumCodeURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id,
                isValid
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const addPremiumCode = async ({
    code,
    monthCount,
    usageLimit,
    isValid,
    expireDate,
    now
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: addPremiumCodeURL,
            headers: md5Control(),
            method: "POST",
            body: {
                code,
                monthCount,
                usageLimit,
                isValid,
                expireDate,
                now
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const updatePremiumCode = async ({
    id,
    code,
    monthCount,
    usageLimit,
    isValid,
    expireDate
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: updatePremiumCodeURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id,
                code,
                monthCount,
                usageLimit,
                isValid,
                expireDate
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

export {
    getPremiumCodes,
    getPremiumCode,
    deletePremiumCode,
    changeValidOfPremiumCode,
    addPremiumCode,
    updatePremiumCode
};