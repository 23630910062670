import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from "reactstrap";
import { Grid, _ } from 'gridjs-react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import useAuth from '../../helpers/auth/useAuth';
import { useHistory } from 'react-router-dom';
import { changeActivityOfActivity, deleteActivity, getAllActivities } from '../../helpers/api/information/information';

const ActivityTable = () => {
    const [activities, setActivities] = useState([]);

    const history = useHistory();
    const { auth } = useAuth();

    document.title = "Activities";

    const getActivities = async () => {
        try {
            setActivities([]);

            let response = await getAllActivities();
            if (!response.success) throw response.data;

            setActivities(response.data.map(activity => {
                return [
                    activity.id,
                    activity.ActivityName,
                    activity.ActivityPlace,
                    activity.ActivityTime.split("T")[0],
                    (activity.ActivityDeleted == 0) ? "Yes" : "No",
                    activity.ActivityPicture
                ]
            }));
        } catch (error) {
            console.log(error);
        }
    }

    const handleClickGridItem = (e) => {
        e.preventDefault();
        let row = e.target.parentElement.parentElement.parentElement;
        let id = row.children[0].innerText;

        history.push("/activity-details", { params: { id, type: "INFORM" } });
    }

    const handleAddActivity = () => {
        history.push("/activity-details", { params: { type: "ADD" } });
    }

    const handleChangeActivity = async (e) => {
        try {
            e.preventDefault();
            let row = e.target.parentElement.parentElement.parentElement;
            let id = row.children[0].innerText;

            let item = activities.find(w => w[0] == id);
            let deleted;
            if (item[4] == "Yes") deleted = 1;
            else deleted = 0;

            let response = await changeActivityOfActivity({
                id,
                activity: deleted
            });
            if (!response.success) return console.log(response.data);

            await getActivities();
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeleteActivity = async (e) => {
        try {
            e.preventDefault();

            confirmAlert({
                title: 'Confirm to Delete',
                message: 'Are you sure to delete this activity?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let row = e.target.parentElement.parentElement.parentElement;
                            let id = row.children[0].innerText;

                            let response = await deleteActivity({
                                id
                            });
                            if (!response.success) return console.log(response.data);

                            await getActivities();
                        }
                    },
                    {
                        label: 'No'
                    }
                ]
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getActivities();
    }, []);

    return (
        <>
            <div className="page-content">
                <Row>
                    <Col xs="10" />
                    <Col xs="2">
                        <Button onClick={handleAddActivity} color="danger">Activity Ekle</Button>
                    </Col>
                </Row>
                <Container fluid={true}>
                    <React.Fragment>
                        <Grid
                            data={activities}
                            columns={[
                                "#", "Name", "Place", "Date", "Active",
                                {
                                    name: 'Picture',
                                    width: '120px',
                                    formatter: (cell) => _(
                                        <>
                                            {(cell)
                                                ? <img src={cell} alt="aroma" style={{ width: "100px", height: "100px" }} />
                                                : <></>
                                            }
                                        </>
                                    )
                                },
                                {
                                    name: 'Actions',
                                    width: '120px',
                                    formatter: (cell) => _(
                                        <>
                                            <Button type='button' onClick={handleClickGridItem} className='btn btn-sm btn-light'> Details </Button>
                                            <Button type='button' onClick={handleDeleteActivity} className='btn btn-sm btn-danger'> Delete </Button>
                                            <Button type='button' onClick={handleChangeActivity} className='btn btn-sm btn-warning'> Change Activity </Button>
                                        </>
                                    )
                                }
                            ]}
                            search={true}
                            sort={true}
                            pagination={{ enabled: true, limit: 10 }}
                        />
                    </React.Fragment>
                </Container>
            </div>
        </>
    );
}

export default ActivityTable;