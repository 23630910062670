import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from "reactstrap";
import { Grid, _ } from 'gridjs-react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { useHistory } from 'react-router-dom';
import { deleteMainFood, getMainDishes } from '../../helpers/api/product/product';

const MainFoodTable = () => {
    const [foods, setFoods] = useState([]);

    const history = useHistory();

    document.title = "My Main Foods";

    const getFoods = async () => {
        try {
            setFoods([]);
            let response = await getMainDishes();
            if (!response.success) throw response.data;

            setFoods(response.data.map(food => {
                return [
                    food.id,
                    food.MainDishName,
                    food.MainDishPicture
                ]
            }));
        } catch (error) {
            console.log(error);
        }
    }

    const handleClickGridItem = (e) => {
        e.preventDefault();
        let row = e.target.parentElement.parentElement.parentElement;
        let id = row.children[0].innerText;

        history.push("/main-food-details", { params: { id, type: "INFORM" } });
    }

    const handleAddMainFood = () => {
        history.push("/main-food-details", { params: { type: "ADD" } });
    }

    const handleClickDelete = async (e) => {
        try {
            e.preventDefault();

            confirmAlert({
                title: 'Confirm to Delete',
                message: 'Are you sure to delete this main food?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let row = e.target.parentElement.parentElement.parentElement;
                            let id = row.children[0].innerText;

                            let response = await deleteMainFood({ id });
                            if (!response.success) return console.log(response.data);

                            await getFoods();
                        }
                    },
                    {
                        label: 'No'
                    }
                ]
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getFoods();
    }, []);

    return (
        <>
            <div className="page-content">
                <Row>
                    <Col xs="10" />
                    <Col xs="2">
                        <Button onClick={handleAddMainFood} color="danger">Main Food Ekle</Button>
                    </Col>
                </Row>
                <Container fluid={true}>
                    <React.Fragment>
                        <Grid
                            data={foods}
                            columns={[
                                "#", "Name",
                                {
                                    name: 'Picture',
                                    width: '120px',
                                    formatter: (cell) => _(
                                        <>
                                            {(cell)
                                                ? <img src={cell} alt="main-food" style={{ width: "100px", height: "100px" }} />
                                                : <></>
                                            }
                                        </>
                                    )
                                },
                                {
                                    name: 'Actions',
                                    width: '120px',
                                    formatter: (cell) => _(
                                        <>
                                            <Button type='button' onClick={handleClickGridItem} className='btn btn-sm btn-light'> Details </Button>
                                            <Button type='button' onClick={handleClickDelete} className='btn btn-sm btn-danger'> Delete </Button>
                                        </>
                                    )
                                }
                            ]}
                            search={true}
                            sort={true}
                            pagination={{ enabled: true, limit: 10 }}
                        />
                    </React.Fragment>
                </Container>
            </div>
        </>
    );
}

export default MainFoodTable;