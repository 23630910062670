import axios from "axios";

import Response from "../../response/Response";
import CustomError from "../../error/CustomError";

import {localhostURL, modernURL } from "./endpointURLs";

const currentURL = modernURL;

const fetchToAPI = async ({
    endpoint,
    body = {},
    headers = {},
    method = "POST",
    contentType = "application/json",
}) => {
    try {
        let url = currentURL + endpoint;
        let response;

        if (method == "GET") {
            response = await axios.get(url, {
                headers: {
                    "Content-Type": contentType,
                    ...headers
                }
            })
        } else if (method == "POST") {
            let formData = new FormData();

            for (let key in body) {
                if (typeof body[key] == "object" && key != "image") formData.append(key, JSON.stringify(body[key]));
                else formData.append(key, body[key]);
            }
            if (contentType == "application/json") formData = body;

            response = await axios({
                method: "POST",
                url: url,
                data: formData,
                headers: {
                    "Content-Type": contentType,
                    ...headers
                }
            });
        }

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

export default fetchToAPI;