import { md5Control, tokenControl } from "../common/middlewareHeaders";
import fetchToAPI from "../common/fetchToAPI";
import CustomError from "../../error/CustomError";
import { addProducerURL, deleteProducerURL, getArtisansURL, getProducerDetailsURL, updateProducerURL } from "../common/endpointURLs";

const getArtisans = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getArtisansURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getProducerDetails = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: getProducerDetailsURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const addProducer = async ({
    name,
    producerExplain,
    producerExplainEn,
    vineyard,
    vineyardEn,
    vineyardNo,
    vineyardNoEn,
    grapes,
    image,
    tours,
    toursEn,
    accomodation,
    accomodationEn,
    restaurant,
    restaurantEn,
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: addProducerURL,
            headers: md5Control(),
            method: "POST",
            body: {
                name,
                producerExplain,
                producerExplainEn,
                vineyard,
                vineyardEn,
                vineyardNo,
                vineyardNoEn,
                grapes,
                image,
                tours,
                toursEn,
                accomodation,
                accomodationEn,
                restaurant,
                restaurantEn,
            },
            contentType: "multipart/form-data"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const updateProducer = async ({
    id,
    name,
    producerExplain,
    producerExplainEn,
    vineyard,
    vineyardEn,
    vineyardNo,
    vineyardNoEn,
    image,
    tours,
    toursEn,
    accomodation,
    accomodationEn,
    restaurant,
    restaurantEn,
    grapes
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: updateProducerURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id,
                name,
                producerExplain,
                producerExplainEn,
                vineyard,
                vineyardEn,
                vineyardNo,
                vineyardNoEn,
                image,
                tours,
                toursEn,
                accomodation,
                accomodationEn,
                restaurant,
                restaurantEn,
                grapes
            },
            contentType: "multipart/form-data"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const deleteProducer = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: deleteProducerURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

export {
    getArtisans,
    getProducerDetails,
    addProducer,
    updateProducer,
    deleteProducer
};