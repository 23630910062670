import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from "reactstrap";
import { Grid, _ } from 'gridjs-react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { useHistory } from 'react-router-dom';
import { getNotifications } from '../../helpers/api/notification/notification';

const NotificationTable = () => {
    const [notifications, setNotifications] = useState([]);

    const history = useHistory();

    document.title = "Notifications";

    const getNotificationList = async () => {
        try {
            setNotifications([]);
            let response = await getNotifications();
            if (!response.success) throw response.data;

            setNotifications(response.data.map(notification => {
                return [
                    notification.id,
                    notification.Title,
                    notification.CreatedAt,
                    notification.SenderType,
                    notification.SenderEmail,
                    notification.IsForAll,
                    notification.NotificationType,
                ]
            }));
        } catch (error) {
            console.log(error);
        }
    }

    const handleClickGridItem = (e) => {
        e.preventDefault();
        let row = e.target.parentElement.parentElement.parentElement;
        let id = row.children[0].innerText;

        history.push("/notification-details", { params: { id, type: "INFORM" } });
    }

    const handleAddNotification = () => {
        history.push("/notification-details", { params: { type: "ADD" } });
    }

    useEffect(() => {
        getNotificationList();
    }, []);

    return (
        <>
            <div className="page-content">
                <Row>
                    <Col xs="10" />
                    <Col xs="2">
                        <Button onClick={handleAddNotification} color="danger">Notification Gönder</Button>
                    </Col>
                </Row>
                <Container fluid={true}>
                    <React.Fragment>
                        <Grid
                            data={notifications}
                            columns={[
                                "#", "Title", "Created At", "Sender Type", "Sender Email", "Is For All", "Notification Type",
                                {
                                    name: 'Actions',
                                    width: '120px',
                                    formatter: (cell) => _(
                                        <>
                                            <Button type='button' onClick={handleClickGridItem} className='btn btn-sm btn-light'> Details </Button>
                                        </>
                                    )
                                }
                            ]}
                            search={true}
                            sort={true}
                            pagination={{ enabled: true, limit: 10 }}
                        />
                    </React.Fragment>
                </Container>
            </div>
        </>
    );
}

export default NotificationTable;