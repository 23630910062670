import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import useAuth from "../helpers/auth/useAuth";

const Navdata = () => {
    const { auth } = useAuth();

    const producerItems = {
        id: "Product",
        label: "Product",
        link: "/",
        click: function (e) {
            e.preventDefault();
        },
        subItems: [
            {
                id: "WineTable",
                label: "Wine Table",
                link: "/wine-table",
                parentId: "Product"
            }
        ]
    };

    const adminItems = {
        id: "AdminPanel",
        label: "Admin Panel",
        link: "/",
        click: function (e) {
            e.preventDefault();
        },
        subItems: [
            {
                id: "Products",
                label: "Products",
                parentId: "AdminPanel",
                click: function (e) {
                    e.preventDefault();
                },
                isChildItem: true,
                childItems: [
                    {
                        id: "GrapeTable",
                        label: "Grape Table",
                        link: "/grape-table",
                        parentId: "Products"
                    },
                    {
                        id: "AromaTable",
                        label: "Aroma Table",
                        link: "/aroma-table",
                        parentId: "Products"
                    },
                    {
                        id: "MainAromaTable",
                        label: "Main Aroma Table",
                        link: "/main-aroma-table",
                        parentId: "Products"
                    },
                    {
                        id: "FoodTable",
                        label: "Food Table",
                        link: "/food-table",
                        parentId: "Products"
                    },
                    {
                        id: "MainFoodTable",
                        label: "Main Food Table",
                        link: "/main-food-table",
                        parentId: "Products"
                    },
                    {
                        id: "GrapeOfTheWeekTable",
                        label: "Grape Of The Week Table",
                        link: "/grape-of-the-week-table",
                        parentId: "Products"
                    }
                ]
            },
            {
                id: "Informations",
                label: "Informations",
                parentId: "AdminPanel",
                click: function (e) {
                    e.preventDefault();
                },
                isChildItem: true,
                childItems: [
                    {
                        id: "ProducerTable",
                        label: "Producer Table",
                        link: "/producer-table",
                        parentId: "Informations"
                    },
                    {
                        id: "PriceTable",
                        label: "Price Table",
                        link: "/price-table",
                        parentId: "Informations"
                    },
                    {
                        id: "AdminTable",
                        label: "Admin Table",
                        link: "/admin-table",
                        parentId: "Informations"
                    },
                    {
                        id: "BlogTable",
                        label: "Blog Table",
                        link: "/blog-table",
                        parentId: "Informations"
                    },
                    {
                        id: "ActivityTable",
                        label: "Activity Table",
                        link: "/activity-table",
                        parentId: "Informations"
                    },
                    {
                        id: "ColorTable",
                        label: "Color Table",
                        link: "/color-table",
                        parentId: "Informations"
                    },
                    {
                        id: "MapPictureTable",
                        label: "Map Picture Table",
                        link: "/map-picture-table",
                        parentId: "Informations"
                    },
                    {
                        id: "GlassTypeTable",
                        label: "Glass Table",
                        link: "/glass-table",
                        parentId: "Informations"
                    },
                    {
                        id: "NotificationTable",
                        label: "Notification Table",
                        link: "/notification-table",
                        parentId: "Informations"
                    },
                    {
                        id: "PremiumCodeTable",
                        label: "Premium Code Table",
                        link: "/premium-code-table",
                        parentId: "Informations"
                    },
                    {
                        id: "AppKeyTable",
                        label: "App Key Table",
                        link: "/app-key-table",
                        parentId: "Informations"
                    }
                ]
            }

        ]
    }

    const menuItems = [
        {
            label: "Menu",
            isHeader: true,
        }
    ];

    if (auth.roles.includes("ADMIN")) {
        menuItems.push(producerItems);
        menuItems.push(adminItems);
    } else {
        if (auth.roles.includes("PRODUCER")) {
            menuItems.push(producerItems);
        }
    }

    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;