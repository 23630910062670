import { md5Control, tokenControl } from "../common/middlewareHeaders";
import fetchToAPI from "../common/fetchToAPI";
import CustomError from "../../error/CustomError";
import { addActivityURL, addGrapeOfTheWeekURL, addNewsURL, changeActivityOfActivityURL, deleteActivityURL, deleteGrapeOfTheWeekURL, deleteNewsURL, getActivityDetailsURL, getAllActivitiesURL, getAllNewsURL, getGrapeOfTheWeekDetailsURL, getGrapesOfTheWeeksURL, getNewsDetailsURL, updateActivityURL, updateGrapeOfTheWeekURL, updateNewsURL } from "../common/endpointURLs";

const getAllNews = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getAllNewsURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getNewsDetails = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: getNewsDetailsURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const deleteNews = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: deleteNewsURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const addNews = async ({
    name,
    text,
    time,
    url,
    image,
    now
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: addNewsURL,
            headers: md5Control(),
            method: "POST",
            body: {
                name,
                text,
                time,
                url,
                image,
                now
            },
            contentType: "multipart/form-data"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const updateNews = async ({
    id,
    name,
    text,
    time,
    url,
    image
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: updateNewsURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id,
                name,
                text,
                time,
                url,
                image
            },
            contentType: "multipart/form-data"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getAllActivities = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getAllActivitiesURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getActivityDetails = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: getActivityDetailsURL,
            headers: md5Control(),
            method: "POST",
            body: {
                activityId: id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const deleteActivity = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: deleteActivityURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const changeActivityOfActivity = async ({
    id,
    activity
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: changeActivityOfActivityURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id,
                activity
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const addActivity = async ({
    name,
    explain,
    time,
    url,
    place,
    phoneNumber,
    active,
    image,
    now
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: addActivityURL,
            headers: md5Control(),
            method: "POST",
            body: {
                name,
                explain,
                time,
                url,
                place,
                phoneNumber,
                active,
                image,
                now
            },
            contentType: "multipart/form-data"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const updateActivity = async ({
    id,
    name,
    explain,
    time,
    url,
    place,
    phoneNumber,
    active,
    image
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: updateActivityURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id,
                name,
                explain,
                time,
                url,
                place,
                phoneNumber,
                active,
                image
            },
            contentType: "multipart/form-data"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getGrapesOfTheWeeks = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getGrapesOfTheWeeksURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getGrapeOfTheWeekDetails = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: getGrapeOfTheWeekDetailsURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const deleteGrapeOfTheWeek = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: deleteGrapeOfTheWeekURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const addGrapeOfTheWeek = async ({
    startDate,
    endDate,
    grapeId,
    now
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: addGrapeOfTheWeekURL,
            headers: md5Control(),
            method: "POST",
            body: {
                startDate,
                endDate,
                grapeId,
                now
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const updateGrapeOfTheWeek = async ({
    id,
    startDate,
    endDate,
    grapeId,
    now
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: updateGrapeOfTheWeekURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id,
                startDate,
                endDate,
                grapeId,
                now
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

export {
    getAllNews,
    getNewsDetails,
    deleteNews,
    addNews,
    updateNews,
    getAllActivities,
    deleteActivity,
    changeActivityOfActivity,
    getActivityDetails,
    addActivity,
    updateActivity,
    getGrapesOfTheWeeks,
    getGrapeOfTheWeekDetails,
    deleteGrapeOfTheWeek,
    addGrapeOfTheWeek,
    updateGrapeOfTheWeek
};