import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    ListGroup,
    ListGroupItem,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Button,
    Spinner
} from "reactstrap";
import classnames from "classnames";
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import Slider from 'react-rangeslider';
import { addGrape, addWine, getAllAromasWithMainAromas, getAllBarrels, getAllColorTypes, getAllDishesWithMainDishes, getAllGlassTypes, getAllGrapes, getAllManufacturePlaces, getAllManufacturePlacesFromGrapes, getAllMotherlandNames, getAllWineCategories, getAllWineTypes, getGrapeDetailed, getWineDetails, updateGrape, updateWine } from '../../helpers/api/product/product';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import { getAllCompanies } from '../../helpers/api/auth/auth';
import useAuth from '../../helpers/auth/useAuth';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
// register lottie and define custom element
defineLordIconElement(loadAnimation);

const GrapeDetails = () => {
    const [activeArrowTab, setactiveArrowTab] = useState(4);
    const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
    const [enable, setEnable] = useState({
        all: false,
        region: false,
        motherland: false,
        doButton: true,
        cancelButton: false,
        refreshButton: true,
        form: true
    });
    const [formValues, setFormValues] = useState({
        name: "",
        category: "",
        color: "",
        idealOlderTime: "",
        idealGlassType: "",
        idealVentilateTime: 0,
        idealServiceTemp: [0, 0],
        asidite: 0,
        tanen: 0,
        bole: 0,
        region: { name: "", isNew: false },
        motherland: { name: "", isNew: false },
        aromas: [],
        foods: [],
        type: "SEÇİNİZ"
    });
    const [dropdownItems, setDropdownItems] = useState({
        category: [],
        color: [],
        glassType: [],
        regions: [],
        aromas: [],
        foods: [],
        motherlands: []
    });
    const [print, setPrint] = useState({
        enable: false,
        aromas: [],
        foods: []
    });
    const [texts, setTexts] = useState({
        title: "",
        doButton: "Ekle"
    });
    const [pageType, setPageType] = useState("");
    const [loading, setLoading] = useState(false);
    const [visibility, setVisibility] = useState({
        loading: true,
        cancelButton: true
    });

    const { auth } = useAuth();

    const history = useHistory();
    const location = useLocation();
    const { id, type } = location.state.params;

    const grapeTypes = ["RED", "WHITE"];

    function toggleArrowTab(tab) {
        if (activeArrowTab !== tab) {
            var modifiedSteps = [...passedarrowSteps, tab];

            if (tab >= 4 && tab <= 6) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    }

    const isAdmin = () => {
        if (auth.roles.includes("ADMIN")) {
            return true;
        }
        return false;
    }

    const handleGrapeDetails = async () => {
        try {
            let result = await getGrapeDetailed({ id });
            if (!result.success) return console.log("Bir hata oluştu");

            let grape = result.data;

            let grapeType = grapeTypes[0];

            if (grape.GrapePicture) {
                let list = grape.GrapePicture.split("/");
                if (list.length) {
                    if (list[list.length - 1] == "yellow_grape.png") grapeType = "WHITE";
                    else if (list[list.length - 1] == "black_grape.png") grapeType = "RED";
                }
            }

            setFormValues({
                ...formValues,
                name: grape.GrapeName || "",
                category: grape.Category || "",
                color: grape.ColorID || "",
                idealOlderTime: grape.OldingTime || "",
                idealGlassType: grape.GlassID || "",
                idealVentilateTime: grape.Ventilation || 0,
                idealServiceTemp: [grape.StartingTemperature, grape.FinishingTemperature] || [0, 0],
                asidite: grape.Characteristics.find(x => x.id == 2)?.CharacteristicRate || 0,
                tanen: grape.Characteristics.find(x => x.id === 4)?.CharacteristicRate || 0,
                bole: grape.Characteristics.find(x => x.id === 3)?.CharacteristicRate || 0,
                region: { name: grape.ManufacturePlaceName || "", isNew: false },
                aromas: grape.Aromas || [],
                foods: grape.Foods || [],
                type: grapeType || grapeTypes[0],
                motherland: { name: grape.MotherlandName || "", isNew: false }
            });



            if (type != "ADD") {
                setPrint({
                    ...print,
                    enable: true
                });
            }
        } catch (error) {
            return console.log(error);
        }
    }

    const fillDropdowns = async () => {
        try {
            let fields = {};
            { // Categories
                let responseCategories = await getAllWineCategories();
                if (!responseCategories.success) return console.log(responseCategories.data);

                fields = {
                    ...fields,
                    category: responseCategories.data
                };
            }

            { // Glass Type
                let responseGlassType = await getAllGlassTypes();
                if (!responseGlassType.success) return console.log(responseGlassType.data);

                fields = {
                    ...fields,
                    glassType: responseGlassType.data
                };
            }

            { // Color
                let responseColor = await getAllColorTypes();
                if (!responseColor.success) return console.log(responseColor.data);

                fields = {
                    ...fields,
                    color: responseColor.data
                };
            }

            { // Regions
                let responseRegion = await getAllManufacturePlaces();
                if (!responseRegion.success) return console.log(responseRegion.data);

                fields = {
                    ...fields,
                    regions: responseRegion.data.map(x => ({ name: x.ManufacturePlaceName }))
                };
            }

            { // Motherlands
                let responseMotherland = await getAllMotherlandNames();
                if (!responseMotherland.success) return console.log(responseMotherland.data);

                fields = {
                    ...fields,
                    motherlands: responseMotherland.data.map(x => ({ name: x.MotherlandName }))
                };
            }

            { // Aromas
                let responseAroma = await getAllAromasWithMainAromas();
                if (!responseAroma.success) return console.log(responseAroma.data);

                fields = {
                    ...fields,
                    aromas: responseAroma.data.map(x => ({ id: x.id, name: x.AromaName, mainName: x.MainAromaName, id: x.id, selected: false }))
                };
            }

            { // Foods
                let responseFood = await getAllDishesWithMainDishes();
                if (!responseFood.success) return console.log(responseFood.data);

                fields = {
                    ...fields,
                    foods: responseFood.data.map(x => ({ id: x.id, name: x.FoodName, mainName: x.MainDishName, selected: false }))
                };
            }

            setDropdownItems({
                ...dropdownItems,
                ...fields
            });

            let otherFields = {};

            if (formValues.category == "") {
                otherFields = {
                    ...otherFields,
                    category: fields.category[0].Category
                };
            }
            if (formValues.idealGlassType == "") {
                otherFields = {
                    ...otherFields,
                    idealGlassType: fields.glassType[0].id
                };
            }
            if (formValues.color == "") {
                otherFields = {
                    ...otherFields,
                    color: fields.color[0].id
                };
            }
            if (formValues.region.name == "") {
                otherFields = {
                    ...otherFields,
                    region: { name: fields.regions[0].name, isNew: false }
                };
            }
            if (formValues.motherland.name == "") {
                otherFields = {
                    ...otherFields,
                    motherland: { name: fields.motherlands[0].name, isNew: false }
                };
            }
            if (formValues.type == "") {
                otherFields = {
                    ...otherFields,
                    type: grapeTypes[0]
                }
            }

            setFormValues({
                ...formValues,
                ...otherFields
            });
        } catch (error) {
            return console.log(error);
        }
    }

    const handleRegion = ({
        name,
        isNew
    }) => {
        let region;
        if (isNew) region = { name, isNew: true };
        else region = { name, isNew: false };

        setFormValues({
            ...formValues,
            region
        });
    };

    const handleRegionEnable = (checked) => {
        setEnable({
            ...enable,
            region: checked
        })
    }

    const handleMotherland = ({
        name,
        isNew
    }) => {
        let motherland;
        if (isNew) motherland = { name, isNew: true };
        else motherland = { name, isNew: false };

        setFormValues({
            ...formValues,
            motherland
        });
    };

    const handleMotherlandEnable = (checked) => {
        setEnable({
            ...enable,
            motherland: checked
        })
    }

    const handleAromaChange = ({
        id,
        checked
    }) => {
        let aromas = [...dropdownItems.aromas];

        let index = aromas.findIndex(x => x.id === id);
        if (index === -1) return;

        aromas[index] = {
            ...aromas[index],
            selected: checked
        };

        setDropdownItems({
            ...dropdownItems,
            aromas
        });
    }

    const handleFoodChange = ({
        id,
        checked
    }) => {
        let foods = [...dropdownItems.foods];

        let index = foods.findIndex(x => x.id === id);
        if (index === -1) return;

        foods[index] = {
            ...foods[index],
            selected: checked
        };

        setDropdownItems({
            ...dropdownItems,
            foods
        });
    }

    const doFirstProcess = async () => {
        try {
            setPageType("LOADING");
            await fillDropdowns();

            if (id) await handleGrapeDetails();
            else {
                if (!isAdmin()) setFormValues({ ...formValues, companyId: auth.companyId });
            }

            setPageType(type);
        } catch (error) {
            return console.log(error);
        }
    };

    const changedFormValuesAroma = () => {
        if (!dropdownItems.aromas.length) return;

        let fAromas = [...dropdownItems.aromas];
        fAromas = fAromas.map(x => ({ ...x, selected: false }));

        for (let aroma of formValues.aromas) {
            let index = fAromas.findIndex(x => x.name == aroma.AromaName);
            if (index == -1) continue;

            fAromas[index].selected = true;
        }

        return fAromas;
    }

    const changedFormValuesFood = () => {
        if (!dropdownItems.foods.length) return;

        let fFoods = [...dropdownItems.foods];

        for (let food of formValues.foods) {
            let index = fFoods.findIndex(x => x.name == food.FoodName);
            if (index == -1) continue;

            fFoods[index].selected = true;
        }

        return fFoods;
    }

    const changedDropdownItemsAroma = () => {
        if (!dropdownItems.aromas.length) return;

        let uniqueMainAromas = [...new Set(dropdownItems.aromas.map(x => x.mainName))];
        let result = [];

        for (let mainAroma of uniqueMainAromas) {
            let selectedAll = dropdownItems.aromas.filter(x => x.mainName == mainAroma).every(x => x.selected);
            result.push({
                name: mainAroma,
                aromas: dropdownItems.aromas.filter(x => x.mainName == mainAroma),
                selectedAll
            });
        }

        return result;
    }

    const changedDropdownItemsFood = () => {
        if (!dropdownItems.foods.length) return;

        let uniqueMainFoods = [...new Set(dropdownItems.foods.map(x => x.mainName))];
        let result = [];

        for (let mainFood of uniqueMainFoods) {
            let selectedAll = dropdownItems.foods.filter(x => x.mainName == mainFood).every(x => x.selected);
            result.push({
                name: mainFood,
                foods: dropdownItems.foods.filter(x => x.mainName == mainFood),
                selectedAll
            });
        }

        return result;
    }

    const handleDo = () => {
        if (pageType == "INFORM") {
            setPageType("EDIT");
        } else if (pageType == "EDIT") {
            handleUpdateGrape();
        }
        else if (pageType == "ADD") {
            handleAddGrape();
        }
    }

    const handleCancel = () => {
        setPageType("INFORM");
    }

    const handleRefresh = () => {
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure to refresh?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        if (pageType == "INFORM" || pageType == "EDIT") {
                            reloadContent();
                        } else if (pageType == "ADD") {
                            setFormValues({
                                ...formValues,
                                name: "",
                                category: dropdownItems.category[0].Category,
                                color: dropdownItems.color[0].id,
                                idealOlderTime: "",
                                idealGlassType: dropdownItems.glassType[0].GlassName,
                                idealVentilateTime: 0,
                                idealServiceTemp: [0, 0],
                                asidite: 0,
                                tanen: 0,
                                bole: 0,
                                alcohol: 0,
                                region: { name: dropdownItems.regions[0].name, isNew: false },
                                motherland: { name: dropdownItems.motherlands[0].name, isNew: false },
                                aromas: [],
                                foods: [],
                                type: grapeTypes[0]
                            });

                            setDropdownItems({
                                ...dropdownItems,
                                aromas: dropdownItems.aromas.map(x => ({ ...x, selected: false })),
                                foods: dropdownItems.foods.map(x => ({ ...x, selected: false }))
                            });
                        }
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const reloadContent = async () => {
        try {
            setLoading(true);

            await doFirstProcess();

            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const alertValidationError = (element) => {
        alert("Lütfen " + element + " alanını doldurunuz.");
    }

    const controlNecessaryValues = () => {
        if (formValues.name.trim() == "") return alertValidationError("Üzüm Adı");
        if (formValues.category == "") return alertValidationError("Kategori");
        if (formValues.idealGlassType == "") return alertValidationError("İdeal Şarap Kadehi");
        if (formValues.idealServiceTemp[0] == 0 || formValues.idealServiceTemp[1] == 0) return alertValidationError("İdeal Servis Sıcaklığı");
        if (formValues.color == "") return alertValidationError("Renk");
        if (formValues.type == "") return alertValidationError("Tür");
        if (formValues.region.name.trim() == "") return alertValidationError("Bölge");
        if (formValues.motherland.name.trim() == "") return alertValidationError("Anavatan");
        return true;
    }

    const handleUpdateGrape = async () => {
        try {
            setLoading(true);

            if (controlNecessaryValues() === true) {
                let data = {
                    ...formValues,
                    aromas: dropdownItems.aromas.filter(x => x.selected).map(x => ({ name: x.name, id: x.id })),
                    foods: dropdownItems.foods.filter(x => x.selected).map(x => ({ name: x.name, id: x.id })),
                    idealOlderTime: (formValues.idealOlderTime.trim() == "") ? "-" : formValues.idealOlderTime.trim(),
                };

                let response = await updateGrape({
                    id,
                    ...data
                });

                if (!response.success) return console.log(response.data);
                history.push("/grape-table");
            }

            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const handleAddGrape = async () => {
        try {
            setLoading(true);
            if (controlNecessaryValues() === true) {

                let data = {
                    ...formValues,
                    aromas: dropdownItems.aromas.filter(x => x.selected).map(x => ({ name: x.name, id: x.id })),
                    foods: dropdownItems.foods.filter(x => x.selected).map(x => ({ name: x.name, id: x.id }))
                };

                let response = await addGrape({
                    ...data,
                    now: (new Date()).toString()
                });

                if (!response.success) return console.log(response.data);
                history.push("/grape-table");
            }

            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const getNumberValueOfBool = (value) => {
        if (value) return 1;
        else return 0;
    }

    const handleSelectAllAromas = (mainAroma, selected) => {
        let printAromas = print.aromas;

        printAromas = printAromas.map(x => {
            if (x.name == mainAroma) {
                x.selectedAll = selected;
                x.aromas = x.aromas.map(y => {
                    y.selected = selected;
                    return y;
                });
            }
            return x;
        });

        setPrint({
            ...print,
            aromas: printAromas
        });
    }

    const handleSelectAllFoods = (mainFood, selected) => {
        let printFoods = print.foods;

        printFoods = printFoods.map(x => {
            if (x.name == mainFood) {
                x.selectedAll = selected;
                x.foods = x.foods.map(y => {
                    y.selected = selected;
                    return y;
                });
            }
            return x;
        });

        setPrint({
            ...print,
            foods: printFoods
        });
    }

    useEffect(() => {
        switch (type) {
            case "EDIT":
                setTexts({
                    ...texts,
                    title: "Üzüm Düzenle",
                    button: "Kaydet"
                });
                break;
            case "ADD":
                setTexts({
                    ...texts,
                    title: "Üzüm Ekle",
                    button: "Ekle"
                });
                break;
            case "INFORM":
                setTexts({
                    ...texts,
                    title: "Üzüm Bilgileri",
                    button: "Düzenle"
                });
                break;
            default:
                break;
        }

        doFirstProcess();
    }, []);

    useEffect(() => {
        document.title = texts.title;
    }, [texts.title])

    useEffect(() => {
        let aromas = changedFormValuesAroma();
        let foods = changedFormValuesFood();

        setDropdownItems({
            ...dropdownItems,
            aromas: aromas || [],
            foods: foods || []
        });

        if (type == "ADD") {
            setPrint({
                ...print,
                enable: true
            });
        }
    }, [formValues]);

    useEffect(() => {
        let aromas = changedDropdownItemsAroma();
        let foods = changedDropdownItemsFood();

        if (!print.enable) return;

        setPrint({
            ...print,
            aromas: aromas || [],
            foods: foods || []
        })
    }, [dropdownItems]);

    useEffect(() => {
        if (!pageType) return

        switch (pageType) {
            case "INFORM":
                setTexts({
                    ...texts,
                    title: "Üzüm Bilgileri",
                    doButton: "Düzenle"
                });
                setEnable({
                    ...enable,
                    form: false,
                    cancelButton: false,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: true,
                    loading: false
                });
                break;
            case "EDIT":
                setTexts({
                    ...texts,
                    title: "Üzüm Düzenleme",
                    doButton: "Kaydet"
                });
                setEnable({
                    ...enable,
                    form: true,
                    cancelButton: true,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: true,
                    loading: false
                });
                break;
            case "ADD":
                setTexts({
                    ...texts,
                    title: "Üzüm Ekleme"
                });
                setEnable({
                    ...enable,
                    form: true,
                    cancelButton: false,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: false,
                    loading: false
                });
                break;
            case "LOADING":
                setEnable({
                    ...enable,
                    all: false
                });
                setVisibility({
                    ...visibility,
                    loading: true
                });
                break;
            default:
                break;
        }
    }, [pageType]);

    useEffect(() => {
        if (loading) {
            setEnable({
                ...enable,
                all: false
            });
            setVisibility({
                ...visibility,
                loading: true
            });
        } else {
            setEnable({
                ...enable,
                all: true
            });
            setVisibility({
                ...visibility,
                loading: false
            });
        }
    }, [loading]);

    return (
        <>
            <div className="page-content">
                <fieldset disabled={!enable.all}>
                    <Container fluid={true}>
                        <Card>
                            <CardHeader>
                                <Row className='align-items-end'>
                                    <Col sm={1}><Spinner color='danger' hidden={!visibility.loading}>loading ...</Spinner></Col>
                                    <Col sm={4}>
                                        <h2 className="mb-0">{texts.title}</h2>
                                        <p>Kaydetmek için '<span style={{ color: "red" }}>*</span>'la işaretlenmiş alanları doldurunuz.</p>
                                    </Col>
                                    <Col sm={4}></Col>
                                    <Col sm={1}><Button hidden={!visibility.cancelButton} disabled={!enable.cancelButton} onClick={handleCancel} color='warning'>İptal Et</Button></Col>
                                    <Col sm={1}><Button disabled={!enable.refreshButton} onClick={handleRefresh} color='danger'>Yenile</Button></Col>
                                    <Col sm={1}><Button disabled={!enable.doButton} onClick={handleDo} color='success'>{texts.doButton}</Button></Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="form-steps">
                                <Form>
                                    <div className="step-arrow-nav mb-4">
                                        <Nav
                                            className="nav-pills custom-nav nav-justified"
                                            role="tablist"
                                        >
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    id="steparrow-gen-info-tab"
                                                    className={classnames({
                                                        active: activeArrowTab === 4,
                                                        done: activeArrowTab <= 7 && activeArrowTab > 3,
                                                    })}
                                                    onClick={() => {
                                                        toggleArrowTab(4);
                                                    }}
                                                >
                                                    Karakter ve Servis
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    id="steparrow-gen-info-tab"
                                                    className={classnames({
                                                        active: activeArrowTab === 5,
                                                        done: activeArrowTab <= 7 && activeArrowTab > 4,
                                                    })}
                                                    onClick={() => {
                                                        toggleArrowTab(5);
                                                    }}
                                                >
                                                    Aromalar
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    id="steparrow-gen-info-tab"
                                                    className={classnames({
                                                        active: activeArrowTab === 6,
                                                        done: activeArrowTab <= 7 && activeArrowTab > 5,
                                                    })}
                                                    onClick={() => {
                                                        toggleArrowTab(6);
                                                    }}
                                                >
                                                    Yemekler
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                    <fieldset disabled={!enable.form}>
                                        <TabContent activeTab={activeArrowTab}>
                                            <TabPane id="common" tabId={4}>
                                                <Form >
                                                    <Row>
                                                        {/* 1. SATIR*/}


                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="firstNameinput" className="form-label">ÜZÜM ADI<span style={{ color: "red" }}>*</span></Label>
                                                                <Input value={formValues.name} onChange={(e) => setFormValues({ ...formValues, name: e.target.value })} type="text" className="form-control" id="firstNameinput" />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="ForminputState" className="form-label">KATEGORİSİ<span style={{ color: "red" }}>*</span></Label>
                                                                <select value={formValues.category} onChange={(e) => setFormValues({ ...formValues, category: e.target.value })} id="ForminputState" className="form-select" data-choices data-choices-sorting="true" >
                                                                    {
                                                                        dropdownItems.category.map((item, index) => (
                                                                            <option key={index}>{item.Category}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="ForminputState" className="form-label">RENK<span style={{ color: "red" }}>*</span></Label>
                                                                <select value={formValues.color} onChange={(e) => setFormValues({ ...formValues, color: e.target.value })} id="ForminputState" className="form-select" data-choices data-choices-sorting="true" >
                                                                    {
                                                                        dropdownItems.color.map((item, index) => (
                                                                            <option key={index} value={item.id}>{item.ColorName}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </Col>


                                                        {/* 2. SATIR*/}
                                                        <hr />


                                                        <Col md={2}>
                                                            <Label htmlFor="compnayNameinput" className="form-label">İDEAL YILLANMA SÜRESİ (Min-Max)</Label>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Label htmlFor="ForminputState" className="form-label">İDEAL KADEH TİPİ<span style={{ color: "red" }}>*</span></Label>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Label htmlFor="emailidInput" className="form-label">İDEAL KARAF/HAVALANDIRMA SÜRESİ</Label>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Label htmlFor="compnayNameinput" className="form-label">İDEAL SERVİS SICAKLIĞI<span style={{ color: "red" }}>*</span> - MIN</Label>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Label htmlFor="phonenumberInput" className="form-label">MAKS</Label>
                                                        </Col>
                                                        <Col md={2} />

                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Input value={formValues.idealOlderTime} onChange={(e) => setFormValues({ ...formValues, idealOlderTime: e.target.value })} type="text" className="form-control" id="compnayNameinput" />
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <select value={formValues.idealGlassType} onChange={(e) => setFormValues({ ...formValues, idealGlassType: e.target.value })} id="ForminputState" className="form-select" data-choices data-choices-sorting="true" >
                                                                    {
                                                                        dropdownItems.glassType.map((item, index) => (
                                                                            <option key={index} value={item.id}>{item.GlassName}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Input value={formValues.idealVentilateTime} onChange={(e) => setFormValues({ ...formValues, idealVentilateTime: e.target.value })} type="number" className="form-control" placeholder="Dakika" id="emailidInput" />
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Input value={formValues.idealServiceTemp[0]} onChange={(e) => setFormValues({ ...formValues, idealServiceTemp: [e.target.value, formValues.idealServiceTemp[1]] })} type="number" className="form-control" placeholder="Min °C" id="compnayNameinput" />
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Input value={formValues.idealServiceTemp[1]} onChange={(e) => setFormValues({ ...formValues, idealServiceTemp: [formValues.idealServiceTemp[0], e.target.value] })} type="number" className="form-control" placeholder="Max °C" id="phonenumberInput" />
                                                            </div>
                                                        </Col>


                                                        {/* 3. SATIR*/}
                                                        <hr />


                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="ForminputState" className="form-label">ASİDİTE<span style={{ color: "red" }}>*</span></Label>
                                                                <select defaultValue={0} value={formValues.asidite} onChange={(e) => setFormValues({ ...formValues, asidite: e.target.value })} id="ForminputState" className="form-select" data-choices data-choices-sorting="true" >
                                                                    <option value={0}>Yok</option>
                                                                    <option value={20}>Az</option>
                                                                    <option value={40}>Orta -</option>
                                                                    <option value={60}>Orta</option>
                                                                    <option value={80}>Orta +</option>
                                                                    <option value={100}>Yüksek</option>
                                                                </select>
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="ForminputState" className="form-label">TANEN<span style={{ color: "red" }}>*</span></Label>
                                                                <select defaultValue={0} value={formValues.tanen} onChange={(e) => setFormValues({ ...formValues, tanen: e.target.value })} id="ForminputState" className="form-select" data-choices data-choices-sorting="true" >
                                                                    <option value={0}>Yok</option>
                                                                    <option value={20}>Az</option>
                                                                    <option value={40}>Orta -</option>
                                                                    <option value={60}>Orta</option>
                                                                    <option value={80}>Orta +</option>
                                                                    <option value={100}>Yüksek</option>
                                                                </select>
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="ForminputState" className="form-label">GÖVDE<span style={{ color: "red" }}>*</span></Label>
                                                                <select defaultValue={0} value={formValues.bole} onChange={(e) => setFormValues({ ...formValues, bole: e.target.value })} id="ForminputState" className="form-select" data-choices data-choices-sorting="true" >
                                                                    <option value={0}>Yok</option>
                                                                    <option value={20}>Az</option>
                                                                    <option value={40}>Orta -</option>
                                                                    <option value={60}>Orta</option>
                                                                    <option value={80}>Orta +</option>
                                                                    <option value={100}>Yüksek</option>
                                                                </select>
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Label htmlFor="ForminputState" className="form-label">TÜR<span style={{ color: "red" }}>*</span></Label>
                                                            <div className="mb-3">
                                                                <select value={formValues.type} onChange={(e) => setFormValues({ ...formValues, type: e.target.value })} id="ForminputState" className="form-select" data-choices data-choices-sorting="true" >
                                                                    {
                                                                        grapeTypes.map((item, index) => (
                                                                            <option key={index}>{item}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    {/* 4. SATIR*/}
                                                    <hr />

                                                    <Row>
                                                        <Col xl={6}>
                                                            <Card>
                                                                <CardHeader>
                                                                    <h4 className="card-title mb-0">Kayıtlı Olan Bölgeler</h4>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col lg={12}>
                                                                            <Card>
                                                                                <CardBody >
                                                                                    <Row className="row-cols-lg-auto g-3 align-items-center">
                                                                                        <Col xs={12}>
                                                                                            <select value={(!enable.region) ? formValues.region.name : null} onChange={(e) => handleRegion({ name: e.target.value })} className="form-select" data-choices data-choices-sorting="true" id="inlineFormSelectPref" disabled={enable.region}>
                                                                                                {
                                                                                                    dropdownItems.regions.map((item, index) => (
                                                                                                        <option key={index}>{item.name}</option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                        </Col>
                                                                                        <Col xs={12}>
                                                                                            <div className="form-check form-check-warning">
                                                                                                <Input className="form-check-input" type="checkbox" id="inlineFormCheck" onChange={(e) => handleRegionEnable(e.target.checked)} />
                                                                                                <Label className="form-check-label" htmlFor="inlineFormCheck">
                                                                                                    Yapıldığı bölge seçenekler içinde yok.
                                                                                                </Label>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col xl={6}>
                                                            <Card>
                                                                <CardHeader>
                                                                    <h4 className="card-title mb-0">Bölgeyi kendim belirlemek istiyorum</h4>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col lg={12}>
                                                                            <Col xs={12}>
                                                                                <Input value={(enable.region) ? formValues.region.name : null} onChange={(e) => { handleRegion({ name: e.target.value, isNew: true }) }} type="text" className="form-control" placeholder="Bölge adı" disabled={!enable.region} />
                                                                            </Col>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl={6}>
                                                            <Card>
                                                                <CardHeader>
                                                                    <h4 className="card-title mb-0">Kayıtlı Olan Anavatanlar</h4>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col lg={12}>
                                                                            <Card>
                                                                                <CardBody >
                                                                                    <Row className="row-cols-lg-auto g-3 align-items-center">
                                                                                        <Col xs={12}>
                                                                                            <select value={(!enable.motherland) ? formValues.motherland.name : null} onChange={(e) => handleMotherland({ name: e.target.value })} className="form-select" data-choices data-choices-sorting="true" id="inlineFormSelectPref" disabled={enable.motherland}>
                                                                                                {
                                                                                                    dropdownItems.motherlands.map((item, index) => (
                                                                                                        <option key={index}>{item.name}</option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                        </Col>
                                                                                        <Col xs={12}>
                                                                                            <div className="form-check form-check-warning">
                                                                                                <Input className="form-check-input" type="checkbox" id="inlineFormChecka" onChange={(e) => handleMotherlandEnable(e.target.checked)} />
                                                                                                <Label className="form-check-label" htmlFor="inlineFormChecka">
                                                                                                    Anavatanı seçenekler içinde yok.
                                                                                                </Label>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col xl={6}>
                                                            <Card>
                                                                <CardHeader>
                                                                    <h4 className="card-title mb-0">Anavatanı kendim belirlemek istiyorum</h4>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col lg={12}>
                                                                            <Col xs={12}>
                                                                                <Input value={(enable.motherland) ? formValues.motherland.name : null} onChange={(e) => { handleMotherland({ name: e.target.value, isNew: true }) }} type="text" className="form-control" placeholder="Anavatan adı" disabled={!enable.motherland} />
                                                                            </Col>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </TabPane>
                                            <TabPane id="aromas" tabId={5}>
                                                <Row>
                                                    {
                                                        print.aromas.map((item, index) => (
                                                            <Col key={index} xl={3}>
                                                                <Card>
                                                                    <CardHeader>
                                                                        <h4 className="card-title mb-0 text-danger">
                                                                            <div className="d-flex">
                                                                                <div className="flex-grow-1">
                                                                                    <div className="form-check form-check-danger mb-3">
                                                                                        <Input className="form-check-input" type="checkbox" checked={Boolean(getNumberValueOfBool(item.selectedAll))} onChange={(e) => handleSelectAllAromas(item.name, e.target.checked)} />
                                                                                        <Label className="form-check-label">
                                                                                            {item.name}
                                                                                        </Label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </h4>
                                                                    </CardHeader>
                                                                    <CardBody>
                                                                        <div>
                                                                            <SimpleBar style={{ height: "242px" }} className="mx-n3">
                                                                                <ListGroup className="list mb-0" flush>
                                                                                    {
                                                                                        item.aromas.map((subItem, subIndex) => (
                                                                                            <ListGroupItem key={subIndex}>
                                                                                                <div className="d-flex">
                                                                                                    <div className="flex-grow-1">
                                                                                                        <div className="form-check form-check-danger mb-3">
                                                                                                            <Input className="form-check-input" type="checkbox" checked={Boolean(getNumberValueOfBool(subItem.selected))} onChange={(e) => handleAromaChange({ id: subItem.id, checked: e.target.checked })} />
                                                                                                            <Label className="form-check-label">
                                                                                                                {subItem.name}
                                                                                                            </Label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </ListGroupItem>
                                                                                        ))
                                                                                    }
                                                                                </ListGroup >
                                                                            </SimpleBar>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        ))
                                                    }
                                                </Row>
                                            </TabPane>
                                            <TabPane id="foods" tabId={6}>
                                                <Row>
                                                    {
                                                        print.foods.map((item, index) => (
                                                            <Col key={index} xl={3}>
                                                                <Card>
                                                                    <CardHeader>
                                                                        <h4 className="card-title mb-0 text-danger">
                                                                            <div className="d-flex">
                                                                                <div className="flex-grow-1">
                                                                                    <div className="form-check form-check-danger mb-3">
                                                                                        <Input className="form-check-input" type="checkbox" checked={Boolean(getNumberValueOfBool(item.selectedAll))} onChange={(e) => handleSelectAllFoods(item.name, e.target.checked)} />
                                                                                        <Label className="form-check-label">
                                                                                            {item.name}
                                                                                        </Label>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </h4>
                                                                    </CardHeader>
                                                                    <CardBody>
                                                                        <div>
                                                                            <SimpleBar style={{ height: "242px" }} className="mx-n3">
                                                                                <ListGroup className="list mb-0" flush>
                                                                                    {
                                                                                        item.foods.map((subItem, subIndex) => (
                                                                                            <ListGroupItem key={subIndex}>
                                                                                                <div className="d-flex">
                                                                                                    <div className="flex-grow-1">
                                                                                                        <div className="form-check form-check-danger mb-3">
                                                                                                            <Input className="form-check-input" type="checkbox" checked={Boolean(getNumberValueOfBool(subItem.selected))} onChange={(e) => handleFoodChange({ id: subItem.id, checked: e.target.checked })} />
                                                                                                            <Label className="form-check-label">
                                                                                                                {subItem.name}
                                                                                                            </Label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </ListGroupItem>
                                                                                        ))
                                                                                    }
                                                                                </ListGroup >
                                                                            </SimpleBar>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        ))
                                                    }
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </fieldset>
                                </Form>
                            </CardBody>
                        </Card>

                    </Container>
                </fieldset>
            </div>
        </>
    );
}

export default GrapeDetails;