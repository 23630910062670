import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Label, Row } from "reactstrap";
import { Grid, _ } from 'gridjs-react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { changeWineActivity, deleteWine, getAllWines, getWinesByCompany } from "../../helpers/api/product/product";
import useAuth from '../../helpers/auth/useAuth';
import { useHistory } from 'react-router-dom';
import { getArtisans } from '../../helpers/api/producer/producer';

const WineTable = () => {
    const [wines, setWines] = useState([]);
    const [producers, setProducers] = useState([]);
    const [currentProducer, setCurrentProducer] = useState(-1);
    const [enable, setEnable] = useState(false);

    const history = useHistory();
    const { auth } = useAuth();

    document.title = "My Wines";

    const getWines = async (producerId) => {
        try {
            setWines([]);

            if (auth.roles.includes("ADMIN")) {
                let response;

                if (!producerId || producerId == -1) response = await getAllWines();
                else {
                    response = await getWinesByCompany({
                        companyId: producerId
                    });
                }

                if (!response.success) throw response.data;

                setWines(response.data.map(row => ([
                    row.id,
                    row.Name,
                    row.WineYear,
                    row.Category,
                    row.ProducerName,
                    (row.IsDeleted == 0) ? "Yes" : "No"
                ])));
            } else if (auth.roles.includes("PRODUCER")) {
                let response = await getWinesByCompany({
                    companyId: auth.companyId
                });
                if (!response.success) throw response.data;

                setWines(response.data.map(row => ([
                    row.id,
                    row.Name,
                    row.WineYear,
                    row.Category,
                    row.ProducerName,
                    (row.IsDeleted == 0) ? "Yes" : "No"
                ])));
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getProducers = async () => {
        try {
            setProducers([]);

            let response = await getArtisans();
            if (!response.success) throw response.data;

            setProducers(response.data);

            if (auth.roles.includes("ADMIN")) {
                setEnable(true);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleClickGridItem = (e) => {
        e.preventDefault();
        let row = e.target.parentElement.parentElement.parentElement;
        let id = row.children[0].innerText;

        history.push("/wine-details", { params: { id, type: "INFORM" } });
    }

    const handleAddWine = () => {
        history.push("/wine-details", { params: { type: "ADD" } });
    }

    const handleClickDuplicate = (e) => {
        e.preventDefault();
        let row = e.target.parentElement.parentElement.parentElement;
        let id = row.children[0].innerText;
        history.push("/wine-details", { params: { type: "ADD", id } });
    }

    const handleClickDelete = async (e) => {
        try {
            e.preventDefault();

            confirmAlert({
                title: 'Confirm to Delete',
                message: 'Are you sure to delete this wine?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let row = e.target.parentElement.parentElement.parentElement;
                            let id = row.children[0].innerText;

                            let response = await deleteWine({ id });
                            if (!response.success) return console.log(response.data);

                            await getWines();
                        }
                    },
                    {
                        label: 'No'
                    }
                ]
            });
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeActivity = async (e) => {
        try {
            e.preventDefault();
            let row = e.target.parentElement.parentElement.parentElement;
            let id = row.children[0].innerText;

            let item = wines.find(w => w[0] == id)[5];
            let activity;

            if (item == "Yes") activity = 1;
            else activity = 0;

            let response = await changeWineActivity({ id, activity });
            if (!response.success) return console.log(response.data);

            await getWines();
        } catch (error) {
            console.log(error);
        }
    };

    const getDatas = async () => {
        try {
            await getProducers();

            await getWines();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getDatas();
    }, []);

    useEffect(() => {
        getWines(currentProducer);
    }, [currentProducer]);

    return (
        <>
            <div className="page-content">
                <Row>
                    <Col md={1} />
                    <Col md={2}>
                        <div className="mb-3">
                            <select value={currentProducer} onChange={(e) => setCurrentProducer(e.target.value)} id="ForminputState" className="form-select" data-choices data-choices-sorting="true" disabled={!enable}>
                                <option value="-1">All</option>
                                {
                                    producers.map((item, index) => (
                                        <option key={index} value={item.id}>{item.ProducerName}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </Col>
                    <Col md={7} />
                    <Col md={2}>
                        <Button onClick={handleAddWine} color="danger">Şarap Ekle</Button>
                    </Col>
                </Row>
                <Container fluid={true}>
                    <React.Fragment>
                        <Grid
                            data={wines}
                            columns={[
                                "#", "Name", "Year", "Category", "Producer", "Active",
                                {
                                    name: 'Actions',
                                    width: '250px',
                                    formatter: (cell) => _(
                                        <>
                                            <Button type='button' onClick={handleClickGridItem} className='btn btn-sm btn-light'> Details </Button>
                                            <Button type='button' onClick={handleClickDelete} className='btn btn-sm btn-danger'> Delete </Button>
                                            <Button type='button' onClick={handleClickDuplicate} className='btn btn-sm btn-info'> Duplicate </Button>
                                            <Button type='button' onClick={handleChangeActivity} className='btn btn-sm btn-warning'> Change Activity </Button>
                                        </>
                                    )
                                }
                            ]}
                            search={true}
                            sort={true}
                            pagination={{ enabled: true, limit: 25 }}
                        />
                    </React.Fragment>
                </Container>
            </div>
        </>
    );
}

export default WineTable;