import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import useAuth from '../../helpers/auth/useAuth';

const ProfileDropdown = () => {

    const { user } = useSelector(state => ({
        user: state.Profile.user,
    }));

    const [userName, setUserName] = useState("Admin");

    useEffect(() => {
        if (sessionStorage.getItem("authUser")) {
            const obj = JSON.parse(sessionStorage.getItem("authUser"));
            setUserName(user.first_name || obj.data.first_name || "Admin");
        }
    }, [userName, user]);

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    const history = useHistory();
    const { setAuth, auth } = useAuth();

    const handleLogout = () => {
        setAuth({});
        history.push("/login");
    }

    const goProducerProfile = () => {
        history.push("/producer-details", { params: { id: auth.companyId, type: "INFORM", mode: "PROFILE" } });
    }

    const haveProducerRole = () => {
        return (auth.roles.includes("PRODUCER") && auth.companyId);
    }

    const goAdminProfile = () => {
        history.push("/admin-details", { params: { id: auth.id, type: "INFORM", mode: "PROFILE" } });
    }

    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn shadow-none">
                    <span className="d-flex align-items-center">
                        <span className="text-start">
                            Profile
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem onClick={goAdminProfile}>
                        <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                        <span className="align-middle">Admin Profile</span>
                    </DropdownItem>
                    <DropdownItem onClick={goProducerProfile} hidden={!haveProducerRole()}>
                        <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                        <span className="align-middle">Producer Profile</span>
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                    <DropdownItem onClick={handleLogout}><i
                        className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle" data-key="t-logout">Logout</span></DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;