import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from "reactstrap";
import { Grid, _ } from 'gridjs-react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { deleteColorType, getAllColorTypes } from "../../helpers/api/product/product";
import { useHistory } from 'react-router-dom';

const ColorTable = () => {
    const [colors, setColors] = useState([]);

    const history = useHistory();

    document.title = "Colors";

    const getColors = async () => {
        try {
            setColors([]);
            let response = await getAllColorTypes();
            if (!response.success) throw response.data;

            console.log(response.data);

            setColors(response.data.map((color) => ([
                color.id,
                color.ColorName,
                color.ColorName_ing,
                color.ColorPicture
            ])));

        } catch (error) {
            console.log(error);
        }
    }

    const handleClickGridItem = (e) => {
        e.preventDefault();
        let row = e.target.parentElement.parentElement.parentElement;
        let id = row.children[0].innerText;

        history.push("/color-details", { params: { id, type: "INFORM" } });
    }

    const handleAddColor = () => {
        history.push("/color-details", { params: { type: "ADD" } });
    }

    const handleClickDelete = async (e) => {
        try {
            e.preventDefault();

            confirmAlert({
                title: 'Confirm to Delete',
                message: 'Are you sure to delete this color?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let row = e.target.parentElement.parentElement.parentElement;
                            let id = row.children[0].innerText;

                            let response = await deleteColorType({ id });
                            if (!response.success) return console.log(response.data);

                            await getColors();
                        }
                    },
                    {
                        label: 'No'
                    }
                ]
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getColors();
    }, []);

    return (
        <>
            <div className="page-content">
                <Row>
                    <Col xs="10" />
                    <Col xs="2">
                        <Button onClick={handleAddColor} color="danger">Renk Ekle</Button>
                    </Col>
                </Row>
                <Container fluid={true}>
                    <React.Fragment>
                        <Grid
                            data={colors}
                            columns={[
                                "#", "Name", "Name English",
                                {
                                    name: 'Renk',
                                    width: '50px',
                                    formatter: (cell) => _(
                                        <>
                                            {cell && (typeof cell == "string") && cell.trim().length == 7 &&
                                                <div style={{ width: "30px", height: "30px", backgroundColor: `${cell}`, borderRadius: "15px", margin: "auto" }}></div>
                                            }
                                        </>
                                    )
                                },
                                {
                                    name: 'Actions',
                                    width: '120px',
                                    formatter: (cell) => _(
                                        <>
                                            <Button type='button' onClick={handleClickGridItem} className='btn btn-sm btn-light'> Details </Button>
                                            <Button type='button' onClick={handleClickDelete} className='btn btn-sm btn-danger'> Delete </Button>
                                        </>
                                    )
                                }
                            ]}
                            search={true}
                            sort={true}
                            pagination={{ enabled: true, limit: 25 }}
                        />
                    </React.Fragment>
                </Container>
            </div>
        </>
    );
}

export default ColorTable;