import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    ListGroup,
    ListGroupItem,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Button,
    Spinner
} from "reactstrap";
import classnames from "classnames";
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import Slider from 'react-rangeslider';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { getNotificationDetails, getNotificationTypes, getReceiverTypes, notificateDeviceFromPanel } from '../../helpers/api/notification/notification';
import useAuth from '../../helpers/auth/useAuth';
import { getNotificitableUsers } from '../../helpers/api/user/user';
// register lottie and define custom element
defineLordIconElement(loadAnimation);

const NotificationDetails = () => {
    const [enable, setEnable] = useState({
        all: false,
        doButton: true,
        cancelButton: false,
        refreshButton: true,
        form: true
    });
    const [formValues, setFormValues] = useState({
        title: "",
        body: "",
        notificationType: "",
        receiverType: "",
        users: []
    });
    const [dropdownItems, setDropdownItems] = useState({
        notificationType: [],
        receiverType: [],
        users: [],
    });
    const [print, setPrint] = useState({
        enable: false
    });
    const [texts, setTexts] = useState({
        title: "",
        doButton: "Ekle"
    });
    const [pageType, setPageType] = useState("");
    const [loading, setLoading] = useState(false);
    const [visibility, setVisibility] = useState({
        loading: true,
        cancelButton: true
    });
    const [cache, setCache] = useState({
        users: []
    });
    const [show, setShow] = useState({
        users: []
    });

    const history = useHistory();
    const location = useLocation();
    const { id, type } = location.state.params;
    const { auth } = useAuth();

    const handleNotificationDetails = async () => {
        try {
            let result = await getNotificationDetails({ id });
            if (!result.success) return console.log("Bir hata oluştu");

            let notification = result.data.data;
            let users = result.data.users;

            setFormValues({
                ...formValues,
                title: notification.Title || "",
                body: notification.Body || "",
                notificationType: notification.NotificationType || "",
                receiverType: notification.ReceiverType || "",
                users: users || []
            });

            if (type != "ADD") {
                setPrint({
                    ...print,
                    enable: true
                });
            }
        } catch (error) {
            return console.log(error);
        }
    }

    const fillDropdowns = async () => {
        try {
            let reponseNotifications = await getNotificationTypes();
            if (!reponseNotifications.success) return console.log(reponseNotifications.data);

            let responseReceiverTypes = await getReceiverTypes();
            if (!responseReceiverTypes.success) return console.log(responseReceiverTypes.data);

            let responseUsers = await getNotificitableUsers();
            if (!responseUsers.success) return console.log(responseUsers.data);

            setDropdownItems({
                ...dropdownItems,
                notificationType: reponseNotifications.data.map(notification => ({ id: notification.id, name: notification.NotificationType })),
                users: responseUsers.data.map(user => ({ id: user.id, name: user.UserEmail })),
                receiverType: responseReceiverTypes.data.map(receiver => ({ id: receiver.id, name: receiver.Name }))
            });

            let data = {};

            if (formValues.notificationType == "") {
                data = {
                    ...data,
                    notificationType: reponseNotifications.data[0].id || ""
                };
            }

            if (formValues.receiverType == "") {
                data = {
                    ...data,
                    receiverType: responseReceiverTypes.data[0].id || ""
                };
            }

            setFormValues({
                ...formValues,
                ...data
            });
        } catch (error) {
            return console.log(error);
        }
    }

    const doFirstProcess = async () => {
        try {
            setPageType("LOADING");
            await fillDropdowns();

            if (id) await handleNotificationDetails();
            setCache({
                ...cache,
                users: formValues.users
            });

            setPageType(type);
        } catch (error) {
            return console.log(error);
        }
    };

    const handleDo = () => {
        if (pageType == "ADD") {
            handleAddNotification();
        }
    }

    const handleRefresh = () => {
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure to refresh?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        if (pageType == "INFORM" || pageType == "EDIT") {
                            reloadContent();
                        } else if (pageType == "ADD") {
                            setFormValues({
                                ...formValues,
                                name: "",
                                nameEng: "",
                                Notification: "",
                                image: null
                            });
                        }
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const reloadContent = async () => {
        try {
            setLoading(true);

            await doFirstProcess();

            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const alertValidationError = (element) => {
        alert("Lütfen " + element + " alanını doldurunuz.");
    }

    const controlNecessaryValues = () => {
        if (formValues.title.trim() == "") return alertValidationError("Title");
        if (formValues.body.trim() == "") return alertValidationError("Body");
        if (formValues.notificationType == "") return alertValidationError("Notification Type");
        return true;
    }

    const handleAddNotification = async () => {
        try {
            setLoading(true);
            if (controlNecessaryValues() === true) {
                let data = {
                    ...formValues
                };

                let response = await notificateDeviceFromPanel({
                    ...data,
                    adminId: auth.id,
                    now: new Date()
                });

                if (!response.success) return console.log(response.data);
                history.push("/notification-table");
            }
            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const handleUserChange = ({
        id,
        checked
    }) => {
        let users = show.users.filter(user => user.selected).map(user => user.id);

        if (checked) {
            users.push(id);
        } else {
            users = users.filter(user => user != id);
        }

        setFormValues({
            ...formValues,
            users
        });

        setCache({
            ...cache,
            users
        });
    }

    const getNumberValueOfBool = (value) => {
        if (value) return 1;
        else return 0;
    }

    const isUserMenuOpen = (receiverType) => {
        if (receiverType == 4) return true;
        else return false;
    }

    useEffect(() => {
        switch (type) {
            case "ADD":
                setTexts({
                    ...texts,
                    title: "Notification Ekle",
                    button: "Ekle"
                });
                break;
            case "INFORM":
                setTexts({
                    ...texts,
                    title: "Notification Bilgileri",
                    button: "Düzenle"
                });
                break;
            default:
                break;
        }

        doFirstProcess();
    }, []);

    useEffect(() => {
        document.title = texts.title;
    }, [texts.title]);

    useEffect(() => {
        if (!pageType) return

        switch (pageType) {
            case "INFORM":
                setTexts({
                    ...texts,
                    title: "Notification Bilgileri",
                    doButton: "Düzenle"
                });
                setEnable({
                    ...enable,
                    form: false,
                    cancelButton: false,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: true,
                    loading: false
                });
                break;
            case "ADD":
                setTexts({
                    ...texts,
                    title: "Notification Ekleme"
                });
                setEnable({
                    ...enable,
                    form: true,
                    cancelButton: false,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: false,
                    loading: false
                });
                break;
            case "LOADING":
                setEnable({
                    ...enable,
                    all: false
                });
                setVisibility({
                    ...visibility,
                    loading: true
                });
                break;
            default:
                break;
        }
    }, [pageType]);

    useEffect(() => {
        if (loading) {
            setEnable({
                ...enable,
                all: false
            });
            setVisibility({
                ...visibility,
                loading: true
            });
        } else {
            setEnable({
                ...enable,
                all: true
            });
            setVisibility({
                ...visibility,
                loading: false
            });
        }
    }, [loading]);

    useEffect(() => {
        let userList = dropdownItems.users;

        if (!cache.users.length) {
            userList = userList.map((user) => ({
                ...user,
                selected: false
            }));
        } else {
            userList = userList.map((user) => ({
                ...user,
                selected: (cache.users.find(u => u == user.id)) ? true : false
            }));
        }

        setShow({
            ...show,
            users: userList
        });
    }, [cache.users]);

    return (
        <>
            <div className="page-content">
                <fieldset disabled={!enable.all}>
                    <Container fluid={true}>
                        <Card>
                            <CardHeader>
                                <Row className='align-items-end'>
                                    <Col sm={1}><Spinner color='danger' hidden={!visibility.loading}>loading ...</Spinner></Col>
                                    <Col sm={4}>
                                        <h2 className="mb-0">{texts.title}</h2>
                                        <p>Kaydetmek için '<span style={{ color: "red" }}>*</span>'la işaretlenmiş alanları doldurunuz.</p>
                                    </Col>
                                    <Col sm={5}></Col>
                                    <Col sm={1}><Button disabled={!enable.refreshButton} onClick={handleRefresh} color='danger'>Yenile</Button></Col>
                                    <Col sm={1}><Button disabled={!enable.doButton} onClick={handleDo} color='success'>{texts.doButton}</Button></Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="form-steps">
                                <Form>
                                    <fieldset disabled={!enable.form}>
                                        <Row>
                                            <Col md={8}>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstNameinpu" className="form-label">Title<span style={{ color: "red" }}>*</span></Label>
                                                    <Input value={formValues.title} onChange={(e) => setFormValues({ ...formValues, title: e.target.value })} type="text" className="form-control" id="firstNameinpu" />
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="ForminputState" className="form-label">Notification Type<span style={{ color: "red" }}>*</span></Label>
                                                    <select value={formValues.notificationType} onChange={(e) => setFormValues({ ...formValues, notificationType: e.target.value })} id="ForminputState" className="form-select" data-choices data-choices-sorting="true" >
                                                        {
                                                            dropdownItems.notificationType.map((item, index) => (
                                                                <option key={index} value={item.id}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </Col>
                                            <hr />
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstNameinpu" className="form-label">Body<span style={{ color: "red" }}>*</span></Label>
                                                    <Input value={formValues.body} onChange={(e) => setFormValues({ ...formValues, body: e.target.value })} className="form-control" id="firstNameinpu" type="textarea" rows="3W" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col md={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="ForminputState" className="form-label">Receiver Type<span style={{ color: "red" }}>*</span></Label>
                                                    <select value={formValues.receiverType} onChange={(e) => setFormValues({ ...formValues, receiverType: e.target.value })} id="ForminputState" className="form-select" data-choices data-choices-sorting="true" >
                                                        {
                                                            dropdownItems.receiverType.map((item, index) => (
                                                                <option key={index} value={item.id}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col md={8}>
                                                <Card>
                                                    <CardHeader>
                                                        <h4 className="card-title mb-0">
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1">
                                                                    <Label>
                                                                        Notificatiable Users
                                                                    </Label>
                                                                </div>
                                                            </div>
                                                        </h4>
                                                    </CardHeader>
                                                    <fieldset disabled={!isUserMenuOpen(formValues.receiverType)}>
                                                        <CardBody>
                                                            <div>
                                                                <SimpleBar style={{ height: "242px" }} className="mx-n3">
                                                                    <ListGroup className="list mb-0" flush>
                                                                        {
                                                                            show.users.map((item, index) => (
                                                                                <ListGroupItem key={index}>
                                                                                    <div className="d-flex">
                                                                                        <div className="flex-grow-1">
                                                                                            <div className="form-check form-check-danger mb-3">
                                                                                                <Input className="form-check-input" type="checkbox" checked={Boolean(getNumberValueOfBool(item.selected))} onChange={(e) => handleUserChange({ id: item.id, checked: e.target.checked })} />
                                                                                                <Label className="form-check-label">
                                                                                                    {item.name}
                                                                                                </Label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </ListGroupItem>
                                                                            ))
                                                                        }
                                                                    </ListGroup >
                                                                </SimpleBar>
                                                            </div>
                                                        </CardBody>
                                                    </fieldset>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </fieldset>
                                </Form>
                            </CardBody>
                        </Card>

                    </Container>
                </fieldset>
            </div>
        </>
    );
}

export default NotificationDetails;