import { md5Control, tokenControl } from "../common/middlewareHeaders";
import fetchToAPI from "../common/fetchToAPI";
import CustomError from "../../error/CustomError";
import { getNotificitableUsersURL } from "../common/endpointURLs";

const getNotificitableUsers = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getNotificitableUsersURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

export {
    getNotificitableUsers
};