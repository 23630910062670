import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    ListGroup,
    ListGroupItem,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Button,
    Spinner
} from "reactstrap";
import classnames from "classnames";
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import Slider from 'react-rangeslider';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import { getVersionInfo, getIsAppReady, editAppSettings } from '../../helpers/api/auth/auth';
// register lottie and define custom element
defineLordIconElement(loadAnimation);

const AppKeyTable = () => {
    const [enable, setEnable] = useState({
        all: false,
        doButton: true,
        cancelButton: false,
        refreshButton: true,
        form: true
    });
    const [formValues, setFormValues] = useState({
        android: "",
        ios: "",
        isAppReady: 1
    });
    const [print, setPrint] = useState({
        enable: false
    });
    const [texts, setTexts] = useState({
        doButton: "Düzenle"
    });
    const [pageType, setPageType] = useState("INFORM");
    const [loading, setLoading] = useState(false);
    const [visibility, setVisibility] = useState({
        loading: true,
        cancelButton: true
    });

    const history = useHistory();
    const type = "INFORM";

    const handleDetails = async () => {
        try {
            let result = await getVersionInfo();
            if (!result.success) return console.log("Bir hata oluştu");

            let data = result.data;

            result = await getIsAppReady();
            if (!result.success) return console.log("Bir hata oluştu");

            data.isAppReady = (result.data.isReady == null) ? 1 : result.data.isReady;

            setFormValues({
                ...formValues,
                android: data.android || "",
                ios: data.ios || "",
                isAppReady: data.isAppReady
            });

            if (type != "ADD") {
                setPrint({
                    ...print,
                    enable: true
                });
            }
        } catch (error) {
            return console.log(error);
        }
    }

    const doFirstProcess = async () => {
        try {
            setPageType("LOADING");

            await handleDetails();

            setPageType(type);
        } catch (error) {
            return console.log(error);
        }
    };

    const handleDo = () => {
        if (pageType == "INFORM") {
            setPageType("EDIT");
        } else if (pageType == "EDIT") {
            handleUpdate();
        }
    }

    const handleCancel = () => {
        setPageType("INFORM");
    }

    const handleRefresh = () => {
        if (pageType == "INFORM" || pageType == "EDIT") {
            reloadContent();
        }
    }

    const reloadContent = async () => {
        try {
            setLoading(true);

            await doFirstProcess();

            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const alertValidationError = (element) => {
        alert("Lütfen " + element + " alanını doldurunuz.");
    }

    const controlNecessaryValues = () => {
        if (formValues.android.trim() == "") return alertValidationError("Android Version");
        if (formValues.ios.trim() == "") return alertValidationError("IOS Version");
        return true;
    }

    const handleUpdate = async () => {
        try {
            setLoading(true);
            if (controlNecessaryValues() === true) {

                let response = await editAppSettings({
                    ...formValues
                });

                if (!response.success) return console.log(response.data);
                history.push("/wine-table");
            }
            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    useEffect(() => {
        switch (type) {
            case "EDIT":
                setTexts({
                    ...texts,
                    button: "Kaydet"
                });
                break;
            case "INFORM":
                setTexts({
                    ...texts,
                    button: "Düzenle"
                });
                break;
            default:
                break;
        }

        document.title = "Price Table";

        doFirstProcess();
    }, []);

    useEffect(() => {
        if (!pageType) return

        switch (pageType) {
            case "INFORM":
                setTexts({
                    ...texts,
                    doButton: "Düzenle"
                });
                setEnable({
                    ...enable,
                    form: false,
                    cancelButton: false,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: true,
                    loading: false
                });
                break;
            case "EDIT":
                setTexts({
                    ...texts,
                    doButton: "Kaydet"
                });
                setEnable({
                    ...enable,
                    form: true,
                    cancelButton: true,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: true,
                    loading: false
                });
                break;
            case "LOADING":
                setEnable({
                    ...enable,
                    all: false
                });
                setVisibility({
                    ...visibility,
                    loading: true
                });
                break;
            default:
                break;
        }
    }, [pageType]);

    useEffect(() => {
        if (loading) {
            setEnable({
                ...enable,
                all: false
            });
            setVisibility({
                ...visibility,
                loading: true
            });
        } else {
            setEnable({
                ...enable,
                all: true
            });
            setVisibility({
                ...visibility,
                loading: false
            });
        }
    }, [loading]);

    return (
        <>
            <div className="page-content">
                <fieldset disabled={!enable.all}>
                    <Container fluid={true}>
                        <Card>
                            <CardHeader>
                                <Row className='align-items-end'>
                                    <Col sm={1}><Spinner color='danger' hidden={!visibility.loading}>loading ...</Spinner></Col>
                                    <Col sm={3}><h2 className="mb-0">App Keys</h2></Col>
                                    <Col sm={5}></Col>
                                    <Col sm={1}><Button hidden={!visibility.cancelButton} disabled={!enable.cancelButton} onClick={handleCancel} color='warning'>İptal Et</Button></Col>
                                    <Col sm={1}><Button disabled={!enable.refreshButton} onClick={handleRefresh} color='success'>Yenile</Button></Col>
                                    <Col sm={1}><Button disabled={!enable.doButton} onClick={handleDo} color='danger'>{texts.doButton}</Button></Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="form-steps">
                                <Form>
                                    <fieldset disabled={!enable.form}>
                                        <Row>
                                            <Col md={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstNameinpu1" className="form-label">Andorid Version<span style={{ color: "red" }}>*</span></Label>
                                                    <Input value={formValues.android} onChange={(e) => setFormValues({ ...formValues, android: e.target.value })} type="text" className="form-control" id="firstNameinpu1" />
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstNameinpu2" className="form-label">IOS Version<span style={{ color: "red" }}>*</span></Label>
                                                    <Input value={formValues.ios} onChange={(e) => setFormValues({ ...formValues, ios: e.target.value })} type="text" className="form-control" id="firstNameinpu2" />
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <div className="form-check form-check-danger mb-3">
                                                                <Input className="form-check-input" type="checkbox" checked={Boolean(formValues.isAppReady)} onChange={(e) => setFormValues({ ...formValues, isAppReady: Number(e.target.checked) })} />
                                                                <Label className="form-check-label">
                                                                    Active
                                                                </Label>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </Col>
                                        </Row>
                                    </fieldset>
                                </Form>
                            </CardBody>
                        </Card>
                    </Container>
                </fieldset>
            </div>
        </>
    );
}

export default AppKeyTable;