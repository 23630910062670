import React, { useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Alert } from 'reactstrap';
import Select from "react-select";
import { useForm } from 'react-hook-form';

import ParticlesAuth from "./packages/ParticlesAuth";
import { loginAdmin, loginProducer } from '../../helpers/api/auth/auth';
import useAuth from "../../helpers/auth/useAuth";

const Login = () => {
    const { register, handleSubmit } = useForm();
    const [alertShow, setAlertShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const history = useHistory();
    const { setAuth } = useAuth();

    const handleAlert = (err) => {
        console.log(err);
        setAlertMessage(err.text || JSON.stringify(err));
        setAlertShow(true);
    }

    const submitProcess = async (data) => {
        try {
            let responseLogin = await loginAdmin({ ...data });
            if (!responseLogin.success) return handleAlert(responseLogin.data);

            let admin = {
                id: responseLogin.data.id,
                email: responseLogin.data.Email,
                name: responseLogin.data.Name,
                surname: responseLogin.data.Surname,
                token: responseLogin.data.Token,
                roles: responseLogin.data.Roles
            };

            if (admin.roles.length === 0) return handleAlert("You don't have any role.");
            if (admin.roles.includes("PRODUCER")) {
                if (!responseLogin.data.CompanyID) return handleAlert("You don't have any company.");
                admin = { ...admin, companyId: responseLogin.data.CompanyID }
            }

            setAuth(admin);

            if (admin.roles.includes("PRODUCER") || admin.roles.includes("ADMIN")) history.push("/wine-table");
            else history.push("/");
        } catch (error) {
            handleAlert(error);
        }
    }


    document.title = "Login";

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Hoş Geldiniz</h5>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <form action='#' onSubmit={handleSubmit((data) => submitProcess(data))}>
                                                <div className="mb-3">
                                                    <Label htmlFor="username" className="form-label">Email</Label>
                                                    <input {...register("email")} type="text" className="form-control" id="email" placeholder="Enter email" required={true} />
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="password-input">Password</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <input {...register("password")} type="password" className="form-control pe-5 password-input" placeholder="Enter password" id="password-input" required />
                                                    </div>
                                                </div>

                                                <div className="mt-4">
                                                    <Button color="success" className="btn btn-success w-100" type="submit">Sign In</Button>
                                                    <Alert color="danger" isOpen={alertShow} toggle={() => setAlertShow(false)}>
                                                        {alertMessage}
                                                    </Alert>
                                                </div>
                                            </form>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
}

export default Login;