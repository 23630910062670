import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from "reactstrap";
import { Grid, _ } from 'gridjs-react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import useAuth from '../../helpers/auth/useAuth';
import { useHistory } from 'react-router-dom';
import { changeAdminActivity, deleteAdmin, getAllAdmins } from '../../helpers/api/auth/auth';

const AdminTable = () => {
    const [admins, setAdmins] = useState([]);

    const history = useHistory();
    const { auth } = useAuth();

    document.title = "Admins";

    const getAdmins = async () => {
        try {
            setAdmins([]);

            let response = await getAllAdmins({
                email: auth.email,
                token: auth.token
            });
            if (!response.success) throw response.data;

            setAdmins(response.data.map(admin => {
                return [
                    admin.id,
                    admin.Email,
                    admin.Roles,
                    (admin.Active == 1) ? "Yes" : "No",
                    admin.CompanyName
                ]
            }));
        } catch (error) {
            console.log(error);
        }
    }

    const handleClickGridItem = (e) => {
        e.preventDefault();
        let row = e.target.parentElement.parentElement.parentElement;
        let id = row.children[0].innerText;

        history.push("/admin-details", { params: { id, type: "INFORM" } });
    }

    const handleAddAdmin = () => {
        history.push("/admin-details", { params: { type: "ADD" } });
    }

    const handleChangeActivity = async (e) => {
        try {
            e.preventDefault();
            let row = e.target.parentElement.parentElement.parentElement;
            let id = row.children[0].innerText;

            let item = admins.find(w => w[0] == id);
            let activity;
            if (item[3] == "Yes") activity = 0;
            else activity = 1;

            let response = await changeAdminActivity({
                email: auth.email,
                token: auth.token,
                id,
                activity
            });
            if (!response.success) return console.log(response.data);

            await getAdmins();
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeleteAdmin = async (e) => {
        try {
            e.preventDefault();

            confirmAlert({
                title: 'Confirm to Delete',
                message: 'Are you sure to delete this admin?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let row = e.target.parentElement.parentElement.parentElement;
                            let id = row.children[0].innerText;

                            let response = await deleteAdmin({
                                id
                            });
                            if (!response.success) return console.log(response.data);

                            await getAdmins();
                        }
                    },
                    {
                        label: 'No'
                    }
                ]
            });
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        getAdmins();
    }, []);

    return (
        <>
            <div className="page-content">
                <Row>
                    <Col xs="10" />
                    <Col xs="2">
                        <Button onClick={handleAddAdmin} color="danger">Admin Ekle</Button>
                    </Col>
                </Row>
                <Container fluid={true}>
                    <React.Fragment>
                        <Grid
                            data={admins}
                            columns={[
                                "#", "Email", "Roles", "Active", "Company",
                                {
                                    name: 'Actions',
                                    width: '120px',
                                    formatter: (cell) => _(
                                        <>
                                            <Button type='button' onClick={handleClickGridItem} className='btn btn-sm btn-light'> Details </Button>
                                            <Button type='button' onClick={handleDeleteAdmin} className='btn btn-sm btn-danger'> Delete </Button>
                                            <Button type='button' onClick={handleChangeActivity} className='btn btn-sm btn-warning'> Change Activity </Button>
                                        </>
                                    )
                                }
                            ]}
                            search={true}
                            sort={true}
                            pagination={{ enabled: true, limit: 10 }}
                        />
                    </React.Fragment>
                </Container>
            </div>
        </>
    );
}

export default AdminTable;