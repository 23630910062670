import React from 'react';
import { withRouter } from 'react-router-dom';

const ParticlesAuth = ({ children }) => {
    return (
        <React.Fragment>
            <div className="auth-page-wrapper pt-5">
                {children}
            </div>
        </React.Fragment>
    );
};

export default withRouter(ParticlesAuth);