import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    ListGroup,
    ListGroupItem,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Button,
    Spinner
} from "reactstrap";
import classnames from "classnames";
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import Slider from 'react-rangeslider';
import { addGrape, addWine, getAllAromasWithMainAromas, getAllBarrels, getAllColorTypes, getAllDishesWithMainDishes, getAllGlassTypes, getAllGrapes, getAllManufacturePlaces, getAllManufacturePlacesFromGrapes, getAllWineCategories, getAllWineTypes, getGrapeDetailed, getWineDetails, updateGrape, updateWine } from '../../helpers/api/product/product';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import { addAdmin, getAdminDetails, getAllAdminRoles, getAllCompanies, updateAdmin } from '../../helpers/api/auth/auth';
import useAuth from '../../helpers/auth/useAuth';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
// register lottie and define custom element
defineLordIconElement(loadAnimation);

const AdminDetails = () => {
    const [enable, setEnable] = useState({
        all: false,
        doButton: true,
        cancelButton: false,
        refreshButton: true,
        form: true
    });
    const [formValues, setFormValues] = useState({
        email: "",
        name: "",
        surname: "",
        password: "",
        active: 1,
        company: "",
        roles: []
    });
    const [dropdownItems, setDropdownItems] = useState({
        companies: [],
        roles: []
    });
    const [print, setPrint] = useState({
        enable: false,
        roles: []
    });
    const [texts, setTexts] = useState({
        title: "",
        doButton: "Ekle"
    });
    const [pageType, setPageType] = useState("");
    const [loading, setLoading] = useState(false);
    const [visibility, setVisibility] = useState({
        loading: true,
        cancelButton: true
    });
    const [profileMode, setProfileMode] = useState(false);

    const history = useHistory();
    const location = useLocation();
    const { mode, id, type } = location.state.params;

    const handleAdminDetails = async () => {
        try {
            let result = await getAdminDetails({ id });
            if (!result.success) return console.log("Bir hata oluştu");

            let admin = result.data;

            setFormValues({
                ...formValues,
                email: admin.Email || "",
                name: admin.Name || "",
                surname: admin.Surname || "",
                active: admin.Active || 1,
                company: admin.CompanyID || "",
                roles: admin.Roles || []
            });

            if (type != "ADD") {
                setPrint({
                    ...print,
                    enable: true
                });
            }
        } catch (error) {
            return console.log(error);
        }
    }

    const fillDropdowns = async () => {
        try {
            let fields = {};

            { // Roles
                let responseRoles = await getAllAdminRoles();
                if (!responseRoles.success) return console.log(responseRoles.data);

                fields = {
                    ...fields,
                    roles: responseRoles.data || []
                };
            }

            { // Companies
                let reponseCompanies = await getAllCompanies();
                if (!reponseCompanies.success) return console.log(reponseCompanies.data);

                fields = {
                    ...fields,
                    companies: reponseCompanies.data || []
                };
            }

            setDropdownItems({
                ...dropdownItems,
                ...fields
            });

            let otherFields = {};

            if (formValues.company == "") {
                otherFields = {
                    ...otherFields,
                    company: fields.companies[0].id
                };
            }

            setFormValues({
                ...formValues,
                ...otherFields
            });
        } catch (error) {
            return console.log(error);
        }
    }

    const handleRoleChange = ({
        id,
        checked
    }) => {
        let roles = [...dropdownItems.roles];

        let index = roles.findIndex(x => x.id == id);
        if (index === -1) return;

        roles[index] = {
            ...roles[index],
            selected: checked
        };

        setDropdownItems({
            ...dropdownItems,
            roles
        });

        let fRoles = [...formValues.roles];

        if (checked) {
            fRoles.push(id);
        } else {
            let fIndex = fRoles.findIndex(x => x == id);
            if (fIndex === -1) return;

            fRoles.splice(fIndex, 1);
        }

        setFormValues({
            ...formValues,
            roles: fRoles
        });
    }

    const doFirstProcess = async () => {
        try {
            setPageType("LOADING");
            await fillDropdowns();

            if (id) await handleAdminDetails();

            setPageType(type);
        } catch (error) {
            return console.log(error);
        }
    };

    const changedFormValuesRoles = () => {
        if (!dropdownItems.roles.length) return;

        let fRoles = [...dropdownItems.roles];
        fRoles = fRoles.map(x => ({ ...x, selected: false }));

        for (let role of formValues.roles) {
            let index = fRoles.findIndex(x => x.id == role);
            if (index == -1) continue;

            fRoles[index].selected = true;
        }
        return fRoles;
    }

    const handleDo = () => {
        if (pageType == "INFORM") {
            setPageType("EDIT");
        } else if (pageType == "EDIT") {
            handleUpdateAdmin();
        }
        else if (pageType == "ADD") {
            handleAddAdmin();
        }
    }

    const handleCancel = () => {
        setPageType("INFORM");
    }

    const handleRefresh = () => {
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure to refresh?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        if (pageType == "INFORM" || pageType == "EDIT") {
                            reloadContent();
                        } else if (pageType == "ADD") {
                            setFormValues({
                                ...formValues,
                                email: "",
                                name: "",
                                surname: "",
                                password: "",
                                active: 1,
                                company: { name: dropdownItems.companies[0].ProducerName, id: dropdownItems.companies[0].id },
                                roles: []
                            });

                            setDropdownItems({
                                ...dropdownItems,
                                roles: dropdownItems.roles.map(x => ({ ...x, selected: false }))
                            });
                        }
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const reloadContent = async () => {
        try {
            setLoading(true);

            await doFirstProcess();

            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const alertValidationError = (element) => {
        alert("Lütfen " + element + " alanını doldurunuz.");
    }

    const controlNecessaryValues = () => {
        if (formValues.name.trim() == "") return alertValidationError("Name");
        if (formValues.surname.trim() == "") return alertValidationError("Surname");
        if (formValues.email.trim() == "") return alertValidationError("Email");
        if (type == "ADD" && formValues.password.trim() == "") return alertValidationError("Password");
        return true;
    }

    const handleUpdateAdmin = async () => {
        try {
            setLoading(true);
            if (controlNecessaryValues() === true) {
                let data = {
                    ...formValues,
                    roles: print.roles.filter(x => x.selected).map(x => ({ name: x.Name, id: x.id }))
                };

                let response = await updateAdmin({
                    id,
                    ...data
                });

                if (!response.success) return console.log(response.data);

                if (mode == "PROFILE") history.push("/wine-table");
                else history.push("/admin-table");
            }
            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const handleAddAdmin = async () => {
        try {
            setLoading(true);
            if (controlNecessaryValues() === true) {
                let data = {
                    ...formValues,
                    roles: print.roles.filter(x => x.selected).map(x => ({ name: x.Name, id: x.id }))
                };

                let response = await addAdmin({
                    ...data
                });

                if (!response.success) return console.log(response.data);
                history.push("/admin-table");
            }
            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const getNumberValueOfBool = (value) => {
        if (value) return 1;
        else return 0;
    }

    useEffect(() => {
        switch (type) {
            case "EDIT":
                setTexts({
                    ...texts,
                    title: "Admin Düzenle",
                    button: "Kaydet"
                });
                break;
            case "ADD":
                setTexts({
                    ...texts,
                    title: "Admin Ekle",
                    button: "Ekle"
                });
                break;
            case "INFORM":
                setTexts({
                    ...texts,
                    title: "Admin Bilgileri",
                    button: "Düzenle"
                });
                break;
            default:
                break;
        }

        if (mode && mode == "PROFILE") setProfileMode(true);

        doFirstProcess();
    }, []);

    useEffect(() => {
        document.title = texts.title;
    }, [texts.title]);

    useEffect(() => {
        let roles = changedFormValuesRoles();

        setDropdownItems({
            ...dropdownItems,
            roles: roles || []
        });

        if (type == "ADD") {
            setPrint({
                ...print,
                enable: true
            });
        }
    }, [formValues]);

    useEffect(() => {
        let roles = dropdownItems.roles;

        if (!print.enable) return;

        setPrint({
            ...print,
            roles: roles || []
        })
    }, [dropdownItems]);

    useEffect(() => {
        if (!pageType) return

        switch (pageType) {
            case "INFORM":
                setTexts({
                    ...texts,
                    title: "Admin Bilgileri",
                    doButton: "Düzenle"
                });
                setEnable({
                    ...enable,
                    form: false,
                    cancelButton: false,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: true,
                    loading: false
                });
                break;
            case "EDIT":
                setTexts({
                    ...texts,
                    title: "Admin Düzenleme",
                    doButton: "Kaydet"
                });
                setEnable({
                    ...enable,
                    form: true,
                    cancelButton: true,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: true,
                    loading: false
                });
                break;
            case "ADD":
                setTexts({
                    ...texts,
                    title: "Admin Ekleme"
                });
                setEnable({
                    ...enable,
                    form: true,
                    cancelButton: false,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: false,
                    loading: false
                });
                break;
            case "LOADING":
                setEnable({
                    ...enable,
                    all: false
                });
                setVisibility({
                    ...visibility,
                    loading: true
                });
                break;
            default:
                break;
        }
    }, [pageType]);

    useEffect(() => {
        if (loading) {
            setEnable({
                ...enable,
                all: false
            });
            setVisibility({
                ...visibility,
                loading: true
            });
        } else {
            setEnable({
                ...enable,
                all: true
            });
            setVisibility({
                ...visibility,
                loading: false
            });
        }
    }, [loading]);

    return (
        <>
            <div className="page-content">
                <fieldset disabled={!enable.all}>
                    <Container fluid={true}>
                        <Card>
                            <CardHeader>
                                <Row className='align-items-end'>
                                    <Col sm={1}><Spinner color='danger' hidden={!visibility.loading}>loading ...</Spinner></Col>
                                    <Col sm={4}>
                                        <h2 className="mb-0">{texts.title}</h2>
                                        <p>Kaydetmek için '<span style={{ color: "red" }}>*</span>'la işaretlenmiş alanları doldurunuz.</p>
                                    </Col>
                                    <Col sm={4}></Col>
                                    <Col sm={1}><Button hidden={!visibility.cancelButton} disabled={!enable.cancelButton} onClick={handleCancel} color='warning'>İptal Et</Button></Col>
                                    <Col sm={1}><Button disabled={!enable.refreshButton} onClick={handleRefresh} color='danger'>Yenile</Button></Col>
                                    <Col sm={1}><Button disabled={!enable.doButton} onClick={handleDo} color='success'>{texts.doButton}</Button></Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="form-steps">
                                <Form>
                                    <fieldset disabled={!enable.form}>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstNameinpu" className="form-label">Name<span style={{ color: "red" }}>*</span></Label>
                                                    <Input value={formValues.name} onChange={(e) => setFormValues({ ...formValues, name: e.target.value })} type="text" className="form-control" id="firstNameinpu" />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstNameinput" className="form-label">Surname<span style={{ color: "red" }}>*</span></Label>
                                                    <Input value={formValues.surname} onChange={(e) => setFormValues({ ...formValues, surname: e.target.value })} type="text" className="form-control" id="firstNameinput" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col md={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstNameinp" className="form-label">Email<span style={{ color: "red" }}>*</span></Label>
                                                    <Input value={formValues.email} onChange={(e) => setFormValues({ ...formValues, email: e.target.value })} type="email" className="form-control" id="firstNameinp" disabled={profileMode} />
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstNamei" className="form-label">Password<span style={{ color: "red" }}>*</span></Label>
                                                    <Input value={formValues.password} onChange={(e) => setFormValues({ ...formValues, password: e.target.value })} type="password" className="form-control" id="firstNam" />
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="ForminputState" className="form-label">Company</Label>
                                                    <select value={formValues.company} onChange={(e) => setFormValues({ ...formValues, company: e.target.value })} id="ForminputState" className="form-select" data-choices data-choices-sorting="true" disabled={profileMode}>
                                                        {
                                                            dropdownItems.companies.map((item, index) => (
                                                                <option key={index} value={item.id}>{item.ProducerName}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <fieldset disabled={profileMode}>
                                            <Row>
                                                <Col md={6}>
                                                    <Card>
                                                        <CardHeader>
                                                            <h4 className="card-title mb-0 text-danger">Roles</h4>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <div>
                                                                <SimpleBar style={{ height: "242px" }} className="mx-n3">
                                                                    <ListGroup className="list mb-0" flush>
                                                                        {
                                                                            print.roles.map((subItem, subIndex) => (
                                                                                <ListGroupItem key={subIndex}>
                                                                                    <div className="d-flex">
                                                                                        <div className="flex-grow-1">
                                                                                            <div className="form-check form-check-danger mb-3">
                                                                                                <Input className="form-check-input" type="checkbox" defaultChecked={Boolean(getNumberValueOfBool(subItem.selected))} onChange={(e) => handleRoleChange({ id: subItem.id, checked: e.target.checked })} />
                                                                                                <Label className="form-check-label">
                                                                                                    {subItem.Name}
                                                                                                </Label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </ListGroupItem>
                                                                            ))
                                                                        }
                                                                    </ListGroup >
                                                                </SimpleBar>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <div className="form-check form-check-danger mb-3">
                                                                <Input className="form-check-input" type="checkbox" checked={Boolean(formValues.active)} onChange={(e) => setFormValues({ ...formValues, active: Number(e.target.checked) })} />
                                                                <Label className="form-check-label">
                                                                    Active
                                                                </Label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </fieldset>
                                    </fieldset>
                                </Form>
                            </CardBody>
                        </Card>

                    </Container>
                </fieldset>
            </div>
        </>
    );
}

export default AdminDetails;