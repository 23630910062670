import md5 from "md5";

const API_SECRET_KEY = "GIpnY1dr6NDBav2j";

const md5Control = () => {
    let nonce = Math.floor(100000 + Math.random() * 900000);
    let timestamp = Math.floor(Date.now() / 1000);
    let signature = md5(API_SECRET_KEY + "&" + nonce + "&" + timestamp);

    let vals = {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        nonce,
        timestamp,
        signature
    };

    return vals;
}

const tokenControl = ({
    token,
    email
}) => {
    let vals = {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        authorization: "Bearer " + token,
        email
    }

    return vals;
}

export { md5Control, tokenControl };