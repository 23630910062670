import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    ListGroup,
    ListGroupItem,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Button,
    Spinner
} from "reactstrap";
import classnames from "classnames";
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import Slider from 'react-rangeslider';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { updatePlaceMapPicture } from '../../helpers/api/product/product';
import { controlFileSize } from '../../helpers/api/common/toolbox';
// register lottie and define custom element
defineLordIconElement(loadAnimation);

const MapPictureDetails = () => {
    const [enable, setEnable] = useState({
        all: false,
        doButton: true,
        cancelButton: false,
        refreshButton: true,
        form: true
    });
    const [formValues, setFormValues] = useState({
        category: "",
        name: "",
        image: { data: "", isNew: true },
        oldImage: null,
        oldName: ""
    });
    const [print, setPrint] = useState({
        enable: false
    });
    const [texts, setTexts] = useState({
        title: "",
        doButton: "Ekle"
    });
    const [pageType, setPageType] = useState("");
    const [loading, setLoading] = useState(false);
    const [visibility, setVisibility] = useState({
        loading: true,
        cancelButton: true
    });

    const history = useHistory();
    const location = useLocation();
    const { type, category, name, image } = location.state.params;

    const handleMapPictureDetails = async () => {
        try {
            setFormValues({
                ...formValues,
                oldName: name || "",
                name: name || "",
                category: category || "",
                image: (image) ? { data: image, isNew: false } : { data: "", isNew: true },
                oldImage: image
            });

            if (type != "ADD") {
                setPrint({
                    ...print,
                    enable: true
                });
            }
        } catch (error) {
            return console.log(error);
        }
    }

    const doFirstProcess = async () => {
        try {
            setPageType("LOADING");

            if (name) await handleMapPictureDetails();

            setPageType(type);
        } catch (error) {
            return console.log(error);
        }
    };

    const handleDo = () => {
        if (pageType == "INFORM") {
            setPageType("EDIT");
        } else if (pageType == "EDIT") {
            handleUpdateMapPicture();
        }
    }

    const handleCancel = () => {
        setPageType("INFORM");
    }

    const handleRefresh = () => {
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure to refresh?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        if (pageType == "INFORM" || pageType == "EDIT") {
                            reloadContent();
                        } else if (pageType == "ADD") {
                            setFormValues({
                                ...formValues,
                                image: { data: "", isNew: true },
                            });
                        }
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const reloadContent = async () => {
        try {
            setLoading(true);

            await doFirstProcess();

            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const alertValidationError = (element) => {
        alert("Lütfen " + element + " alanını doldurunuz.");
    }

    const controlNecessaryValues = () => {
        if (formValues.category.trim() == "") return alertValidationError("Category");
        if (formValues.name.trim() == "") return alertValidationError("Name");
        if(!controlFileSize(formValues.image)) return alert("Dosya boyutu 1MB'dan fazla olamaz.");
        return true;
    }

    const handleUpdateMapPicture = async () => {
        try {
            setLoading(true);
            if (controlNecessaryValues() === true) {
                let data = {
                    ...formValues,
                    image: (formValues.image.isNew && formValues.image.data != "") ? formValues.image.data : null
                };

                let response = await updatePlaceMapPicture({
                    ...data
                });

                if (!response.success) return console.log(response.data);
                history.push("/map-picture-table");
            }
            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader();
            let file = e.target.files[0];
            reader.onloadend = () => {
                setFormValues({
                    ...formValues,
                    image: { url: reader.result, isNew: true, data: file }
                });
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        switch (type) {
            case "EDIT":
                setTexts({
                    ...texts,
                    title: "MapPicture Düzenle",
                    button: "Kaydet"
                });
                break;
            case "ADD":
                setTexts({
                    ...texts,
                    title: "MapPicture Ekle",
                    button: "Ekle"
                });
                break;
            case "INFORM":
                setTexts({
                    ...texts,
                    title: "MapPicture Bilgileri",
                    button: "Düzenle"
                });
                break;
            default:
                break;
        }

        doFirstProcess();
    }, []);

    useEffect(() => {
        document.title = texts.title;
    }, [texts.title]);

    useEffect(() => {
        if (!pageType) return

        switch (pageType) {
            case "INFORM":
                setTexts({
                    ...texts,
                    title: "MapPicture Bilgileri",
                    doButton: "Düzenle"
                });
                setEnable({
                    ...enable,
                    form: false,
                    cancelButton: false,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: true,
                    loading: false
                });
                break;
            case "EDIT":
                setTexts({
                    ...texts,
                    title: "MapPicture Düzenleme",
                    doButton: "Kaydet"
                });
                setEnable({
                    ...enable,
                    form: true,
                    cancelButton: true,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: true,
                    loading: false
                });
                break;
            case "ADD":
                setTexts({
                    ...texts,
                    title: "MapPicture Ekleme"
                });
                setEnable({
                    ...enable,
                    form: true,
                    cancelButton: false,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: false,
                    loading: false
                });
                break;
            case "LOADING":
                setEnable({
                    ...enable,
                    all: false
                });
                setVisibility({
                    ...visibility,
                    loading: true
                });
                break;
            default:
                break;
        }
    }, [pageType]);

    useEffect(() => {
        if (loading) {
            setEnable({
                ...enable,
                all: false
            });
            setVisibility({
                ...visibility,
                loading: true
            });
        } else {
            setEnable({
                ...enable,
                all: true
            });
            setVisibility({
                ...visibility,
                loading: false
            });
        }
    }, [loading]);

    return (
        <>
            <div className="page-content">
                <fieldset disabled={!enable.all}>
                    <Container fluid={true}>
                        <Card>
                            <CardHeader>
                                <Row className='align-items-end'>
                                    <Col sm={1}><Spinner color='danger' hidden={!visibility.loading}>loading ...</Spinner></Col>
                                    <Col sm={4}>
                                        <h2 className="mb-0">{texts.title}</h2>
                                        <p>Kaydetmek için '<span style={{ color: "red" }}>*</span>'la işaretlenmiş alanları doldurunuz.</p>
                                    </Col>
                                    <Col sm={4}></Col>
                                    <Col sm={1}><Button hidden={!visibility.cancelButton} disabled={!enable.cancelButton} onClick={handleCancel} color='warning'>İptal Et</Button></Col>
                                    <Col sm={1}><Button disabled={!enable.refreshButton} onClick={handleRefresh} color='danger'>Yenile</Button></Col>
                                    <Col sm={1}><Button disabled={!enable.doButton} onClick={handleDo} color='success'>{texts.doButton}</Button></Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="form-steps">
                                <Form>
                                    <fieldset disabled={!enable.form}>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstNameinpu" className="form-label">Category<span style={{ color: "red" }}>*</span></Label>
                                                    <Input value={formValues.category} onChange={(e) => setFormValues({ ...formValues, category: e.target.value })} type="text" className="form-control" id="firstNameinpu" disabled={true} />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstNameinpu" className="form-label">Name<span style={{ color: "red" }}>*</span></Label>
                                                    <Input value={formValues.name} onChange={(e) => setFormValues({ ...formValues, name: e.target.value })} type="text" className="form-control" id="firstNameinpu" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col lg={12}>
                                                <Card>
                                                    <CardHeader className="card-header">
                                                        <h4 className="card-title mb-0">Map Resmi</h4>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg={6}>
                                                                {
                                                                    (!formValues.image.isNew && formValues.image.data)
                                                                        ? <img src={formValues.image.data} style={{ height: "100px" }} />
                                                                        : (formValues.image.isNew && formValues.image.data && formValues.image.data != "" && formValues.image.url)
                                                                            ? <img src={formValues.image.url} style={{ height: "100px" }} />
                                                                            : <></>
                                                                }
                                                            </Col>
                                                            <Col lg={6}>
                                                                <div>
                                                                    <input className="form-control" type="file" id="formFile" onChange={handleImageChange} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </fieldset>
                                </Form>
                            </CardBody>
                        </Card>

                    </Container>
                </fieldset>
            </div>
        </>
    );
}

export default MapPictureDetails;