import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from "reactstrap";
import { Grid, _ } from 'gridjs-react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { useHistory } from 'react-router-dom';
import { deleteMainAroma, getMainAromas } from '../../helpers/api/product/product';

const MainAromaTable = () => {
    const [aromas, setAromas] = useState([]);

    const history = useHistory();

    document.title = "My Main Aromas";

    const getAromas = async () => {
        try {
            setAromas([]);
            let response = await getMainAromas();
            if (!response.success) throw response.data;

            setAromas(response.data.map(aroma => {
                return [
                    aroma.id,
                    aroma.MainAromaName,
                    aroma.MainAromaPicture
                ]
            }));
        } catch (error) {
            console.log(error);
        }
    }

    const handleClickGridItem = (e) => {
        e.preventDefault();
        let row = e.target.parentElement.parentElement.parentElement;
        let id = row.children[0].innerText;

        history.push("/main-aroma-details", { params: { id, type: "INFORM" } });
    }

    const handleAddMainAroma = () => {
        history.push("/main-aroma-details", { params: { type: "ADD" } });
    }

    const handleClickDelete = async (e) => {
        try {
            e.preventDefault();

            confirmAlert({
                title: 'Confirm to Delete',
                message: 'Are you sure to delete this aroma?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let row = e.target.parentElement.parentElement.parentElement;
                            let id = row.children[0].innerText;

                            let response = await deleteMainAroma({ id });
                            if (!response.success) return console.log(response.data);

                            await getAromas();
                        }
                    },
                    {
                        label: 'No'
                    }
                ]
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getAromas();
    }, []);

    return (
        <>
            <div className="page-content">
                <Row>
                    <Col xs="10" />
                    <Col xs="2">
                        <Button onClick={handleAddMainAroma} color="danger">Main Aroma Ekle</Button>
                    </Col>
                </Row>
                <Container fluid={true}>
                    <React.Fragment>
                        <Grid
                            data={aromas}
                            columns={[
                                "#", "Name",
                                {
                                    name: 'Picture',
                                    width: '120px',
                                    formatter: (cell) => _(
                                        <>
                                            {(cell)
                                                ? <img src={cell} alt="main-aroma" style={{ width: "100px", height: "100px" }} />
                                                : <></>
                                            }
                                        </>
                                    )
                                },
                                {
                                    name: 'Actions',
                                    width: '120px',
                                    formatter: (cell) => _(
                                        <>
                                            <Button type='button' onClick={handleClickGridItem} className='btn btn-sm btn-light'> Details </Button>
                                            <Button type='button' onClick={handleClickDelete} className='btn btn-sm btn-danger'> Delete </Button>
                                        </>
                                    )
                                }
                            ]}
                            search={true}
                            sort={true}
                            pagination={{ enabled: true, limit: 10 }}
                        />
                    </React.Fragment>
                </Container>
            </div>
        </>
    );
}

export default MainAromaTable;