import React from 'react';
import { Switch, Route } from "react-router-dom";

//Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";
//routes
import { sPublicRoutes, adminRoutes, producerRoutes, allAuthRoutes } from "./allRoutes";
import { AuthProtected, AccessRoute } from './AuthProtected';
import RequireAuth from "../helpers/auth/RequireAuth";

const Index = () => {
    const availableSPublicRoutesPaths = sPublicRoutes.map((r) => r.path);
    const availableSAdminRoutesPaths = adminRoutes.map((r) => r.path);
    const availableSProducerRoutesPaths = producerRoutes.map((r) => r.path);
    const availableSAllAuthRoutesPaths = allAuthRoutes.map((r) => r.path);


    return (
        <React.Fragment>
            <Switch>
                <Route path={availableSPublicRoutesPaths}>
                    <Switch>
                        {
                            sPublicRoutes.map((route, idx) => (
                                <Route path={route.path}
                                    component={route.component}
                                    key={idx}
                                    exact={true}
                                />
                            ))
                        }
                    </Switch>
                </Route>
                <Route path={availableSAdminRoutesPaths}>
                    <RequireAuth allowedRoles={["ADMIN"]}>
                        <VerticalLayout>
                            <Switch>
                                {
                                    adminRoutes.map((route, idx) => (
                                        <Route path={route.path}
                                            component={route.component}
                                            key={idx}
                                            exact={true}
                                        />
                                    ))
                                }
                            </Switch>
                        </VerticalLayout>
                    </RequireAuth>
                </Route>
                <Route path={availableSProducerRoutesPaths}>
                    <RequireAuth allowedRoles={["PRODUCER"]}>
                        <VerticalLayout>
                            <Switch>
                                {
                                    producerRoutes.map((route, idx) => (
                                        <Route path={route.path}
                                            component={route.component}
                                            key={idx}
                                            exact={true}
                                        />
                                    ))
                                }
                            </Switch>
                        </VerticalLayout>
                    </RequireAuth>
                </Route>
                <Route path={availableSAllAuthRoutesPaths}>
                    <RequireAuth allowedRoles={["PRODUCER", "ADMIN"]}>
                        <VerticalLayout>
                            <Switch>
                                {
                                    allAuthRoutes.map((route, idx) => (
                                        <Route path={route.path}
                                            component={route.component}
                                            key={idx}
                                            exact={true}
                                        />
                                    ))
                                }
                            </Switch>
                        </VerticalLayout>
                    </RequireAuth>
                </Route>

            </Switch>
        </React.Fragment>
    );
};

export default Index;