const sizeReference = 1000000; // 1MB
const sizeLimit = sizeReference; // 1MB

const controlFileSize = (file) => {
    if(!file || !file.size) return true;
    if(file.size > sizeLimit) {
        return false;
    }
    return true;
}

export {
    controlFileSize
}