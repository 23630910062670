import WineTable from "../pages/AdminPanel/WineTable";
import SLogin from "../pages/AdminPanel/Login";
import Unauthorized from "../pages/AdminPanel/Unauthorized";
import WineDetails from "../pages/AdminPanel/WineDetails";
import GrapeTable from "../pages/AdminPanel/GrapeTable";
import GrapeDetails from "../pages/AdminPanel/GrapeDetails";
import AdminTable from "../pages/AdminPanel/AdminTable";
import AdminDetails from "../pages/AdminPanel/AdminDetails";
import AromaTable from "../pages/AdminPanel/AromaTable";
import AromaDetails from "../pages/AdminPanel/AromaDetails";
import MainAromaTable from "../pages/AdminPanel/MainAromaTable";
import MainAromaDetails from "../pages/AdminPanel/MainAromaDetails";
import FoodTable from "../pages/AdminPanel/FoodTable";
import FoodDetails from "../pages/AdminPanel/FoodDetails";
import MainFoodDetails from "../pages/AdminPanel/MainFoodDetails";
import MainFoodTable from "../pages/AdminPanel/MainFoodTable";
import EmptyPage from "../pages/AdminPanel/EmptyPage";
import ProducerTable from "../pages/AdminPanel/ProducerTable";
import ProducerDetails from "../pages/AdminPanel/ProducerDetails";
import PriceTable from "../pages/AdminPanel/PriceTable";
import BlogTable from "../pages/AdminPanel/BlogTable";
import BlogDetails from "../pages/AdminPanel/BlogDetails";
import ActivityTable from "../pages/AdminPanel/ActivityTable";
import ActivityDetails from "../pages/AdminPanel/ActivityDetails";
import ColorTable from "../pages/AdminPanel/ColorTable";
import ColorDetails from "../pages/AdminPanel/ColorDetails";
import MapPictureTable from "../pages/AdminPanel/MapPictureTable";
import MapPictureDetails from "../pages/AdminPanel/MapPictureDetails";
import GlassTable from "../pages/AdminPanel/GlassTable";
import GlassDetails from "../pages/AdminPanel/GlassDetails";
import GrapeOfTheWeek from "../pages/AdminPanel/GrapesOfWeekTable";
import GrapeOfTheWeekTable from "../pages/AdminPanel/GrapesOfWeekTable";
import GrapeOfTheWeekDetails from "../pages/AdminPanel/GrapesOfWeekDetails";
import NotificationTable from "../pages/AdminPanel/NotificationTable";
import NotificationDetails from "../pages/AdminPanel/NotificationDetails";
import { loginAdmin } from "../helpers/api/auth/auth";
import PremiumCodeTable from "../pages/AdminPanel/PremiumCodeTable";
import PremiumCodeDetails from "../pages/AdminPanel/PremiumCodeDetails";
import AppKeyTable from "../pages/AdminPanel/AppKeyTable";


const sPublicRoutes = [
  { path: "/login", component: SLogin },
  { path: "/unauthorized", component: Unauthorized },
];

const adminRoutes = [
  { path: "/admin-table", component: AdminTable },
  { path: "/aroma-table", component: AromaTable },
  { path: "/aroma-details", component: AromaDetails },
  { path: "/main-aroma-table", component: MainAromaTable },
  { path: "/main-aroma-details", component: MainAromaDetails },
  { path: "/food-table", component: FoodTable },
  { path: "/food-details", component: FoodDetails },
  { path: "/main-food-table", component: MainFoodTable },
  { path: "/main-food-details", component: MainFoodDetails },
  { path: "/producer-table", component: ProducerTable },
  { path: "/price-table", component: PriceTable },
  { path: "/grape-table", component: GrapeTable },
  { path: "/grape-details", component: GrapeDetails },
  { path: "/blog-table", component: BlogTable },
  { path: "/blog-details", component: BlogDetails },
  { path: "/activity-table", component: ActivityTable },
  { path: "/activity-details", component: ActivityDetails },
  { path: "/color-table", component: ColorTable },
  { path: "/color-details", component: ColorDetails },
  { path: "/map-picture-table", component: MapPictureTable },
  { path: "/map-picture-details", component: MapPictureDetails },
  { path: "/glass-table", component: GlassTable },
  { path: "/glass-details", component: GlassDetails },
  { path: "/grape-of-the-week-table", component: GrapeOfTheWeekTable },
  { path: "/grape-of-the-week-details", component: GrapeOfTheWeekDetails },
  { path: "/notification-table", component: NotificationTable },
  { path: "/notification-details", component: NotificationDetails },
  { path: "/premium-code-table", component: PremiumCodeTable },
  { path: "/premium-code-details", component: PremiumCodeDetails },
  { path: "/app-key-table", component: AppKeyTable }
]

const producerRoutes = [
  { path: "/wine-details", component: WineDetails },
  { path: "/wine-table", component: WineTable },
  { path: "/producer-details", component: ProducerDetails },
]

const allAuthRoutes = [
  { path: "/", component: SLogin },
  { path: "/admin-details", component: AdminDetails }
]


export { sPublicRoutes, adminRoutes, producerRoutes, allAuthRoutes };