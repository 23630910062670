//const baseURL = "http://ec2-3-86-195-162.compute-1.amazonaws.com:5000";
const localhostURL = "http://localhost:8000";
const modernURL = "https://api.sarapp.co";

// Auth
const adminLoginURL = "/Auth/LoginAdmin";
const getAllAdminsURL = "/Auth/GetAllAdmins";
const changeAdminActivityURL = "/Auth/ChangeAdminActivity";
const getAllAdminRolesURL = "/Auth/GetAllAdminRoles";
const getAllCompaniesURL = "/Auth/GetAllCompanies";
const getAdminDetailsURL = "/Auth/GetAdminDetails";
const updateAdminURL = "/Auth/UpdateAdmin";
const addAdminURL = "/Auth/AddAdmin";
const deleteAdminURL = "/Auth/DeleteAdmin";
const getVersionInfoURL = "/Auth/GetVersionInfo";
const editAppSettingsURL = "/Auth/EditAppSettings";
const getIsAppReadyURL = "/Auth/GetIsAppReady";

// PRODUCER
const getArtisansURL = "/Producer/GetArtisans";
const getProducerDetailsURL = "/Producer/GetProducerDetails";
const addProducerURL = "/Producer/AddProducer";
const updateProducerURL = "/Producer/UpdateProducer";
const deleteProducerURL = "/Producer/DeleteProducer";

// PRODUCT
const getAllWinesURL = "/Product/GetAllWines";
const getWinesByCompanyURL = "/Product/GetWinesByCompany";
const getWineDetailsURL = "/Product/GetWineDetails";
const getAllWineCategoriesURL = "/Product/GetAllWineCategories";
const getAllWineTypesURL = "/Product/GetWineTypes";
const getAllColorTypesURL = "/Product/GetAllColorTypes";
const getAllBarrelsURL = "/Product/GetAllBarrels";
const getAllGlassTypesURL = "/Product/GetAllGlassTypes";
const getAllGrapesURL = "/Product/GetAllGrapes";
const getAllManufacturePlacesURL = "/Product/GetAllManufacturePlaces";
const getAllAromasWithMainAromasURL = "/Product/GetAllAromasWithMainAromas";
const getAllDishesWithMainDishesURL = "/Product/GetAllDishesWithMainDishes";
const updateWineURL = "/Product/UpdateWine";
const addWineURL = "/Product/AddWine";
const getAllGrapesDetailedURL = "/Product/GetAllGrapesDetailed";
const getGrapeDetailedURL = "/Product/GetGrapeDetails";
const updateGrapeURL = "/Product/UpdateGrape";
const addGrapeURL = "/Product/AddGrape";
const deleteGrapeURL = "/Product/DeleteGrape";
const deleteWineURL = "/Product/DeleteWine";
const changeWineActivityURL = "/Product/ChangeWineActivity";
const getAromaDetailsURL = "/Product/GetAromaDetails";
const getMainAromasURL = "/Product/GetMainAromas";
const addAromaURL = "/Product/AddAroma";
const updateAromaURL = "/Product/UpdateAroma";
const getMainAromaDetailsURL = "/Product/GetMainAromaDetails";
const addMainAromaURL = "/Product/AddMainAroma";
const updateMainAromaURL = "/Product/UpdateMainAroma";
const getFoodDetailsURL = "/Product/GetFoodDetails";
const addFoodURL = "/Product/AddFood";
const updateFoodURL = "/Product/UpdateFood";
const getMainFoodDetailsURL = "/Product/GetMainFoodDetails";
const addMainFoodURL = "/Product/AddMainFood";
const updateMainFoodURL = "/Product/UpdateMainFood";
const getMainDishesURL = "/Product/GetMainDishes";
const deleteMainFoodURL = "/Product/DeleteMainFood";
const deleteFoodURL = "/Product/DeleteFood";
const deleteMainAromaURL = "/Product/DeleteMainAroma";
const deleteAromaURL = "/Product/DeleteAroma";
const getAllGrapesDetailedByCompanyURL = "/Product/GetAllGrapesDetailedByCompany";
const getPriceCategoriesURL = "/Product/GetPriceCategories";
const updatePriceCategoriesURL = "/Product/UpdatePriceCategories";
const getAllMotherlandNamesURL = "/Product/GetAllMotherlandNames";
const deleteColorTypeURL = "/Product/DeleteColorType";
const getColorTypeDetailsURL = "/Product/GetColorTypeDetails";
const addColorTypeURL = "/Product/AddColorType";
const updateColorTypeURL = "/Product/UpdateColorType";
const getAllPlaceMapPicturesURL = "/Product/GetAllPlaceMapPictures";
const updatePlaceMapPictureURL = "/Product/UpdatePlaceMapPicture";
const getGlassTypeDetailsURL = "/Product/GetGlassTypeDetails";
const addGlassTypeURL = "/Product/AddGlassType";
const updateGlassTypeURL = "/Product/UpdateGlassType";
const deleteGlassTypeURL = "/Product/DeleteGlassType";
const deletePlaceMapPictureURL = "/Product/DeletePlaceMapPicture";

// INFORMATION
const getAllNewsURL = "/Information/GetAllNews";
const getNewsDetailsURL = "/Information/GetNewsDetails";
const deleteNewsURL = "/Information/DeleteNews";
const addNewsURL = "/Information/AddNews";
const updateNewsURL = "/Information/UpdateNews";
const getAllActivitiesURL = "/Information/GetAllActivities";
const getActivityDetailsURL = "/Information/GetActivityDetails";
const deleteActivityURL = "/Information/DeleteActivity";
const changeActivityOfActivityURL = "/Information/ChangeActivityOfActivity";
const addActivityURL = "/Information/AddActivity";
const updateActivityURL = "/Information/UpdateActivity";
const getGrapesOfTheWeeksURL = "/Information/GetGrapesOfTheWeeks";
const getGrapeOfTheWeekDetailsURL = "/Information/GetGrapeOfTheWeekDetailed";
const deleteGrapeOfTheWeekURL = "/Information/DeleteGrapeOfTheWeek";
const addGrapeOfTheWeekURL = "/Information/AddGrapeOfTheWeek";
const updateGrapeOfTheWeekURL = "/Information/UpdateGrapeOfTheWeek";

// NOTIFICATION
const getNotificationsURL = "/Notification/GetNotifications";
const notificateDeviceFromPanelURL = "/Notification/NotificateDeviceFromPanel";
const getNotificationTypesURL = "/Notification/GetNotificationTypes";
const getNotificationDetailsURL = "/Notification/GetNotificationDetails";
const getReceiverTypesURL = "/Notification/GetReceiverTypes";

// USER
const getNotificitableUsersURL = "/User/GetNotificatableUsers";

// PREMIUM
const getPremiumCodesURL = "/Premium/GetPremiumCodes";
const getPremiumCodeURL = "/Premium/GetPremiumCode";
const addPremiumCodeURL = "/Premium/AddPremiumCode";
const updatePremiumCodeURL = "/Premium/UpdatePremiumCode";
const deletePremiumCodeURL = "/Premium/DeletePremiumCode";
const changeValidOfPremiumCodeURL = "/Premium/ChangeValidOfPremiumCode";

export {
    
    localhostURL,
    modernURL,

    adminLoginURL,
    getAllAdminsURL,
    changeAdminActivityURL,
    getAllAdminRolesURL,
    getAllCompaniesURL,
    getAdminDetailsURL,
    updateAdminURL,
    addAdminURL,
    deleteAdminURL,
    getVersionInfoURL,
    editAppSettingsURL,
    getIsAppReadyURL,

    getArtisansURL,
    getProducerDetailsURL,
    addProducerURL,
    updateProducerURL,
    deleteProducerURL,

    getAllWinesURL,
    getWinesByCompanyURL,
    getWineDetailsURL,
    getAllWineCategoriesURL,
    getAllWineTypesURL,
    getAllColorTypesURL,
    getAllBarrelsURL,
    getAllGlassTypesURL,
    getAllGrapesURL,
    getAllManufacturePlacesURL,
    getAllAromasWithMainAromasURL,
    getAllDishesWithMainDishesURL,
    updateWineURL,
    addWineURL,
    getAllGrapesDetailedURL,
    getGrapeDetailedURL,
    updateGrapeURL,
    addGrapeURL,
    deleteGrapeURL,
    deleteWineURL,
    changeWineActivityURL,
    getAromaDetailsURL,
    getMainAromasURL,
    addAromaURL,
    updateAromaURL,
    getMainAromaDetailsURL,
    addMainAromaURL,
    updateMainAromaURL,
    getFoodDetailsURL,
    addFoodURL,
    updateFoodURL,
    getMainFoodDetailsURL,
    addMainFoodURL,
    updateMainFoodURL,
    getMainDishesURL,
    deleteMainFoodURL,
    deleteFoodURL,
    deleteMainAromaURL,
    deleteAromaURL,
    getAllGrapesDetailedByCompanyURL,
    getPriceCategoriesURL,
    updatePriceCategoriesURL,
    getAllMotherlandNamesURL,
    deleteColorTypeURL,
    getColorTypeDetailsURL,
    addColorTypeURL,
    updateColorTypeURL,
    getAllPlaceMapPicturesURL,
    updatePlaceMapPictureURL,
    getGlassTypeDetailsURL,
    addGlassTypeURL,
    updateGlassTypeURL,
    deleteGlassTypeURL,
    deletePlaceMapPictureURL,

    getAllNewsURL,
    getNewsDetailsURL,
    deleteNewsURL,
    addNewsURL,
    updateNewsURL,
    getAllActivitiesURL,
    getActivityDetailsURL,
    deleteActivityURL,
    changeActivityOfActivityURL,
    addActivityURL,
    updateActivityURL,
    getGrapesOfTheWeeksURL,
    getGrapeOfTheWeekDetailsURL,
    deleteGrapeOfTheWeekURL,
    addGrapeOfTheWeekURL,
    updateGrapeOfTheWeekURL,

    getNotificationsURL,
    notificateDeviceFromPanelURL,
    getNotificationTypesURL,
    getNotificationDetailsURL,
    getReceiverTypesURL,

    getNotificitableUsersURL,

    getPremiumCodesURL,
    getPremiumCodeURL,
    addPremiumCodeURL,
    updatePremiumCodeURL,
    deletePremiumCodeURL,
    changeValidOfPremiumCodeURL
};