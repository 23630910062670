import { useLocation, Redirect } from "react-router-dom";
import useAuth from "./useAuth";

const compareArrays = (a1, a2) => {
    let result = false;

    for (let item1 of a1) {
        for (let item2 of a2) {
            if (item1 == item2) {
                result = true;
                break;
            }
        }
    }

    return result;
}

const RequireAuth = ({ allowedRoles, children }) => {
    const { auth } = useAuth();
    const location = useLocation();

    if (!auth || !auth.roles || !auth.token || !auth.roles.length) return <Redirect to="/login" state={{ from: location }} replace />
    else if (compareArrays(allowedRoles || [], auth.roles) || compareArrays(["ADMIN"], auth.roles)) return children;
    else return <Redirect to="/unauthorized" state={{ from: location }} replace />
}

export default RequireAuth;