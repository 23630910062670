import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from "reactstrap";
import { Grid, _ } from 'gridjs-react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { deleteGrape, getAllGrapesDetailed } from "../../helpers/api/product/product";
import { useHistory } from 'react-router-dom';

const GrapeTable = () => {
    const [grapes, setGrapes] = useState([]);

    const history = useHistory();

    document.title = "My Grapes";

    const getGrapes = async () => {
        try {
            setGrapes([]);
            let response = await getAllGrapesDetailed();
            if (!response.success) throw response.data;

            setGrapes(response.data.map((grape) => ([
                grape.id,
                grape.GrapeName,
                grape.OldingTime,
                grape.ManufacturePlaceName,
                grape.MotherlandName,
                grape.ColorPicture,
                grape.GrapePicture
            ])));

        } catch (error) {
            console.log(error);
        }
    }

    const handleClickGridItem = (e) => {
        e.preventDefault();
        let row = e.target.parentElement.parentElement.parentElement;
        let id = row.children[0].innerText;

        history.push("/grape-details", { params: { id, type: "INFORM" } });
    }

    const handleAddWine = () => {
        history.push("/grape-details", { params: { type: "ADD" } });
    }

    const handleClickDelete = async (e) => {
        try {
            e.preventDefault();

            confirmAlert({
                title: 'Confirm to Delete',
                message: 'Are you sure to delete this grape?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let row = e.target.parentElement.parentElement.parentElement;
                            let id = row.children[0].innerText;

                            let response = await deleteGrape({ id });
                            if (!response.success) return console.log(response.data);

                            await getGrapes();
                        }
                    },
                    {
                        label: 'No'
                    }
                ]
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getGrapes();
    }, []);

    return (
        <>
            <div className="page-content">
                <Row>
                    <Col xs="10" />
                    <Col xs="2">
                        <Button onClick={handleAddWine} color="danger">Üzüm Ekle</Button>
                    </Col>
                </Row>
                <Container fluid={true}>
                    <React.Fragment>
                        <Grid
                            data={grapes}
                            columns={[
                                "#", "Ad", "Yıllanma", "Üretici", "Anavatan",
                                {
                                    name: 'Renk',
                                    width: '50px',
                                    formatter: (cell) => _(
                                        <>
                                            {cell && (typeof cell == "string") && cell.trim().length == 7 &&
                                                <div style={{ width: "30px", height: "30px", backgroundColor: `${cell}`, borderRadius: "15px", margin: "auto" }}></div>
                                            }
                                        </>
                                    )
                                },
                                {
                                    name: 'Resim',
                                    width: '120px',
                                    formatter: (cell) => _(
                                        <>
                                            {cell &&
                                                <img src={cell} alt="food" style={{ width: "100px", height: "100px" }} />
                                            }
                                        </>
                                    )
                                },
                                {
                                    name: 'Actions',
                                    width: '120px',
                                    formatter: (cell) => _(
                                        <>
                                            <Button type='button' onClick={handleClickGridItem} className='btn btn-sm btn-light'> Details </Button>
                                            <Button type='button' onClick={handleClickDelete} className='btn btn-sm btn-danger'> Delete </Button>
                                        </>
                                    )
                                }
                            ]}
                            search={true}
                            sort={true}
                            pagination={{ enabled: true, limit: 25 }}
                        />
                    </React.Fragment>
                </Container>
            </div>
        </>
    );
}

export default GrapeTable;