import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    ListGroup,
    ListGroupItem,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Button,
    Spinner
} from "reactstrap";
import classnames from "classnames";
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import Slider from 'react-rangeslider';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import { addMainFood, getMainFoodDetails, updateMainFood } from '../../helpers/api/product/product';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { controlFileSize } from '../../helpers/api/common/toolbox';
import { addPremiumCode, getPremiumCode, updatePremiumCode } from '../../helpers/api/premium/premium';
// register lottie and define custom element
defineLordIconElement(loadAnimation);

const PremiumCodeDetails = () => {
    const [enable, setEnable] = useState({
        all: false,
        doButton: true,
        cancelButton: false,
        refreshButton: true,
        form: true
    });
    const [formValues, setFormValues] = useState({
        code: "",
        expireDate: "",
        monthCount: "",
        isValid: true,
        usageLimit: ""
    });
    const [print, setPrint] = useState({
        enable: false
    });
    const [texts, setTexts] = useState({
        title: "",
        doButton: "Ekle"
    });
    const [pageType, setPageType] = useState("");
    const [loading, setLoading] = useState(false);
    const [visibility, setVisibility] = useState({
        loading: true,
        cancelButton: true
    });

    const history = useHistory();
    const location = useLocation();
    const { id, type } = location.state.params;

    const handleCodeDetails = async () => {
        try {
            let result = await getPremiumCode({ id });
            if (!result.success) return console.log("Bir hata oluştu");

            let premiumCode = result.data;

            setFormValues({
                ...formValues,
                code: premiumCode.code || "",
                expireDate: premiumCode.expireDate || "",
                monthCount: premiumCode.monthCount || "",
                isValid: premiumCode.isValid,
                usageLimit: premiumCode.usageLimit || ""
            });

            if (type != "ADD") {
                setPrint({
                    ...print,
                    enable: true
                });
            }
        } catch (error) {
            return console.log(error);
        }
    }

    const doFirstProcess = async () => {
        try {
            setPageType("LOADING");

            if (id) await handleCodeDetails();

            setPageType(type);
        } catch (error) {
            return console.log(error);
        }
    };

    const handleDo = () => {
        alert("Lütfen önceki kodlardan farklı bir kod girdiğinizden emin olunuz. Aksi takdirde işlem tamamlanamayacaktır.");
        if (pageType == "INFORM") {
            setPageType("EDIT");
        } else if (pageType == "EDIT") {
            handleUpdateCode();
        }
        else if (pageType == "ADD") {
            handleAddCode();
        }
    }

    const handleCancel = () => {
        setPageType("INFORM");
    }

    const handleRefresh = () => {
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure to refresh?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        if (pageType == "INFORM" || pageType == "EDIT") {
                            reloadContent();
                        } else if (pageType == "ADD") {
                            setFormValues({
                                ...formValues,
                                code: "",
                                expireDate: "",
                                monthCount: "",
                                isValid: true,
                                usageLimit: ""
                            });
                        }
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const reloadContent = async () => {
        try {
            setLoading(true);

            await doFirstProcess();

            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const alertValidationError = (element) => {
        alert("Lütfen " + element + " alanını doldurunuz.");
    }

    const numberAlert = (element) => {
        alert("Lütfen " + element + " alanına tam ve sıfırdan büyük bir sayı giriniz.");
    }

    const controlNecessaryValues = () => {
        if (formValues.code.trim() == "") return alertValidationError("Code");
        if (formValues.expireDate.trim() == "") return alertValidationError("Expire Date");
        if (formValues.monthCount == "") return alertValidationError("Month Count");
        if (formValues.monthCount <= 0 || formValues.monthCount % 1 != 0) return numberAlert("Month Count");
        if (formValues.usageLimit == "") return alertValidationError("Usage Limit");
        if (formValues.usageLimit <= 0 || formValues.usageLimit % 1 != 0) return numberAlert("Usage Limit");

        return true;
    }

    const handleUpdateCode = async () => {
        try {
            setLoading(true);
            if (controlNecessaryValues() === true) {
                let data = {
                    ...formValues
                };

                data.monthCount = parseInt(data.monthCount);
                data.usageLimit = parseInt(data.usageLimit);

                let response = await updatePremiumCode({
                    id,
                    ...data
                });

                if (!response.success) return console.log(response.data);
                history.push("/premium-code-table");
            }
            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const handleAddCode = async () => {
        try {
            setLoading(true);
            if (controlNecessaryValues() === true) {
                let data = {
                    ...formValues
                };

                data.monthCount = parseInt(data.monthCount);
                data.usageLimit = parseInt(data.usageLimit);

                let response = await addPremiumCode({
                    ...data,
                    now: new Date()
                });

                if (!response.success) return console.log(response.data);
                history.push("/premium-code-table");
            }
            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const getNumberValueOfBool = (value) => {
        if (value) return 1;
        else return 0;
    }

    useEffect(() => {
        switch (type) {
            case "EDIT":
                setTexts({
                    ...texts,
                    title: "Code Düzenle",
                    button: "Kaydet"
                });
                break;
            case "ADD":
                setTexts({
                    ...texts,
                    title: "Code Ekle",
                    button: "Ekle"
                });
                break;
            case "INFORM":
                setTexts({
                    ...texts,
                    title: "Code Bilgileri",
                    button: "Düzenle"
                });
                break;
            default:
                break;
        }

        doFirstProcess();
    }, []);

    useEffect(() => {
        document.title = texts.title;
    }, [texts.title]);

    useEffect(() => {
        if (!pageType) return

        switch (pageType) {
            case "INFORM":
                setTexts({
                    ...texts,
                    title: "Code Bilgileri",
                    doButton: "Düzenle"
                });
                setEnable({
                    ...enable,
                    form: false,
                    cancelButton: false,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: true,
                    loading: false
                });
                break;
            case "EDIT":
                setTexts({
                    ...texts,
                    title: "Code Düzenleme",
                    doButton: "Kaydet"
                });
                setEnable({
                    ...enable,
                    form: true,
                    cancelButton: true,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: true,
                    loading: false
                });
                break;
            case "ADD":
                setTexts({
                    ...texts,
                    title: "Code Ekleme"
                });
                setEnable({
                    ...enable,
                    form: true,
                    cancelButton: false,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: false,
                    loading: false
                });
                break;
            case "LOADING":
                setEnable({
                    ...enable,
                    all: false
                });
                setVisibility({
                    ...visibility,
                    loading: true
                });
                break;
            default:
                break;
        }
    }, [pageType]);

    useEffect(() => {
        if (loading) {
            setEnable({
                ...enable,
                all: false
            });
            setVisibility({
                ...visibility,
                loading: true
            });
        } else {
            setEnable({
                ...enable,
                all: true
            });
            setVisibility({
                ...visibility,
                loading: false
            });
        }
    }, [loading]);

    return (
        <>
            <div className="page-content">
                <fieldset disabled={!enable.all}>
                    <Container fluid={true}>
                        <Card>
                            <CardHeader>
                                <Row className='align-items-end'>
                                    <Col sm={1}><Spinner color='danger' hidden={!visibility.loading}>loading ...</Spinner></Col>
                                    <Col sm={4}>
                                        <h2 className="mb-0">{texts.title}</h2>
                                        <p>Kaydetmek için '<span style={{ color: "red" }}>*</span>'la işaretlenmiş alanları doldurunuz.</p>
                                    </Col>
                                    <Col sm={4}></Col>
                                    <Col sm={1}><Button hidden={!visibility.cancelButton} disabled={!enable.cancelButton} onClick={handleCancel} color='warning'>İptal Et</Button></Col>
                                    <Col sm={1}><Button disabled={!enable.refreshButton} onClick={handleRefresh} color='danger'>Yenile</Button></Col>
                                    <Col sm={1}><Button disabled={!enable.doButton} onClick={handleDo} color='success'>{texts.doButton}</Button></Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="form-steps">
                                <Form>
                                    <fieldset disabled={!enable.form}>
                                        <Row>
                                            <Col md={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstNameinpu" className="form-label">Code<span style={{ color: "red" }}>*</span></Label>
                                                    <Input value={formValues.code} onChange={(e) => setFormValues({ ...formValues, code: e.target.value })} type="text" className="form-control" id="firstNameinpu" />
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstNameinpu" className="form-label">Month Count<span style={{ color: "red" }}>*</span></Label>
                                                    <Input value={formValues.monthCount} onChange={(e) => setFormValues({ ...formValues, monthCount: e.target.value })} type="number" className="form-control" id="firstNameinpu" min={1} step={1} />
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstNameinpu" className="form-label">Usage Limit<span style={{ color: "red" }}>*</span></Label>
                                                    <Input value={formValues.usageLimit} onChange={(e) => setFormValues({ ...formValues, usageLimit: e.target.value })} type="text" className="form-control" id="firstNameinpu" min={1} step={1} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstNameinpu" className="form-label">Expire Date<span style={{ color: "red" }}>*</span></Label>
                                                    <Input value={formValues.expireDate} onChange={(e) => setFormValues({ ...formValues, expireDate: e.target.value })} type="date" className="form-control" id="firstNameinpu" />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <div className="form-check form-check-danger mb-3">
                                                            <Input className="form-check-input" type="checkbox" checked={getNumberValueOfBool(formValues.isValid)} onChange={(e) => setFormValues({ ...formValues, isValid: Number(e.target.checked) })} />
                                                            <Label className="form-check-label">
                                                                Is Valid
                                                            </Label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </fieldset>
                                </Form>
                            </CardBody>
                        </Card>

                    </Container>
                </fieldset>
            </div>
        </>
    );
}

export default PremiumCodeDetails;