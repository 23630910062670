import { md5Control, tokenControl } from "../common/middlewareHeaders";
import fetchToAPI from "../common/fetchToAPI";
import CustomError from "../../error/CustomError";
import { getNotificationDetailsURL, getNotificationsURL, getNotificationTypesURL, getReceiverTypesURL, notificateDeviceFromPanelURL } from "../common/endpointURLs";

const getNotifications = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getNotificationsURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getNotificationTypes = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getNotificationTypesURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const notificateDeviceFromPanel = async ({
    adminId,
    title,
    body,
    notificationType,
    receiverType,
    users,
    now
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: notificateDeviceFromPanelURL,
            headers: md5Control(),
            method: "POST",
            body: {
                adminId,
                title,
                body,
                notificationType,
                receiverType,
                users,
                now
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getNotificationDetails = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: getNotificationDetailsURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getReceiverTypes = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getReceiverTypesURL,
            headers: md5Control(),
            method: "GET"
        });

        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

export {
    getNotifications,
    notificateDeviceFromPanel,
    getNotificationTypes,
    getNotificationDetails,
    getReceiverTypes
};