import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    ListGroup,
    ListGroupItem,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Button,
    Spinner
} from "reactstrap";
import classnames from "classnames";
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import Slider from 'react-rangeslider';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import { addMainAroma, getMainAromaDetails, getPriceCategories, updateMainAroma, updatePriceCategories } from '../../helpers/api/product/product';
// register lottie and define custom element
defineLordIconElement(loadAnimation);

const PriceTable = () => {
    const [enable, setEnable] = useState({
        all: false,
        doButton: true,
        cancelButton: false,
        refreshButton: true,
        form: true
    });
    const [formValues, setFormValues] = useState({
        one: { Text: "" },
        two: { Text: "" },
        three: { Text: "" },
        four: { Text: "" },
        five: { Text: "" }
    });
    const [print, setPrint] = useState({
        enable: false
    });
    const [texts, setTexts] = useState({
        doButton: "Düzenle"
    });
    const [pageType, setPageType] = useState("INFORM");
    const [loading, setLoading] = useState(false);
    const [visibility, setVisibility] = useState({
        loading: true,
        cancelButton: true
    });

    const history = useHistory();
    const type = "INFORM";

    const handlePricesDetails = async () => {
        try {
            let result = await getPriceCategories();
            if (!result.success) return console.log("Bir hata oluştu");

            let prices = result.data;

            setFormValues({
                ...formValues,
                one: prices.find(x => x.Value == 1),
                two: prices.find(x => x.Value == 2),
                three: prices.find(x => x.Value == 3),
                four: prices.find(x => x.Value == 4),
                five: prices.find(x => x.Value == 5)
            });

            if (type != "ADD") {
                setPrint({
                    ...print,
                    enable: true
                });
            }
        } catch (error) {
            return console.log(error);
        }
    }

    const doFirstProcess = async () => {
        try {
            setPageType("LOADING");

            await handlePricesDetails();

            setPageType(type);
        } catch (error) {
            return console.log(error);
        }
    };

    const handleDo = () => {
        if (pageType == "INFORM") {
            setPageType("EDIT");
        } else if (pageType == "EDIT") {
            handleUpdatePrices();
        }
    }

    const handleCancel = () => {
        setPageType("INFORM");
    }

    const handleRefresh = () => {
        if (pageType == "INFORM" || pageType == "EDIT") {
            reloadContent();
        }
    }

    const reloadContent = async () => {
        try {
            setLoading(true);

            await doFirstProcess();

            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    const alertValidationError = (element) => {
        alert("Lütfen " + element + " alanını doldurunuz.");
    }

    const controlNecessaryValues = () => {
        if (formValues.one.Text.trim() == "") return alertValidationError("Category 1");
        if (formValues.two.Text.trim() == "") return alertValidationError("Category 2");
        if (formValues.three.Text.trim() == "") return alertValidationError("Category 3");
        if (formValues.four.Text.trim() == "") return alertValidationError("Category 4");
        if (formValues.five.Text.trim() == "") return alertValidationError("Category 5");
        return true;
    }

    const handleUpdatePrices = async () => {
        try {
            setLoading(true);
            if (controlNecessaryValues() === true) {

                let response = await updatePriceCategories({
                    ...formValues
                });

                if (!response.success) return console.log(response.data);
                history.push("/wine-table");
            }
            setLoading(false);
        } catch (error) {
            return console.log(error);
        }
    }

    useEffect(() => {
        switch (type) {
            case "EDIT":
                setTexts({
                    ...texts,
                    button: "Kaydet"
                });
                break;
            case "INFORM":
                setTexts({
                    ...texts,
                    button: "Düzenle"
                });
                break;
            default:
                break;
        }

        document.title = "Price Table";

        doFirstProcess();
    }, []);

    useEffect(() => {
        if (!pageType) return

        switch (pageType) {
            case "INFORM":
                setTexts({
                    ...texts,
                    doButton: "Düzenle"
                });
                setEnable({
                    ...enable,
                    form: false,
                    cancelButton: false,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: true,
                    loading: false
                });
                break;
            case "EDIT":
                setTexts({
                    ...texts,
                    doButton: "Kaydet"
                });
                setEnable({
                    ...enable,
                    form: true,
                    cancelButton: true,
                    all: true
                });
                setVisibility({
                    ...visibility,
                    cancelButton: true,
                    loading: false
                });
                break;
            case "LOADING":
                setEnable({
                    ...enable,
                    all: false
                });
                setVisibility({
                    ...visibility,
                    loading: true
                });
                break;
            default:
                break;
        }
    }, [pageType]);

    useEffect(() => {
        if (loading) {
            setEnable({
                ...enable,
                all: false
            });
            setVisibility({
                ...visibility,
                loading: true
            });
        } else {
            setEnable({
                ...enable,
                all: true
            });
            setVisibility({
                ...visibility,
                loading: false
            });
        }
    }, [loading]);

    return (
        <>
            <div className="page-content">
                <fieldset disabled={!enable.all}>
                    <Container fluid={true}>
                        <Card>
                            <CardHeader>
                                <Row className='align-items-end'>
                                    <Col sm={1}><Spinner color='danger' hidden={!visibility.loading}>loading ...</Spinner></Col>
                                    <Col sm={3}><h2 className="mb-0">Price Categories</h2></Col>
                                    <Col sm={5}></Col>
                                    <Col sm={1}><Button hidden={!visibility.cancelButton} disabled={!enable.cancelButton} onClick={handleCancel} color='warning'>İptal Et</Button></Col>
                                    <Col sm={1}><Button disabled={!enable.refreshButton} onClick={handleRefresh} color='success'>Yenile</Button></Col>
                                    <Col sm={1}><Button disabled={!enable.doButton} onClick={handleDo} color='danger'>{texts.doButton}</Button></Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="form-steps">
                                <Form>
                                    <fieldset disabled={!enable.form}>
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstNameinpu1" className="form-label">Category 1<span style={{ color: "red" }}>*</span></Label>
                                                    <Input value={formValues.one.Text} onChange={(e) => setFormValues({ ...formValues, one: { ...formValues.one, Text: e.target.value } })} type="text" className="form-control" id="firstNameinpu1" />
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstNameinpu2" className="form-label">Category 2<span style={{ color: "red" }}>*</span></Label>
                                                    <Input value={formValues.two.Text} onChange={(e) => setFormValues({ ...formValues, two: { ...formValues.two, Text: e.target.value } })} type="text" className="form-control" id="firstNameinpu2" />
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstNameinpu3" className="form-label">Category 3<span style={{ color: "red" }}>*</span></Label>
                                                    <Input value={formValues.three.Text} onChange={(e) => setFormValues({ ...formValues, three: { ...formValues.three, Text: e.target.value } })} type="text" className="form-control" id="firstNameinpu3" />
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstNameinpu4" className="form-label">Category 4<span style={{ color: "red" }}>*</span></Label>
                                                    <Input value={formValues.four.Text} onChange={(e) => setFormValues({ ...formValues, four: { ...formValues.four, Text: e.target.value } })} type="text" className="form-control" id="firstNameinpu4" />
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstNameinpu5" className="form-label">Category 5<span style={{ color: "red" }}>*</span></Label>
                                                    <Input value={formValues.five.Text} onChange={(e) => setFormValues({ ...formValues, five: { ...formValues.five, Text: e.target.value } })} type="text" className="form-control" id="firstNameinpu5" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr />
                                    </fieldset>
                                </Form>
                            </CardBody>
                        </Card>
                    </Container>
                </fieldset>
            </div>
        </>
    );
}

export default PriceTable;