import { md5Control, tokenControl } from "../common/middlewareHeaders";
import { addAdminURL, adminLoginURL, changeAdminActivityURL, deleteAdminURL, editAppSettingsURL, getAdminDetailsURL, getAllAdminRolesURL, getAllAdminsURL, getAllCompaniesURL, getIsAppReadyURL, getVersionInfoURL, updateAdminURL } from "../common/endpointURLs";
import fetchToAPI from "../common/fetchToAPI";
import CustomError from "../../error/CustomError";

const loginAdmin = async ({
    email,
    password
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: adminLoginURL,
            body: {
                email,
                password
            },
            headers: md5Control(),
            method: "POST"
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getAllAdmins = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getAllAdminsURL,
            headers: md5Control(),
            method: "GET"
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const changeAdminActivity = async ({
    token,
    email,
    id,
    activity
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: changeAdminActivityURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id,
                activity
            }
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getAllAdminRoles = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getAllAdminRolesURL,
            headers: md5Control(),
            method: "GET"
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getAllCompanies = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getAllCompaniesURL,
            headers: md5Control(),
            method: "GET"
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getAdminDetails = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: getAdminDetailsURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const updateAdmin = async ({
    id,
    email,
    name,
    surname,
    roles,
    active,
    password,
    company
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: updateAdminURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id,
                email,
                name,
                surname,
                roles,
                active,
                password,
                companyID: company
            }
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const addAdmin = async ({
    email,
    name,
    surname,
    roles,
    active,
    company,
    password
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: addAdminURL,
            headers: md5Control(),
            method: "POST",
            body: {
                email,
                name,
                surname,
                roles,
                active,
                companyID: company,
                password
            }
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const deleteAdmin = async ({
    id
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: deleteAdminURL,
            headers: md5Control(),
            method: "POST",
            body: {
                id
            }
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getVersionInfo = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getVersionInfoURL,
            headers: md5Control(),
            method: "GET"
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const getIsAppReady = async () => {
    try {
        let response = await fetchToAPI({
            endpoint: getIsAppReadyURL,
            headers: md5Control(),
            method: "GET"
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

const editAppSettings = async ({
    android,
    ios,
    isAppReady
}) => {
    try {
        let response = await fetchToAPI({
            endpoint: editAppSettingsURL,
            headers: md5Control(),
            method: "POST",
            body: {
                android,
                ios,
                isAppReady
            }
        });
        return response;
    } catch (err) {
        return new Response(false, new CustomError(err, 500));
    }
}

export {
    loginAdmin,
    getAllAdmins,
    changeAdminActivity,
    getAllAdminRoles,
    getAllCompanies,
    getAdminDetails,
    updateAdmin,
    addAdmin,
    deleteAdmin,
    getVersionInfo,
    editAppSettings,
    getIsAppReady
};