import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from "reactstrap";
import { Grid, _ } from 'gridjs-react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import useAuth from '../../helpers/auth/useAuth';
import { useHistory } from 'react-router-dom';
import { deleteNews, getAllNews } from '../../helpers/api/information/information';

const BlogTable = () => {
    const [blogs, setBlogs] = useState([]);

    const history = useHistory();
    const { auth } = useAuth();

    document.title = "Blogs";

    const getBlogs = async () => {
        try {
            setBlogs([]);

            let response = await getAllNews({
                email: auth.email,
                token: auth.token
            });
            if (!response.success) throw response.data;

            setBlogs(response.data.map(blog => {
                return [
                    blog.id,
                    blog.NewsName,
                    blog.NewsTime.split("T")[0],
                    blog.NewsPicture
                ]
            }));
        } catch (error) {
            console.log(error);
        }
    }

    const handleClickGridItem = (e) => {
        e.preventDefault();
        let row = e.target.parentElement.parentElement.parentElement;
        let id = row.children[0].innerText;

        history.push("/blog-details", { params: { id, type: "INFORM" } });
    }

    const handleAddBlog = () => {
        history.push("/blog-details", { params: { type: "ADD" } });
    }

    const handleDeleteBlog = async (e) => {
        try {
            e.preventDefault();

            confirmAlert({
                title: 'Confirm to Delete',
                message: 'Are you sure to delete this blog?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let row = e.target.parentElement.parentElement.parentElement;
                            let id = row.children[0].innerText;

                            let response = await deleteNews({
                                id
                            });
                            if (!response.success) return console.log(response.data);

                            await getBlogs();
                        }
                    },
                    {
                        label: 'No'
                    }
                ]
            });
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        getBlogs();
    }, []);

    return (
        <>
            <div className="page-content">
                <Row>
                    <Col xs="10" />
                    <Col xs="2">
                        <Button onClick={handleAddBlog} color="danger">Blog Ekle</Button>
                    </Col>
                </Row>
                <Container fluid={true}>
                    <React.Fragment>
                        <Grid
                            data={blogs}
                            columns={[
                                "#", "Name", "Date",
                                {
                                    name: 'Picture',
                                    width: '120px',
                                    formatter: (cell) => _(
                                        <>
                                            {(cell)
                                                ? <img src={cell} alt="blog" style={{ width: "100px", height: "100px" }} />
                                                : <></>
                                            }
                                        </>
                                    )
                                },
                                {
                                    name: 'Actions',
                                    width: '120px',
                                    formatter: (cell) => _(
                                        <>
                                            <Button type='button' onClick={handleClickGridItem} className='btn btn-sm btn-light'> Details </Button>
                                            <Button type='button' onClick={handleDeleteBlog} className='btn btn-sm btn-danger'> Delete </Button>
                                        </>
                                    )
                                }
                            ]}
                            search={true}
                            sort={true}
                            pagination={{ enabled: true, limit: 10 }}
                        />
                    </React.Fragment>
                </Container>
            </div>
        </>
    );
}

export default BlogTable;